import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'


import Index from './pages/index'
import DepotPresences from './pages/depot-presences'
import DemandesAttestations from './pages/admin/demandesAttestations'
import Login from './pages/admin/login'
import Formations from './pages/admin/formations'
import EditFormation from './pages/admin/editFormation'
import Organismes from './pages/admin/organismes'
import GestionPresences from './pages/admin/gestion-presences'
import AdminEvaluations from './pages/admin/evaluations'
import Evenement from './pages/admin/evenement'
import Evaluation from './pages/evaluation'
import Rapports from './pages/admin/rapports'
import Superviseurs from "./pages/admin/superviseurs";
import SignalerProbleme from "./pages/SignalerProbleme";


Vue.use(VueRouter);

function hasAdminToken(to, from, next) {
    let token = Cookies.get('token');
    if (token) {
        next();
    } else {
        Vue.notify({
            type: 'error',
            group: 'alerts',
            title: 'Accès refusé',
            text: 'Vous n\'avez pas accès à cette ressource ou votre session a expirée. Veuillez vous connecter.',
            duration: 10000
        });
        next({name: 'admin.connexion'})
    }
}

const router = new VueRouter({
    mode: 'history',
    routes: [
        {path: '/', component: Index, name: 'demandes-attestations', meta: {groupe: 'participant'}},
        {path: '/evaluation', component: Evaluation, name: 'evaluation', meta: {groupe: 'participant'}},
        {
            path: '/signaler-probleme',
            component: SignalerProbleme,
            name: 'signaler-probleme',
            meta: {groupe: 'participant'}
        },
        {
            path: '/depot-presences',
            component: DepotPresences,
            name: 'depot-presences',
            meta: {groupe: 'organisateur'}
        },

        {path: '/admin/connexion', component: Login, name: 'admin.connexion', meta: {groupe: 'admin'}},
        {
            path: '/admin/demandes-attestations',
            component: DemandesAttestations,
            name: 'admin.demandes-attestations',
            beforeEnter: hasAdminToken,
            meta: {groupe: 'admin'}
        },
        {
            path: '/admin/formations',
            component: Formations,
            name: 'admin.formations',
            beforeEnter: hasAdminToken,
            meta: {groupe: 'admin'}
        },
        {
            path: '/admin/formations/:id',
            component: EditFormation,
            name: 'admin.formation',
            beforeEnter: hasAdminToken,
            meta: {groupe: 'admin'}
        },
        {
            path: '/admin/organismes',
            component: Organismes,
            name: 'admin.organismes',
            beforeEnter: hasAdminToken,
            meta: {groupe: 'admin'}
        },
        {
            path: '/admin/presences',
            component: GestionPresences,
            name: 'admin.presences',
            beforeEnter: hasAdminToken,
            meta: {groupe: 'admin'}
        },
        {
            path: '/admin/evaluations',
            component: AdminEvaluations,
            name: 'admin.evaluations',
            beforeEnter: hasAdminToken,
            meta: {groupe: 'admin'}
        },
        {
            path: '/admin/evenements/:id',
            component: Evenement,
            name: 'admin.evenement',
            beforeEnter: hasAdminToken,
            meta: {groupe: 'admin'}
        },
        {
            path: '/admin/rapports',
            component: Rapports,
            name: 'admin.rapports',
            beforeEnter: hasAdminToken,
            meta: {groupe: 'admin'}
        },
        {
            path: '/admin/superviseurs',
            component: Superviseurs,
            name: 'admin.superviseurs',
            beforeEnter: hasAdminToken,
            meta: {groupe: 'admin'}
        },
        {path: '*', redirect: '/'}
    ]
});


export default router;