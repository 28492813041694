<template>

    <FmedFenetreModale :afficher="formationId !== null">

        <div class="row" v-if="!chargement">
            <div class="col-sm-12">
                <div class="modal">

                    <div class="form">
                        <FmedTitre :niveau="1" class="mb-1">Modifier la formation</FmedTitre>

                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <FmedChampSaisie v-model="formation.nom"
                                                     placeholder="Nom de la formation"/>
                                    <div v-if="errors.nom" v-show="showErrors" class="error">
                                        <span>{{ errors.nom[0] }}</span></div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt1">
                            <div class="col-sm-12">
                                <FmedListeDeroulante v-model="typeActiviteSelectionne" :options="listeTypeActivites"
                                                     cle-libelle="texte"
                                                     placeholder="Type d'activité"
                                                     @input="act => this.formation.typeActivite = act ? act.valeur : null">
                                    <span slot="aucuneOption">Désolé, aucun organisme n'a été trouvée</span>
                                </FmedListeDeroulante>
                            </div>
                        </div>

                        <div class="row mt1">
                            <div class="col-sm-12">
                                <FmedListeDeroulante v-model="organismesSelectionnes" :options="organismes"
                                                     cle-libelle="nom"
                                                     placeholder="Organisme" :multiple="true">
                                    <span slot="aucuneOption">Désolé, aucun organisme n'a été trouvée</span>
                                </FmedListeDeroulante>
                            </div>
                        </div>

                        <div class="row mt1">
                            <div class="col-sm-12">
                                <div class="strip strip-secondary">
                                    <div><label>Accréditation</label></div>
                                    <div class="mt1 flex align-center">
                                        <div class="flex align-center">
                                            <span>de</span>
                                            <FmedChampDate
                                                    v-model="formation.dateDebutAccreditation"></FmedChampDate>
                                        </div>

                                        <div class="flex align-center" style="margin-left: 1em">
                                            <span>à</span>
                                            <FmedChampDate
                                                    v-model="formation.dateFinAccreditation"></FmedChampDate>
                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>


                        <div class="row mt1">
                            <div class="col-sm-12">
                                <div class="text-right btn-group" @mouseover="showErrors = true">
                                    <FmedBouton couleur="encadre" @click="$emit('annuler')">Annuler</FmedBouton>
                                    <FmedBouton @click="sauvegarder" :chargement="sauvegardeChargement"
                                                :disabled="this.hasErrors">Enregistrer
                                    </FmedBouton>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
        <LoadingOverlay class="text-center mt1" :active="chargement"></LoadingOverlay>


    </FmedFenetreModale>

</template>

<script>


    import Api from "../../api";

    import {
        FmedFenetreModale,
        FmedChampDate,
        FmedListeDeroulante,
        FmedTitre,
        FmedBouton,
        FmedChampSaisie
    } from '@fmedul/panier-vue'
    import LoadingOverlay from 'vue-loading-overlay'

    const api = Api.getInstance();

    export default {
        components: {
            FmedFenetreModale,
            FmedChampDate,
            LoadingOverlay,
            FmedListeDeroulante,
            FmedTitre,
            FmedBouton,
            FmedChampSaisie
        },
        data: function () {
            return {
                formation: null,
                organismesSelectionnes: [],
                organismes: [],
                showErrors: false,
                sauvegardeChargement: false,

                listeTypeActivites: [
                    {valeur: 'colloque', texte: 'Colloque'},
                    {valeur: 'formation', texte: 'Formation'},
                ],
                typeActiviteSelectionne: null,

                chargement: true,
            }
        },

        props: ["formationId"],

        watch: {
            async formationId(nouvelleValeur) {
                if (nouvelleValeur !== null) {
                    this.chargement = true;
                    this.formation = null;
                    this.formation = await api.getFormation(nouvelleValeur)
                    this.organismesSelectionnes = await api.getOrganismesFromFormation(this.formation.id, true);
                    this.fichiersAccreditationsLoading = true;
                    this.typeActiviteSelectionne = this.listeTypeActivites.find(act => act.valeur === this.formation.typeActivite);
                    this.organismes = await api.getOrganismes();
                    this.chargement = false;
                }

            }
        },


        methods: {

            addError(errors, key, value) {
                if (!errors[key]) {
                    errors[key] = [];
                }

                errors[key].push(value);
            },


            async sauvegarder() {
                if (this.errors.length > 0)
                    return;

                this.sauvegardeChargement = true;
                await api.modifierFormation(this.formation, this.organismesSelectionnes);
                await api.modifierOrganismesDeFormation(this.formation.id, this.organismesSelectionnes.map(o => o.id));
                this.sauvegardeChargement = false;
                this.$emit('sauvegarder', {formation: this.formation, organismes: this.organismesSelectionnes});
            },


        },


        computed: {

            errors() {

                var errors = {};

                if (this.formation.nom.length === 0) {
                    this.addError(errors, 'nom', 'Le nom de la formation ne peut être vide');
                }


                return errors;
            },

            hasErrors() {
                return Object.keys(this.errors).length > 0 && this.errors.constructor === Object;
            },


        },


    }


</script>

<style>

    .liste-fichiers tr:not(:last-child) td {
        padding: 10px 0;
        border-bottom: 1px solid #aaaaaa;
    }

    .liste-fichiers tr td {
        color: #aaa;
        padding: 10px 0;
    }


</style>


