<template>

    <div>
        <div class="container">
            <div class="mt-1">
                <FmedLien destination="/admin/formations" icone="icon-arrow-back">Retour à la liste des formations
                </FmedLien>
            </div>
            <FmedTitre :niveau="1" class="mt-2 mb-1">Gestion des formations</FmedTitre>
        </div>


        <LoadingOverlay class="text-center mt1" :active.sync="isLoading"></LoadingOverlay>

        <div class="container mt2 form" v-if="formation">


            <div class="row">
                <div class="col-sm-12">
                    <div class="header">
                        <h2>{{ formation.nom }}</h2>
                        <DropdownButton style="margin-left: auto;align-self: flex-end" :items="dropdownItems">
                            <span class="caret"></span>
                        </DropdownButton>
                    </div>

                </div>
            </div>

            <div class="row mt1">
                <div class="col-sm-2">
                    <VueSwitch v-model="formation.active" theme="uec" type-bold="true" label="Statut"></VueSwitch>
                </div>
                <div class="col-sm-8">
                    <span class="text-muted">Période d'accréditation :</span> {{ formation.dateDebutAccreditation |
                    shortDate}} au {{ formation.dateFinAccreditation | shortDate }}
                </div>
            </div>
            <div class="strip strip-secondary mt1">

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Unités de formation continue :</label>
                            <FmedChampSaisie type="text" v-model="formation.nombreUfc" style="max-width: 100px"/>
                            <div v-if="errors.nombreUfc" class="error"><span>{{ errors.nombreUfc[0] }}</span></div>
                        </div>

                    </div>

                </div>

                <div class="row mt1">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Numéro du produit Dexero :</label>
                            <FmedListeDeroulante v-model="formation.produitEcommerce" :options="produitsDexero"
                                                 :fonction-libelle="p => p.id + ' (' + p.cout.toFixed(2) + ' $)'"/>
                            <div v-if="errors.ecommerceId" class="error"><span>{{ errors.ecommerceId[0] }}</span></div>
                        </div>

                    </div>
                </div>
                <div class="row mt1">
                    <div class="col-sm-12">
                        <label>Est-ce que le participant doit télécharger un fichier lors de sa demande?</label>
                      <div class="flex">
                        <FmedBoutonRadio groupe="fichier_a_telechargement" libelle="Oui" :valeur="true"
                                         v-model="formation.fichierRequisDemandeAttestation"/>
                        <FmedBoutonRadio groupe="fichier_a_telechargement" libelle="Non" :valeur="false"
                                         v-model="formation.fichierRequisDemandeAttestation"/>
                      </div>

                    </div>

                </div>

            </div>


            <div class="row mt1" v-if="showModules">
                <div class="col-sm-12">

                    <table class="table">
                        <tr>
                            <th>Titre</th>
                            <th>UEC</th>
                            <th>Coût</th>
                            <th>Statut</th>
                            <th></th>
                        </tr>
                        <tr v-for="(module, idx) in modules" :key="idx">
                            <td>{{ module.nom }}</td>
                            <td>{{ module.nombreUfc }}</td>
                            <td>{{ module.produitEcommerce.id }} ({{module.produitEcommerce.cout.toFixed(2)}} $)</td>
                            <td class="text-center">
                                <Light v-model="module.actif" size="15px"/>
                            </td>
                            <td class="">
                                <i class="icon icon-edit" @click="ouvrirModalModifierModule(idx)"
                                   style="cursor: pointer"></i>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <FmedChampSaisie v-model="nouveauModule.nom" placeholder="Titre du module"/>
                            </td>
                            <td>
                                <FmedChampSaisie v-model="nouveauModule.nombreUfc" placeholder="Nombre de UEC"/>
                            </td>
                            <td>
                                <FmedListeDeroulante :options="produitsDexero" v-model="nouveauModule.produitEcommerce"
                                                     placeholder="No. de produit Dex."
                                                     :fonction-libelle="p => p.id + ' (' + p.cout.toFixed(2) + ' $)'"/>
                            </td>
                            <td>

                            </td>
                          <td>
                            <FmedBouton couleur="encadre" @click="ajouterNouveauModule">Ajouter
                            </FmedBouton>
                          </td>
                        </tr>
                    </table>

                </div>
            </div>

            <div class="row mt1">
                <div class="col-sm-12">
                    <div class="pull-right btn-group mb-1">
                        <FmedBouton couleur="encadre" @click="$router.go(-1)">Annuler</FmedBouton>

                        <FmedBouton @click="sauvegarder" :chargement="sauvegardeEnCours">Enregistrer</FmedBouton>
                    </div>

                </div>
            </div>
        </div>


        <FenetreModaleModifierFormation :formation-id="fenetreModaleModifierFormationId"
                                        @annuler="fenetreModaleModifierFormationId = null"
                                        @sauvegarder="sauvegarderModifierFormation"></FenetreModaleModifierFormation>
        <FenetreModaleModifierModule :module="fenetreModaleModifierModule"
                                     @annuler="fenetreModaleModifierModuleId = null;fenetreModaleModifierModule = null"
                                     @sauvegarder="sauvegarderModule"></FenetreModaleModifierModule>
        <FenetreModaleFormationFichiers :formation-id="fenetreModaleFormationFichiersId"
                                        @fermer="fenetreModaleFormationFichiersId = null"></FenetreModaleFormationFichiers>
    </div>


</template>


<script>

    import Api from '../../api';

    import {
        FmedLien,
        FmedTitre,
        FmedBoutonRadio,
        FmedBouton,
        FmedListeDeroulante,
        FmedChampSaisie
    } from '@fmedul/panier-vue'

    import LoadingOverlay from 'vue-loading-overlay'

    import FenetreModaleModifierFormation from "../../components/modals/FenetreModaleModifierFormation";
    import FenetreModaleModifierModule from "../../components/modals/FenetreModaleModifierModule";
    import FenetreModaleFormationFichiers from "../../components/modals/FenetreModaleFormationFichiers";

    import Light from "../../components/Light";

    import DropdownButton from "../../components/DropdownButton";

    import VueSwitch from 'vue-switches'


    const api = Api.getInstance();

    export default {
        components: {
            FenetreModaleModifierFormation,
            FmedChampSaisie,
            FmedTitre,
            FmedLien,
            LoadingOverlay,
            FenetreModaleModifierModule,
            FenetreModaleFormationFichiers,
            DropdownButton,
            VueSwitch,
            FmedBoutonRadio,
            FmedBouton,
            FmedListeDeroulante,
            Light
        },
        data: function () {

            return {
                formation: null,
                modules: [],

                nouveauModule: {},

                organismesFormation: null,

                produitsDexero: [],

                showModules: false,

                isLoading: true,

                sauvegardeEnCours: false,

                dropdownItems: [
                    {label: 'Voir ou modifier la formation', action: this.openEditFormationModal},
                    {label: 'Ajouter ou modifier un fichier', action: this.openAddFileModal},
                ],

                fenetreModaleModifierFormationId: null,

                fenetreModaleModifierModule: null,
                idxModificationModule: null,

                fenetreModaleFormationFichiersId: null

            }

        },


        methods: {

            sauvegarderModifierFormation($event) {
                this.formation = $event.formation;
                this.organismesFormation = $event.organismes;
                this.fenetreModaleModifierFormationId = null;
            },

            sauvegarderModule(moduleModifie) {
                this.modules[this.idxModificationModule] = moduleModifie;
                this.fenetreModaleModifierModule = null;
                this.idxModificationModule = null;
            },

            ouvrirModalModifierModule(idxModule) {
                this.idxModificationModule = idxModule;
                this.fenetreModaleModifierModule = this.modules[this.idxModificationModule];
            },


            ajouterNouveauModule() {
                if (!this.nouveauModule.nombreUfc || isNaN(this.nouveauModule.nombreUfc)) {
                    alert('Le nombre d\'UEC doit être un nombre');
                    return;
                }

                if (!this.nouveauModule.nom || this.nouveauModule.nom === '') {
                    alert('Le nom du module ne doit pas être vide');
                    return;
                }

                if (!this.nouveauModule.produitEcommerce) {
                    alert('Le numéro de produit Dexero ne doit pas être vide');
                    return;
                }

                this.modules.push({
                    nom: this.nouveauModule.nom,
                    nombreUfc: this.nouveauModule.nombreUfc,
                    produitEcommerce: this.nouveauModule.produitEcommerce,
                    actif: true
                });

                this.resetNouveauModule()


            },

            toggleModuleActif(module) {
                module.actif = !module.actif
            },

            async sauvegarder() {
                if (this.errors.length > 0)
                    return;

                this.sauvegardeEnCours = true;

                let appels = [];

                appels.push(api.modifierFormation(this.formation));

                this.modules.forEach(module => {
                    if (module.id) {
                        appels.push(api.modifierModule(module.id, module.nom, module.nombreUfc, module.produitEcommerce.id, module.actif))
                    } else {
                        appels.push(api.creerModule(module.nom, module.nombreUfc, module.produitEcommerce.id, module.actif, this.formation.id))
                    }
                });

                await Promise.all(appels);


                this.$notify({
                    group: 'alerts',
                    title: 'Succès',
                    text: 'Formation sauvegardée avec succès.',
                    duration: 10000
                });

                this.isLoading = true;
                this.formation = await api.getFormation(this.$route.params.id);

                this.sauvegardeEnCours = false;


                this.resetNouveauModule();
                this.showModules = true;


                this.isLoading = false;


            },

            openEditFormationModal: function () {
                this.fenetreModaleModifierFormationId = this.formation.id
            },

            openAddFileModal: function () {
                this.fenetreModaleFormationFichiersId = this.formation.id;
            },

            addError(errors, key, value) {
                if (!errors[key]) {
                    errors[key] = [];
                }

                errors[key].push(value);
            },

            resetNouveauModule() {
                this.nouveauModule = {
                    nom: '',
                    nombreUfc: this.formation.nombreUfc,
                    produitEcommerce: this.formation.produitEcommerce,
                }
            }

        },

        computed: {

            errors() {

                var errors = {};

                if (!this.formation.nombreUfc || this.formation.nombreUfc.length === 0) {
                    this.addError(errors, 'nombreUfc', 'Le champ unités de formation continue ne peut être vide');
                }
                if (isNaN(this.formation.nombreUfc)) {
                    this.addError(errors, 'nombreUfc', 'Le champ unités de formation continue doit être un nombre');
                }

                if (!this.formation.cout || this.formation.cout.length === 0) {
                    this.addError(errors, 'cout', 'Le champ coût de l\'attestation ne peut être vide');
                }
                if (isNaN(this.formation.cout)) {
                    this.addError(errors, 'cout', 'Le champ  coût de l\'attestation doit être un nombre');
                }

                if (!this.formation.produitEcommerce) {
                    this.addError(errors, 'ecommerceId', 'Le champ Numéro du produit Dexero ne peut être vide');
                }

                return errors;
            }

        },

        created: async function () {
            this.formation = await api.getFormation(this.$route.params.id);
            this.organismesFormation = await api.getOrganismesFromFormation(this.formation.id);
            this.modules = await api.obtenirModulesDeFormation(this.formation.id, true);
            this.produitsDexero = await api.obtenirTousProduitsDexero();

            this.resetNouveauModule();
            this.showModules = true;


            this.isLoading = false;
        }

    }


</script>
