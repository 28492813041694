export default {


    IMPOSSIBLE_APPLIQUER_RABAIS_DEMANDEUR_REQUETE_DEMANDE_ATTESTATION: "Désolé, aucun superviseur n'a été trouvé avec ce nom et prénom",
    ADRESSE_COURRIEL_INVALIDE: "L'adresse courriel est invalide",




    message(id){
        return this[id] || "Désolé, une erreur est survenue"
    }


}