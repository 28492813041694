<template>

    <div>
        <div class="container mt-1">
            <FmedLien destination="/admin/presences" icone="icon-arrow-back">Retour à la liste des événements
            </FmedLien>
            <FmedTitre :niveau="1" class="mt-1 mb-1">Gestion des présences d'un événement</FmedTitre>
        </div>


        <LoadingOverlay class="text-center mt1" :active.sync="isLoading"></LoadingOverlay>

        <div class="container mt2 form" v-if="evenement">

            <div class="definitions">
                <div>
                    <div class="definition definition-inline">
                        <span class="title">Date : </span>
                        <span class="description">{{ evenement.date | shortDate }}</span>
                    </div>
                </div>


                <div>
                    <div class="definition definition-inline">
                        <span class="title">Formation : </span>
                        <span class="description">{{ evenement.formation.nom }}</span>
                    </div>
                </div>

                <div>
                    <div class="definition definition-inline">
                        <span class="title">Organisme : </span>
                        <span class="description">{{ evenement.organisme.nom }}</span>
                    </div>
                </div>


            </div>
            <table class="table mt1">
                <tr>
                    <th>Nom</th>
                    <th>Prénom</th>
                </tr>
                <tr v-for="presence in presences" :class="{ surligner: presence_id == presence.id}"
                    :key="presence.id">
                    <td>{{ presence.nom }}</td>
                    <td>{{ presence.prenom }}</td>
                </tr>
            </table>
        </div>

    </div>


</template>


<script>

    import Api from '../../api';

    import {FmedTitre, FmedLien} from '@fmedul/panier-vue'
    import LoadingOverlay from 'vue-loading-overlay';

    const api = Api.getInstance();

    export default {
        components: {FmedTitre, FmedLien,LoadingOverlay},
        data: function () {

            return {
                evenement: null,
                presences : [],

                isLoading: true,

                presence_id: null

            }

        },


        methods: {},


        computed: {}
        ,

        created: async function () {
            this.evenement = await api.trouverEvenement(this.$route.params.id);
            this.presences = await api.obtenirPresencesEvenement(this.evenement.id);
            this.presence_id = this.$route.query.presence_id;
            this.isLoading = false;
        },

    }


</script>

<style lang="scss">

    .surligner {
        border: 3px solid #000;
    }

</style>
