<template>

    <div>
        <div class="container">
            <FmedTitre :niveau="1" class="mt-1 mb-1">Gestion des superviseurs de stage (gratuité)</FmedTitre>


        <LoadingOverlay class="text-center mt1" :active.sync="chargement"></LoadingOverlay>

        <div v-if="!chargement">


                <ul v-for="(superviseur, idx) in superviseurs" :key="idx" class="mb-1">
                    <li>{{superviseur.nom}}, {{superviseur.prenom}}</li>
                </ul>



        </div>

          <FmedMessage type="info">
            Pour mettre à jour la liste des superviseurs, veuillez importer un nouveau fichier Excel en mettant le nom dans la première colonne et le prénom dans la deuxième colonne.
          </FmedMessage>
        <DragFileUpload @change="(e) => this.fichierTelechargement = e" class="mt-1"></DragFileUpload>
            <div class="text-right mt-1 mb-1">
                <FmedBouton :chargement="chargementImport" @click="importerFichier" :desactive="fichierTelechargement === null">Importer</FmedBouton>
            </div>

    </div>


    </div>


</template>


<script>

    import Api from '../../api';

    import DragFileUpload from "../../components/DragFileUpload";

    import {FmedTitre, FmedBouton, FmedMessage} from '@fmedul/panier-vue'

    import LoadingOverlay from 'vue-loading-overlay'



    const api = Api.getInstance();

    export default {
        components: {FmedTitre, LoadingOverlay, DragFileUpload, FmedBouton, FmedMessage},
        data: function () {

            return {
                superviseurs : [],

                chargement : true,

                fichierTelechargement : null,

                chargementImport : false
            }

        },


        methods: {
            async importerFichier(){
                this.chargementImport = true;
                try {
                    this.superviseurs = await api.chargerFichierSuperviseurs(this.fichierTelechargement);
                }
                finally {
                    this.chargementImport = false;
                }
            }
        },


        computed: {},

        async created() {
            this.superviseurs = await api.obtenirSuperviseurs();
            this.chargement = false
        },

    }


</script>

<style lang="scss">

    .surligner {
        background-color: yellow;
    }

</style>
