import 'regenerator-runtime/runtime'

import  './sass/bootstrap.scss'
import  './sass/app.scss'

import Vue from 'vue'
import router from './router';
import store from './store';
import App from './App.vue'
import moment from 'moment'
import Notifications from 'vue-notification'
import VTooltip from 'v-tooltip'



//import './components';

Vue.filter('shortDate', (input) => moment.utc(input).format("YYYY-MM-DD"))
Vue.use(Notifications);
Vue.use(VTooltip);


Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his childrens
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
});


new Vue({
    router,
    store,
    render : h => h(App)
}).$mount("#app");