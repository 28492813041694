<template>


    <div :class="{'file-upload' : true, over : dragOver}" @dragenter="dragOver=true"
         @dragend="dragOver=false" @dragleave="dragOver=false" @dragover.prevent
         @drop="dropFichier" @click="click" style="cursor: pointer">
        <div class="text">
            <span v-if="nomFichier" style="color: #000">{{ nomFichier }}</span>
            <span v-else>Cliquer ou glisser-déposer le fichier pour le joindre</span>
        </div>
        <input type="file" ref="filePresence" style="display: none" @change="fileChangeFichier" :accept="accept">
    </div>


</template>

<script>

    export default {

        data: () => {
            return {
                dragOver: false,
                nomFichier: ''
            }
        },

        props : {
            accept : {
                type : String,
                Defaults : null
            }
        },

        methods: {
            fileChangeFichier: function(e) {
                let fichier = e.target.files[0];
                this.nomFichier = fichier.name;
                this.$emit('change', fichier);
            },

            dropFichier: function(e) {

                this.dragoverPresence = false;

                e.stopPropagation();
                e.preventDefault();

                let fichier = e.dataTransfer.files[0];
                this.nomFichier = fichier.name;
                this.$emit('change', fichier);
            },

            clear: function () {
                this.dragOver =false;
                this.nomFichier = '';
            },

          click :function () {
            this.$refs.filePresence.value = null;
            this.clear();
            this.$refs.filePresence.click();
          }
        }


    }


</script>


<style lang="scss">

    .file-upload {

        padding: 20px;
        border: 2px dashed #aaa;
        background-color: #eee;
        color : #444;

        &.over {
            background-color: #ccc;
        }

        .text{
            color:#aaa;
            text-align: center;
            pointer-events: none
        }

    }


</style>


