<template>

  <div>


    <div class="container">
      <FmedTitre :niveau="1" class="mt-2 mb-1">Faire une demande d’attestation de formation</FmedTitre>
      <p>
        Vous avez participé à une activité de formation continue et vous souhaitez obtenir les unités de formation
        continue qui lui sont attribuées ? Vous pouvez faire une demande d’attestation en remplissant le formulaire
        suivant. Veuillez noter que des frais de traitement de votre demande sont à payer.
      </p>
      <p class="mt-1">
        Votre attestation vous sera émise et envoyée par courriel dès réception de la confirmation de votre présence par l’organisateur.
      </p>
    </div>


    <LoadingOverlay class="text-center mt-1" :active.sync="isLoading"></LoadingOverlay>

    <div class="container form" v-if="!isLoading">


      <h3 class="mt-3 mb-1">Activité de formation</h3>


      <FmedMessage type="erreur" v-show="messageErreur" @fermer="messageErreur = ''" class="mt-1 mb-1"
                   ref="erreur">
        {{ messageErreur }}
      </FmedMessage>

      <div class="form-group">
        <FmedListeDeroulante v-model="selectedFormation" :options="formationsOrdreAlpha" cle-libelle="nom"
                             placeholder="Saisir ou sélectionner une formation"
                             @input="formationChanged(selectedFormation)"
                             style="max-width: 500px" :cherchable="true">
          <span slot="aucuneOption">Désolé, aucune formation n'a été trouvée</span>
        </FmedListeDeroulante>
      </div>


      <div class="form-group">
        <FmedListeDeroulante :options="selectedFormation ? organismes : []"
                             v-model="selectedOrganisme" cle-libelle="nom"
                             placeholder="Sélectionner un organisme"
                             style="max-width: 500px">
          <span slot="aucuneOption">Sélectionner une formation préalablement</span>
        </FmedListeDeroulante>
      </div>


      <div class="form-group">
        <label>Date de la formation</label>
        <FmedChampDate v-model="selectedDate"></FmedChampDate>
      </div>


      <div class="superviseur">
        <span class="mt-3">Êtes-vous une superviseure ou un superviseur pour la Faculté des sciences infirmières de l’Université Laval?</span>
        <div class="flex">
          <FmedBoutonRadio groupe="superviseur" :valeur="true" v-model="estSuperviseur" libelle="Oui"/>
          <FmedBoutonRadio groupe="superviseur" :valeur="false" v-model="estSuperviseur" libelle="Non"/>
        </div>


        <div v-if="estSuperviseur">
          <span>Veuillez saisir votre nom et prénom</span>
          <div class="flex">
            <FmedChampSaisie placeholder="Nom" v-model="nomSuperviseur"/>
            <FmedChampSaisie placeholder="Prénom" v-model="prenomSuperviseur"/>
          </div>
        </div>
      </div>


      <div v-if="selectedFormation && selectedOrganisme && selectedDate" class="box mt1">

        <div class="definitions">
          <div class="definition">
            <span class="title">Formation : </span>
            <span class="description">{{ selectedFormation.nom }}</span>
          </div>
          <div class="definition">
            <span class="title">Organisme : </span>
            <span class="description">{{ selectedOrganisme.nom }}</span>
          </div>

          <div class="definition">
            <span class="title">Date : </span>
            <span class="description">{{ selectedDate | shortDate }}</span>
          </div>
        </div>

        <div v-if="modules.length > 0">
          <div class="mt2">

            <strong>Vous pouvez préciser un module ou un ensemble de modules si vous ne faites pas une
              demande pour la formation complète&nbsp;:</strong>
          </div>
          <FmedListeDeroulante v-model="selectedModule" :options="listeModules" class="mt-1" cle-libelle="nom"
                               placeholder="Veuillez choisir une option">
          </FmedListeDeroulante>

        </div>

        <div class="text-right mt3">
          <span class="text-muted">Coût de l'attestation :</span> <strong>{{ cout }} $</strong>
        </div>
      </div>


      <div v-if="selectedFormation && selectedFormation.fichierRequisDemandeAttestation === true" class="mt2">
        Une preuve de participation est requise pour faire une demande d’attestation pour cette formation.
        <DragFileUpload class="mt1" @change="changeFichierUpload" ref="fichierUpload"></DragFileUpload>
      </div>


      <FmedMessage type="info" class="mt-5" :fermable="false">
        En cliquant sur le bouton « Procéder au paiement », vous serez redirigé vers le système de paiement en
        ligne de l'Université Laval afin de compléter votre demande.
      </FmedMessage>

      <div class="pull-right mt2">
        <FmedBouton :chargement="proceddingToPayment" @click="proceedToPayment"
                    :disabled="!allFieldSelected">Procéder au paiement
        </FmedBouton>
      </div>


      <div style="height: 50px"></div>

    </div>
  </div>
</template>


<script>

import Api from "../api";
import moment from 'moment';

import DragFileUpload from "../components/DragFileUpload";
import LoadingOverlay from 'vue-loading-overlay'

import {
  FmedBouton,
  FmedMessage,
  FmedListeDeroulante,
  FmedTitre,
  FmedChampDate,
  FmedChampSaisie,
  FmedBoutonRadio
} from '@fmedul/panier-vue'
import messages from "../messages";

const api = Api.getInstance();

export default {

  name: 'index',
  components: {
    FmedChampSaisie,
    FmedBouton,
    FmedMessage,
    DragFileUpload,
    FmedListeDeroulante,
    FmedTitre,
    FmedChampDate,
    LoadingOverlay,
    FmedBoutonRadio
  },
  data: function () {

    return {
      formations: [],
      organismes: [],
      modules: [],
      fichierAttache: null,
      selectedFormation: null,

      selectedOrganisme: null,

      selectedModule: undefined,

      selectedDate: null,

      disabledDates: {
        to: moment().subtract(18, 'months').toDate(),
        from: (new Date())
      },

      isLoading: true,
      proceddingToPayment: false,

      estSuperviseur: false,
      nomSuperviseur: '',
      prenomSuperviseur: '',


      messageErreur: '',
    }
  },

  methods: {

    changeFichierUpload(nouveauFichier) {
      this.fichierAttache = nouveauFichier;
    },

    formationChanged: async function () {

      this.selectedOrganisme = null;
      this.selectedModule = undefined;

      this.organismes = await api.getOrganismesFromFormation(this.selectedFormation.id);
      this.modules = await api.obtenirModulesDeFormation(this.selectedFormation.id);

      if (this.modules.length === 0) {
        this.selectedModule = null;
      }
    },

    organismeChanged: function () {
      this.selectedModule = undefined;
    },

    async proceedToPayment() {

      this.proceddingToPayment = true;

      try {
        const url = await api.creerRequeteDemandeAttestation(
            this.selectedFormation.id,
            this.selectedModule ? this.selectedModule.id : null,
            this.selectedOrganisme.id,
            moment.utc(this.selectedDate).format('YYYY-MM-DD'),
            this.fichierAttache,
            this.estSuperviseur,
            this.nomSuperviseur,
            this.prenomSuperviseur);

        window.location = url;

      } catch (e) {
        this.messageErreur = messages.message(e.erreur);
        this.$nextTick(() => this.$refs['erreur'].$el.scrollIntoView({behavior: 'smooth'}))


      } finally {
        this.proceddingToPayment = false;
      }


    }


  }
  ,

  computed: {


    formationsOrdreAlpha() {
      return this.formations.slice().sort((a, b) => a.nom.localeCompare(b.nom));
    },


    cout() {
      //Si la méthode évolue pour avoir trop de conditions, on pourrait laisser le backend nous retourner le produit ecommerce à utiliser pour déterminer le cout.
      if (this.estSuperviseur) {
        return 0;
      } else if (this.selectedModule && this.selectedModule.produitEcommerce) {
        return this.selectedModule.produitEcommerce.cout.toFixed(2);
      } else if (this.selectedModule && !this.selectedModule.produitEcommerce) { //Le module "Tous les modules" n'a pas de produit, on utilise celui de la formation.
        return this.selectedFormation.produitEcommerce.cout.toFixed(2);
      } else if (this.selectedFormation) {
        return this.selectedFormation.produitEcommerce.cout.toFixed(2);
      } else {
        return 0;
      }
    },

    listeModules() {
      let liste = [];
      liste.push({nom: "Tous les modules"});
      this.modules.forEach(module => liste.push(module));

      return liste;
    },

    allFieldSelected: function () {
      return this.selectedOrganisme != null
          && this.selectedFormation != null
          && this.selectedDate != null
          && this.selectedModule !== undefined
          && (this.selectedFormation.fichierRequisDemandeAttestation === false || (this.selectedFormation.fichierRequisDemandeAttestation === true && this.fichierAttache))
          && this.estSuperviseur === false || (this.estSuperviseur === true && this.nomSuperviseur.length > 0 && this.prenomSuperviseur.length > 0)
          && this.selectedFormation != null && (this.selectedFormation.fichierRequisDemandeAttestation === false || (this.selectedFormation.fichierRequisDemandeAttestation === true && this.fichierAttache !== null))
    }
  },



  mounted: async function () {
    this.formations = await api.getAllFormations();

    this.isLoading = false;
  }


};
</script>


<style lang="scss">
.checkbox-group {
  input[type=radio] {
    margin-right: 5px;
  }
}


.superviseur {
  .fmed-champ-saisie {
    width: 50%;
    max-width: 300px;
  }
}
</style>
