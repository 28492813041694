<script>

    import { Pie } from 'vue-chartjs'

    export default {

        name : 'region-geographique-pie-chart',

        extends: Pie,

        data: function () {
            return {

                regions: [
                    {text: "Bas-Saint-Laurent", code: "01"},
                    {text: "Saguenay-Lac-Saint-Jean", code: "02"},
                    {text: "Capitale nationale", code: "03"},
                    {text: "Mauricie", code: "04"},
                    {text: "Estrie", code: "05"},
                    {text: "Montréal", code: "06"},
                    {text: "Outaouais", code: "07"},
                    {text: "Abitibi-Témiscamingue", code: "08"},
                    {text: "Côte-Nord", code: "09"},
                    {text: "Nord-du-Québec", code: "10"},
                    {text: "Gaspésie-Îles-de-la-Madeleine", code: "11"},
                    {text: "Chaudière-Appalaches", code: "12"},
                    {text: "Laval", code: "13"},
                    {text: "Lanaudière", code: "14"},
                    {text: "Laurentides", code: "15"},
                    {text: "Montérégie", code: "16"},
                    {text: "Centre-du-Québec", code: "17"},
                ],

            }
        },

        props: ['chartData'],


        mounted() {

            let default_colors = ['#3366CC','#DC3912','#FF9900','#109618','#990099','#3B3EAC','#0099C6','#DD4477','#66AA00','#B82E2E','#316395','#994499','#22AA99','#AAAA11','#6633CC','#E67300','#8B0707','#329262','#5574A6','#3B3EAC']


            let labels = [];
            let dataset = {
                backgroundColor : [],
                data : []
            };
            this.regions.forEach((region, idx) => {
                if(this.chartData[region.code])
                {
                    labels.push(region.text);
                    dataset.data.push(this.chartData[region.code]);
                    dataset.backgroundColor.push(default_colors[idx]);
                }
            });

            let data = { datasets : [dataset], labels : labels };
            this.renderChart(data)
        }

    }


</script>

