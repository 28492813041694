<script>

    import { Pie } from 'vue-chartjs'

    export default {

        name : 'annee-experience-pie-chart',

        extends: Pie,

        data: function () {
            return {

                anneesExperience : [
                    {id: '0_5', text: "0 à 5 ans"},
                    {id: '6_10', text: "6 à 10 ans"},
                    {id: '11_15', text: "11 à 15 ans"},
                    {id: '16_20', text: "16 à 20 ans"},
                    {id: '21_plus', text: "21 ans et plus"}
                ]

            }
        },

        props: ['chartData'],


        mounted() {

            let default_colors = ['#3366CC','#DC3912','#FF9900','#109618','#990099','#3B3EAC','#0099C6','#DD4477','#66AA00','#B82E2E','#316395','#994499','#22AA99','#AAAA11','#6633CC','#E67300','#8B0707','#329262','#5574A6','#3B3EAC']


            let labels = [];
            let dataset = {
                backgroundColor : [],
                data : []
            };
            this.anneesExperience.forEach((anneeExperience, idx) => {
                if(this.chartData[anneeExperience.id])
                {
                    labels.push(anneeExperience.text);
                    dataset.data.push(this.chartData[anneeExperience.id]);
                    dataset.backgroundColor.push(default_colors[idx]);
                }
            });

            let data = { datasets : [dataset], labels : labels };
            this.renderChart(data)
        }

    }


</script>

