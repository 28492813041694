<template>

    <div>


        <div class="container">
            <FmedTitre :niveau="1" class="mt-2 mb-1">Gestion des formations</FmedTitre>
        </div>


        <div class="strip strip-secondary">
            <div class="container mt2">

                <div class="form">

                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <input type="search" class="form-control" v-model="keywords"
                                       placeholder="Par mots-clés"/>
                            </div>

                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="checkbox">
                                    <input type="checkbox" v-model="active">
                                    Statut actif
                                </label>
                            </div>

                        </div>
                    </div>

                </div>


                <div class="pull-right">
                    <FmedBouton @click="updateRows">Chercher</FmedBouton>
                </div>


            </div>
        </div>

        <LoadingOverlay class="text-center mt1" :active.sync="isLoading"></LoadingOverlay>

        <div v-if="!isLoading">

            <div class="container mt-1">

                <div class="pull-right">
                    <FmedBouton couleur="encadre" @click="afficherFenetreModaleCreerFormation = true">Ajouter une formation
                    </FmedBouton>
                </div>


                <datatable :columns="columns" :data="rows" :per-page="15" class="mt1">

                    <template slot-scope="{data}">
                        <tr v-for="formation in data" :key="formation.id">
                            <td>{{ formation.nom }}</td>
                            <td style="white-space: nowrap;text-align: center"><router-link :to="{name : 'admin.evaluations', query : { formation : formation.id }}"> {{ formation.nbEvaluations }} </router-link>
                            </td>
                            <td style="text-align: center">


                                <Light v-model="formation.active" size="15px"></Light>
                            </td>
                            <td align="center"><i class="icon icon-edit icon-link" style="cursor: pointer"
                                                  @click="editFormation(formation)"></i></td>
                        </tr>
                    </template>


                </datatable>
            </div>
        </div>

        <FenetreModaleCreerFormation :afficher="afficherFenetreModaleCreerFormation" @annuler="afficherFenetreModaleCreerFormation = false"  @sauvegarder="onFormationCreate"></FenetreModaleCreerFormation>
    </div>


</template>


<script>

    import Api from '../../api';
    import {FmedTitre, FmedBouton} from '@fmedul/panier-vue';
    import LoadingOverlay from 'vue-loading-overlay'
    import Datatable from "../../components/Datatable";
    import Light from "../../components/Light";
    import FenetreModaleCreerFormation from "../../components/modals/FenetreModaleCreerFormation";

    const api = Api.getInstance();

    export default {
        components: {FmedTitre, FmedBouton, LoadingOverlay, Datatable, Light, FenetreModaleCreerFormation},

        data: function () {

            return {
                allFormations: [],
                keywords: '',
                active: true,

                itemsPerPage: 15,

                loadingdemandesAttestations: false,

                columns: [
                    {label: 'Formation', field: 'nom', sortable: true},
                    {
                        label: 'Évaluation', sortable: true, sortFunction: (a, b) => {
                            let first = a.nbEvaluations;
                            let second = b.nbEvaluations;

                            return (first > second) ? 1 : ((first < second) ? -1 : 0)
                        }
                    },
                    {
                        label: 'Statut', field: 'status', sortFunction: function (a, b) {
                            let first = a.actif ? 1 : 0;
                            let second = b.actif ? 1 : 0;

                            return (first > second) ? 1 : ((first < second) ? -1 : 0)
                        }, sortable: true, width: '75px'
                    },
                    {label: '', width: '50px'},
                ],

                rows: [],

                page: 1,
                per_page: 10,
                allOrganismes: [],

                currentEditDemandeAttestation: null,

                isLoading: true,

                afficherFenetreModaleCreerFormation : false
            }

        },

        methods: {

            async updateRows() {
                this.isLoading = true;
                this.rows = await this.launchSearch();
                this.isLoading = false;
            },

            launchSearch() {
                return api.searchFormations(this.keywords, this.active);
            },

            editFormation(formation) {
                this.$router.push({path: '/admin/formations/' + formation.id});
            },


            async openEditFormationModal(formation) {


                this.$modal.show('edit_formation', {
                    formation: formation
                });

            },

            ouvrirModalCreerFormation() {
                this.$modal.show('create_formation')
            },


            async onFormationCreate() {
                this.$notify({
                    group: 'alerts',
                    title: 'Succès',
                    text: 'Formation créée avec succès',
                    duration: 10000
                });

                this.afficherFenetreModaleCreerFormation = false;

                await this.updateRows();
            },

            async onOrganismeUpdate() {
                this.$notify({
                    group: 'alerts',
                    title: 'Succès',
                    text: 'Organisme mis à jour avec succès',
                    duration: 10000
                });

                this.rows = await this.launchSearch();
            },


        },

        created: async function () {

            var _this = this;

            Promise.all([
                this.launchSearch(),
                api.getOrganismes()
            ]).then(function (results) {
                _this.rows = results[0];
                _this.allOrganismes = results[1];
                _this.isLoading = false;
            });

        }

    }


</script>
