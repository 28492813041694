<script>

    import { Pie } from 'vue-chartjs'

    export default {

        name : 'profession-pie-chart',

        extends: Pie,

        data: function () {
            return {

                professions : [
                    {id: 'educateur', text: "Éducateur"},
                    {id: 'enseignant', text: "Enseignant"},
                    {id: 'ergotherapeute', text: "Ergothérapeute"},
                    {id: 'gestionnaire', text: "Gestionnaire"},
                    {id: 'gestionnaire_de_cas', text: "Gestionnaire de cas"},
                    {id: 'infirmiere', text: "Infirmière"},
                    {id: 'infirmiere_auxiliere', text: "Infirmière auxiliaire"},
                    {id: 'inhalotherapeute', text: "Inhalothérapeute"},
                    {id: 'medecin', text: "Médecin"},
                    {id: 'nutritionniste', text: "Nutritionniste"},
                    {id: 'pharmacien', text: "Pharmacien"},
                    {id: 'physiotherapeute', text: "Physiothérapeute"},
                    {id: 'prepose_aux_beneficiaires', text: "Préposé aux bénéficiaires"},
                    {id: 'psychologue', text: "Psychologue"},
                    {id: 'travailleur_social', text: "Travailleur social"},
                    {id: 'personne_ressource', text: "Personne-ressource/Conseillère"},
                    {id: 'autre', text: "Autre"}
                ]

            }
        },

        props: ['chartData'],


        mounted() {

            let default_colors = ['#3366CC','#DC3912','#FF9900','#109618','#990099','#3B3EAC','#0099C6','#DD4477','#66AA00','#B82E2E','#316395','#994499','#22AA99','#AAAA11','#6633CC','#E67300','#8B0707','#329262','#5574A6','#3B3EAC']


            let labels = [];
            let dataset = {
                backgroundColor : [],
                data : []
            };
            this.professions.forEach((profession, idx) => {
                if(this.chartData[profession.id])
                {
                    labels.push(profession.text);
                    dataset.data.push(this.chartData[profession.id]);
                    dataset.backgroundColor.push(default_colors[idx]);
                }
            });

            let data = { datasets : [dataset], labels : labels };
            this.renderChart(data)
        }

    }


</script>

