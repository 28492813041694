<template>

    <div>


        <div class="container">
            <FmedTitre :niveau="1" class="mt-2 mb-1">Consultation de la liste des participants</FmedTitre>
        </div>


        <div class="strip strip-secondary mt1">
            <div class="container mt2">

                <div class="form">

                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <FmedChampSaisie v-model="mots_cles" @toucheEntree="recherche"
                                                 placeholder="Par mots-clés"></FmedChampSaisie>

                            </div>

                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <FmedListeDeroulante :options="organismes" cle-libelle="nom" v-model="organisme"
                                                     placeholder="Organisme"></FmedListeDeroulante>

                            </div>

                        </div>
                    </div>
                    <div class="row mt2 align-center">
                        <div class="col-sm-2"><span class="text-muted">Période des formations :</span></div>
                        <div class="col-sm-10 flex">
                            <div class="align-center flex align-center">
                                <span class="text-muted">de</span>
                                <FmedChampDate v-model="date_debut"></FmedChampDate>
                                <span class="text-muted" style="margin-left: 1em">à</span>
                                <FmedChampDate v-model="date_fin"></FmedChampDate>
                            </div>
                        </div>
                    </div>

                </div>


                <div class="pull-right">
                    <FmedBouton @click="recherche">Chercher</FmedBouton>
                </div>


            </div>
        </div>

        <LoadingOverlay class="text-center mt1" :active.sync="isLoading"></LoadingOverlay>

        <div v-if="!isLoading">

            <div class="container mt2">

                <Datatable :columns="colonnes" :data="evenements" :per-page="15" class="mt1">

                    <template slot-scope="{data}">
                        <tr v-for="evenement in data" :key="evenement.id">
                            <td>{{ evenement.date | shortDate }}</td>
                            <td>{{ evenement.formation.nom }}</td>
                            <td>{{ evenement.organisme.nom }}</td>
                            <td class="text-center">
                                <router-link :to="{path: '/admin/evenements/' + evenement.id}">{{
                                    evenement.nombrePresences }}
                                </router-link>
                            </td>
                        </tr>
                    </template>


                </Datatable>
            </div>
        </div>

    </div>


</template>


<script>

    import {FmedTitre, FmedChampSaisie, FmedListeDeroulante, FmedChampDate, FmedBouton} from '@fmedul/panier-vue'
    import LoadingOverlay from 'vue-loading-overlay'
    import Datatable from '../../components/Datatable'
    import Api from '../../api';


    const api = Api.getInstance();

    export default {
        components: {FmedTitre, FmedChampSaisie, FmedListeDeroulante, FmedChampDate, LoadingOverlay, Datatable, FmedBouton},
        data: function () {

            return {

                mots_cles: '',
                organisme: null,
                date_debut: null,
                date_fin: null,

                organismes: [],

                evenements: [],

                isLoading: true,

                colonnes: [
                    {label: 'Date de la formation'},
                    {label: 'Formation'},
                    {label: 'Organisme'},
                    {label: 'Présences'},
                ],
            }

        },

        methods: {


            recherche: async function () {
                this.isLoading = true;
                this.evenements = await api.rechercheEvenements(this.mots_cles,
                    this.organisme ? this.organisme.id : null,
                    this.date_debut,
                    this.date_fin
                )
                this.isLoading = false;
            },


        },


        created: async function () {

            this.organismes = await api.getOrganismes();
            await this.recherche();


        }

    }


</script>
