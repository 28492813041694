<template>

  <FmedFenetreModale :afficher="organismeId !== null">


    <div v-if="organisme" class="modal">
      <div class="form">
        <FmedTitre :niveau="1" class="mb-1">Modifier un organisme</FmedTitre>

        <FmedMessage type="erreur" :fermable="false" :afficher="erreurServeur !== null" class="mb-2">{{erreurServeur}}</FmedMessage>

        <div class="form-group">
          <FmedChampSaisie v-model="organisme.nom" placeholder="Nom de l'organisme *" :erreur="afficherErreurs && messageErreur('nom') !== null"
                           :message-erreur="messageErreur('nom')"/>
        </div>


        <div class="form-group">
          <FmedChampSaisie v-model="organisme.adresseCourriel" placeholder="Adresse courriel de l'organisme"
                           :erreur="afficherErreurs && messageErreur('adresseCourriel') !== null"
                           :message-erreur="messageErreur('adresseCourriel')" />
        </div>

        <div class="mt1">
          <VueSwitch v-model="organisme.actif" theme="uec" :type-bold="true" label="Statut"></VueSwitch>
        </div>


        <div class="mt1">
          <label>Formations pouvant être données par cet organisme</label>
          <div class="strip strip-secondary mt1">

            <LoadingOverlay class="text-center mt1" :active.sync="loadingFormations"></LoadingOverlay>
            <ul v-if="!loadingFormations">
              <li v-for="formation in formations" :key="formation.id" @click="ouvrirFormation(formation)"
                  style="cursor: pointer">{{
                  formation.nom
                }}
              </li>
              <span v-if="formations.length ===0">Aucune formation n'a été trouvée</span>
            </ul>
          </div>

        </div>


        <div class="row mt1">
          <div class="col-sm-12">
            <div class="text-right btn-group" @mouseover="showErrors = true">
              <FmedBouton couleur="encadre" @click="$emit('annuler')">Annuler</FmedBouton>
              <FmedBouton @click="save" :chargement="chargementBoutonSauvegarde"
                          :desactive="hasErrors && afficherErreurs">
                Enregistrer
              </FmedBouton>
            </div>
          </div>
        </div>
      </div>


    </div>
    <LoadingOverlay class="text-center mt1" :active.sync="loadingOrganisme" v-else></LoadingOverlay>


  </FmedFenetreModale>

</template>

<script>


import Api from '../../api';

const api = Api.getInstance();
import {FmedFenetreModale, FmedBouton, FmedTitre, FmedChampSaisie, FmedMessage} from '@fmedul/panier-vue'
import LoadingOverlay from 'vue-loading-overlay'
import VueSwitch from 'vue-switches'
import {courrielValide} from "../../../utilitaire";
import messages from "../../messages";


export default {

  components: {FmedFenetreModale, LoadingOverlay, VueSwitch, FmedBouton, FmedTitre, FmedChampSaisie, FmedMessage},
  data: function () {
    return {
      erreurServeur : null,
      afficherErreurs: false,
      organisme: null,
      formations: [],
      loadingFormations: true,
      loadingOrganisme: true,

      chargementBoutonSauvegarde: false


    }
  },

  props: {
    organismeId: {
      required: true
    }
  },

  watch: {
    async organismeId(nouvelleValeur) {
      if (nouvelleValeur !== null) {

        this.reinitialiser();
        this.organisme = await api.getOrganisme(nouvelleValeur);
        this.loadingOrganisme = false
        this.formations = await api.obtenirFormationsPourOrganisme(this.organisme.id);
        this.loadingFormations = false;
      }
    }
  },


  methods: {

    reinitialiser() {
      this.afficherErreurs = false;
      this.organisme = null;
      this.formations = [];
      this.loadingFormations = true;
      this.loadingOrganisme = true;
    },


    ouvrirFormation(formation) {
      this.$router.push({path: '/admin/formations/' + formation.id})
    },


    closeModal() {
      this.$modal.hide('modifier_organisme');
    },

    addError(errors, key, value) {
      if (!errors[key]) {
        errors[key] = [];
      }

      errors[key].push(value);
    },

    messageErreur(prop) {
      if(this.errors[prop]){
        return this.errors[prop][0];
      }

      return null;
    },


    async save() {
      if (this.hasErrors) {
        this.afficherErreurs = true;
        return;
      }

      this.erreurServeur = null;


      this.chargementBoutonSauvegarde = true;
      try{
        await api.updateOrganisme(this.organisme);

        this.$notify({
          group: 'alerts',
          title: 'Succès',
          text: 'Organisme mis à jour avec succès',
          duration: 10000
        });


        this.$emit('sauvegarder', this.organisme);
      }
      catch (err){
       this.erreurServeur = messages.message(err.erreur)
      }
      finally {
        this.chargementBoutonSauvegarde = false;
      }

    },


  },


  computed: {

    errors() {

      var errors = {};

      if (!this.organisme.nom || this.organisme.nom.length === 0) {
        this.addError(errors, 'nom', 'Le nom de l\'organisme ne doit pas être vide');
      }
      if (this.organisme.adresseCourriel && !courrielValide(this.organisme.adresseCourriel)) {
        this.addError(errors, 'adresseCourriel', "L'adresse courriel n'est pas valide");
      }


      return errors;
    },

    hasErrors() {
      return Object.keys(this.errors).length > 0 && this.errors.constructor === Object;
    }

  },


}


</script>

<style>

ul {
  list-style: none;
  padding: 0;
}

ul li {
  padding: 5px 0;
}
</style>


