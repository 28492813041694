<template>

  <div>


    <div class="container">
      <FmedTitre :niveau="1" class="mt-1 mb-1" v-if="compilation == null">Compilation des évaluations</FmedTitre>
      <FmedTitre :niveau="1" class="mt-1 mb-1" v-else>Évaluations compilées</FmedTitre>
    </div>


    <div class="strip strip-secondary">
      <div class="container mt2">

        <div class="form">

          <div class="row">

            <div class="col-sm-6">
              <div class="form-group">
                <FmedListeDeroulante v-model="selectedFormation" :options="formationsOrdreAlpha" cle-libelle="nom"
                                     placeholder="Laisser la champ vide pour compiler les évaluations de toutes les formations"
                                     :multiple="true">
                  <span slot="aucuneOption">Désolé, aucune formation n'a été trouvée</span>
                </FmedListeDeroulante>
              </div>

            </div>
            <div class="col-sm-6">
              <input type="text" v-model="formateur" class="form-control" placeholder="Formateur">
            </div>
          </div>
          <div class="row mt2 align-center">
            <div class="col-sm-2"><span class="text-muted">Période des formations :</span></div>
            <div class="col-sm-10 flex align-center">
              <span class="text-muted">de</span>
              <FmedChampDate v-model="dateDebut"></FmedChampDate>
              <span class="text-muted" style="margin-left: 1em">à</span>
              <FmedChampDate v-model="dateFin"></FmedChampDate>
            </div>
          </div>

        </div>


        <div class="pull-right">
          <FmedBouton couleur="encadre" @click="exportExcel">Télécharger la compilation</FmedBouton>
          <FmedBouton @click="compiler">Compiler les évaluations</FmedBouton>
        </div>


      </div>
    </div>

    <LoadingOverlay class="text-center mt1" :active.sync="isLoading"></LoadingOverlay>

    <div v-if="compilation">

      <div class="container mt2">
        <template v-if="compilation === null">
          Veuillez faire une recherche ci-dessus pour obtenir une compilation des évaluations
        </template>
        <template v-else-if="totalEvaluations === 0">
          Désolé, aucune évaluations ne correspond aux critères selectionnés
        </template>

        <template v-else>

          <div class="text-right">
            <strong>Compilation de {{ totalEvaluations }} évaluations</strong>
          </div>


          <h2>Formations compilées</h2>
          <table class="table table-striped">
            <tr>
              <th>Formation(s)</th>
              <th style="width: 50px">Nombre de répondants</th>
            </tr>
            <tr v-for="(val, id) in compilation.formationIds" :key="id">
              <td>{{ getFormation(id).nom }}</td>
              <td style="text-align: right">{{ val }}</td>
            </tr>
          </table>

          <table class="table table-striped">
            <tr>
              <th>Formateur(s)</th>
              <th style="width: 50px">Nombre de répondants</th>
            </tr>
            <tr v-for="(val, formateur) in compilation.formateur" :key="formateur">
              <td>{{ formateur }}</td>
              <td style="text-align: right">{{ val }}</td>
            </tr>
          </table>

          <table class="table table-striped">
            <tr>
              <th>Date(s)</th>
              <th style="width: 50px">Nombre de répondants</th>
            </tr>
            <tr v-for="(val, date) in compilation.dateFormation" :key="date">
              <td>{{ date }}</td>
              <td style="text-align: right">{{ val }}</td>
            </tr>
          </table>

          <table class="table table-striped">
            <tr>
              <th>Organisme(s)</th>
              <th style="width: 50px">Nombre de répondants</th>
            </tr>
            <tr v-for="(val, organismeId) in compilation.organismeIds" :key="organismeId">
              <td>{{ getOrganisme(organismeId).nom }}</td>
              <td style="text-align: right">{{ val }}</td>
            </tr>
          </table>

          <h2>Questions</h2>
          <table class="table table-striped">
            <tr>
              <th>LA FORMATION</th>
              <th style="width: 20px">4</th>
              <th style="width: 20px">3</th>
              <th style="width: 20px">2</th>
              <th style="width: 20px">1</th>
              <th style="width: 20px">SO</th>
            </tr>
            <tr v-for="(question, key) in questions.formation" :key="key">
              <td>{{ question.text }}</td>
              <td>{{ compilation[key][4] || 0 }}</td>
              <td>{{ compilation[key][3] || 0 }}</td>
              <td>{{ compilation[key][2] || 0 }}</td>
              <td>{{ compilation[key][1] || 0 }}</td>
              <td>{{ compilation[key]['SO'] || 0 }}</td>
            </tr>
            <tr>
              <td rowspan="2"><strong>Total</strong></td>
              <td><strong>{{ totalGroupeQuestionsReponse(questions.formation, 4) }}</strong></td>
              <td><strong>{{ totalGroupeQuestionsReponse(questions.formation, 3) }}</strong></td>
              <td><strong>{{ totalGroupeQuestionsReponse(questions.formation, 2) }}</strong></td>
              <td><strong>{{ totalGroupeQuestionsReponse(questions.formation, 1) }}</strong></td>
              <td><strong>{{ totalGroupeQuestionsReponse(questions.formation, 'SO') }}</strong></td>
            </tr>
            <tr>
              <td colspan="2" style="text-align: center">
                <strong>
                  {{ pourcentageReponsesPositives(questions.formation) }}%
                </strong>
              </td>
              <td colspan="2" style="text-align: center">
                <strong>
                  {{ pourcentageReponsesNegatives(questions.formation) }}%
                </strong>
              </td>
              <td>
                <strong>
                  {{ pourcentageReponsesSO(questions.formation) }}%
                </strong>
              </td>
            </tr>
          </table>

          <table class="table table-striped">
            <tr>
              <th>LA MÉTHODOLOGIE</th>
              <th style="width: 20px">4</th>
              <th style="width: 20px">3</th>
              <th style="width: 20px">2</th>
              <th style="width: 20px">1</th>
              <th style="width: 20px">SO</th>
            </tr>
            <tr v-for="(question, key) in questions.methodologie" :key="key">
              <td>{{ question.text }}</td>
              <td>{{ compilation[key][4] || 0 }}</td>
              <td>{{ compilation[key][3] || 0 }}</td>
              <td>{{ compilation[key][2] || 0 }}</td>
              <td>{{ compilation[key][1] || 0 }}</td>
              <td>{{ compilation[key]['SO'] || 0 }}</td>
            </tr>
            <tr>
              <td rowspan="2"><strong>Total</strong></td>
              <td><strong>{{ totalGroupeQuestionsReponse(questions.methodologie, 4) }}</strong></td>
              <td><strong>{{ totalGroupeQuestionsReponse(questions.methodologie, 3) }}</strong></td>
              <td><strong>{{ totalGroupeQuestionsReponse(questions.methodologie, 2) }}</strong></td>
              <td><strong>{{ totalGroupeQuestionsReponse(questions.methodologie, 1) }}</strong></td>
              <td><strong>{{ totalGroupeQuestionsReponse(questions.methodologie, 'SO') }}</strong></td>
            </tr>
            <tr>
              <td colspan="2" style="text-align: center">
                <strong>
                  {{ pourcentageReponsesPositives(questions.methodologie) }}%
                </strong>
              </td>
              <td colspan="2" style="text-align: center">
                <strong>
                  {{ pourcentageReponsesNegatives(questions.methodologie) }}%
                </strong>
              </td>
              <td>
                <strong>
                  {{ pourcentageReponsesSO(questions.methodologie) }}%
                </strong>
              </td>
            </tr>
          </table>

          <table class="table table-striped">
            <tr>
              <th>ÉVALUATION GLOBALE</th>
              <th style="width: 20px">4</th>
              <th style="width: 20px">3</th>
              <th style="width: 20px">2</th>
              <th style="width: 20px">1</th>
              <th style="width: 20px">SO</th>
            </tr>
            <tr v-for="(question, key) in questions.evaluationGlobale" :key="key">
              <td>{{ question.text }}</td>
              <td>{{ compilation[key][4] || 0 }}</td>
              <td>{{ compilation[key][3] || 0 }}</td>
              <td>{{ compilation[key][2] || 0 }}</td>
              <td>{{ compilation[key][1] || 0 }}</td>
              <td>{{ compilation[key]['SO'] || 0 }}</td>
            </tr>
            <tr>
              <td rowspan="2"><strong>Total</strong></td>
              <td><strong>{{ totalGroupeQuestionsReponse(questions.evaluationGlobale, 4) }}</strong></td>
              <td><strong>{{ totalGroupeQuestionsReponse(questions.evaluationGlobale, 3) }}</strong></td>
              <td><strong>{{ totalGroupeQuestionsReponse(questions.evaluationGlobale, 2) }}</strong></td>
              <td><strong>{{ totalGroupeQuestionsReponse(questions.evaluationGlobale, 1) }}</strong></td>
              <td><strong>{{ totalGroupeQuestionsReponse(questions.evaluationGlobale, 'SO') }}</strong></td>
            </tr>
            <tr>
              <td colspan="2" style="text-align: center">
                <strong>
                  {{ pourcentageReponsesPositives(questions.evaluationGlobale) }}%
                </strong>
              </td>
              <td colspan="2" style="text-align: center">
                <strong>
                  {{ pourcentageReponsesNegatives(questions.evaluationGlobale) }}%
                </strong>
              </td>
              <td>
                <strong>
                  {{ pourcentageReponsesSO(questions.evaluationGlobale) }}%
                </strong>
              </td>
            </tr>
          </table>


          <table class="table table-striped">
            <tr>
              <th>Référence</th>
              <th style="width: 50px">Nombre de répondants</th>
            </tr>
            <tr v-for="(reference, key) in references" :key="key">
              <td>
                {{ reference.text }}
                <template v-if="key === 'autre'">
                  <table class="table table-striped" v-if="key === 'autre'">
                    <tr v-for="(nb, autre) in compilation.referenceAutreDetails" :key="autre">
                      <td>{{ autre }}</td>
                      <td style="text-align: right; width: 50px">{{ nb }}</td>
                    </tr>
                  </table>
                </template>

                <template v-if="key === 'publicite_faite_par_organisation'">
                  <table class="table table-striped"
                         v-if="key === 'publicite_faite_par_organisation'">
                    <tr v-for="(nb, autre) in compilation.referencePubliciteOrganisationDetails" :key="autre">
                      <td>{{ autre }}</td>
                      <td style="text-align: right; width: 50px">{{ nb }}</td>
                    </tr>
                  </table>
                </template>
              </td>
              <td style="text-align: right">
                {{ compilation.reference[key] || 0 }}
              </td>
            </tr>

          </table>


          <table class="table table-striped">
            <tr>
              <th>Motif</th>
              <th style="width: 50px">Nombre de répondants</th>
            </tr>
            <tr v-for="(motif, key) in motifs" :key="key">
              <td>
                {{ motif.text }}
                <template v-if="key === 'autre'">
                  <table class="table table-striped" v-if="key === 'autre'">
                    <tr v-for="(nb, autre) in compilation.motifAutreDetails" :key="autre">
                      <td>{{ autre }}</td>
                      <td style="text-align: right; width: 50px">{{ nb }}</td>
                    </tr>
                  </table>
                </template>
              </td>
              <td style="text-align: right">
                {{ compilation.motif[key] || 0 }}
              </td>
            </tr>

          </table>


          <h2>Profil des répondants</h2>

          <div class="row">
            <div class="col-sm-4">
              <h3 class="text-center">Région Géographique</h3>
              <RegionGeographiquePieChart :chartData="compilation.regionGeographique"></RegionGeographiquePieChart>
            </div>
            <div class="col-sm-4">
              <h3 class="text-center">Dernier diplôme obtenu</h3>
              <DiplomePieChart :chartData="compilation.dernierDiplome"></DiplomePieChart>
            </div>
            <div class="col-sm-4">
              <h3 class="text-center">Milieu de travail</h3>
              <MilieuTravailPieChart :chartData="compilation.milieuTravail"></MilieuTravailPieChart>
            </div>
            <div class="col-sm-4">
              <h3 class="text-center">Profession</h3>
              <ProfessionPieChart :chartData="compilation.profession"></ProfessionPieChart>
            </div>
            <div class="col-sm-4">
              <h3 class="text-center">Expérience de travail</h3>
              <AnneeExperiencePieChart :chartData="compilation.nombreAnneeExperience"></AnneeExperiencePieChart>
            </div>
            <div class="col-sm-4">
              <h3 class="text-center">Groupe d'âge</h3>
              <GroupeAgePieChart :chartData="compilation.groupeAge"></GroupeAgePieChart>
            </div>
          </div>


          <h2>Opinions</h2>

          <h3>Les points forts</h3>
          <div class="opinions">
            <template v-for="(opinion, key) in compilation.pointsforts">
              <div class="opinion" :key="key" v-if="opinion">
                <span>{{ opinion }}</span>
              </div>
            </template>
          </div>

          <h3>Les points à améliorer</h3>
          <div class="opinions">
            <template v-for="(opinion, key) in compilation.pointsaameliorer">
              <div class="opinion" :key="key" v-if="opinion">
                <span>{{ opinion }}</span>
              </div>
            </template>
          </div>

          <h3>Les informations manquantes à inclure</h3>
          <div class="opinions">
            <template v-for="(opinion, key) in compilation.infosmanquantesainclure">
              <div class="opinion" :key="key" v-if="opinion">
                <span>{{ opinion }}</span>
              </div>
            </template>
          </div>

          <h3>À qui recommanderiez-vous cette activité de formation?</h3>
          <div class="opinions">
            <template v-for="(opinion, key) in compilation.aquirecommanderiezvousformation">
              <div class="opinion" :key="key" v-if="opinion">
                <span>{{ opinion }}</span>
              </div>
            </template>
          </div>

          <h3>Quelles sont les activités de formation que vous aimeriez voir offertes?</h3>
          <div class="opinions">
            <template v-for="(opinion, key) in compilation.formationvousaimeriezvoiroffertes">
              <div class="opinion" :key="key" v-if="opinion">
                <span>{{ opinion }}</span>
              </div>
            </template>
          </div>

          <h3>Quels sont les thèmes que vous aimeriez voir traités dans un colloque?</h3>
          <div class="opinions">
            <template v-for="(opinion, key) in compilation.themesColloques">
              <div class="opinion" :key="key" v-if="opinion">
                <span>{{ opinion }}</span>
              </div>
            </template>

          </div>

          <h3>Commentaires</h3>
          <div class="opinions">
            <template v-for="(opinion, key) in compilation.commentaires">
              <div class="opinion" :key="key" v-if="opinion">
                <span>{{ opinion }}</span>
              </div>
            </template>
          </div>


        </template>

      </div>
    </div>

  </div>


</template>


<script>

import Api from '../../api';
import moment from 'moment';

import {FmedListeDeroulante, FmedTitre, FmedChampDate, FmedBouton} from '@fmedul/panier-vue'

import LoadingOverlay from 'vue-loading-overlay'
import RegionGeographiquePieChart from "../../components/charts/RegionGeographiquePieChart";
import DiplomePieChart from "../../components/charts/DiplomePieChart";
import MilieuTravailPieChart from "../../components/charts/MilieuTravailPieChart";
import ProfessionPieChart from "../../components/charts/ProfessionPieChart";
import AnneeExperiencePieChart from "../../components/charts/AnneeExperiencePieChart";
import GroupeAgePieChart from "../../components/charts/GroupeAgePieChart";


const api = Api.getInstance();

export default {
  components: {
    GroupeAgePieChart,
    AnneeExperiencePieChart,
    ProfessionPieChart,
    MilieuTravailPieChart,
    DiplomePieChart,
    RegionGeographiquePieChart, FmedListeDeroulante, FmedTitre, FmedChampDate, LoadingOverlay, FmedBouton
  },
  data: function () {

    return {
      formations: [],
      organismes: [],

      selectedFormation: [],
      formateur: '',
      dateDebut: null,
      dateFin: null,

      isLoading: true,

      compilation: null,


      questions: [],
      references: [],
      motifs: [],
      regions: [],
      diplomes: [],
      milieuxTravail: []
    };

  },

  methods: {

    async exportExcel() {
      await api.telechargerRapportBilanEvaluations(
          this.selectedFormationsIds,
          this.formateur.length > 0 ? this.formateur : null,
          this.dateDebut ? moment.utc(this.dateDebut).format('YYYY-MM-DD') : null,
          this.dateFin ? moment.utc(this.dateFin).format('YYYY-MM-DD') : null
      )
    },

    pourcentageReponsesPositives(groupe) {
      return ((this.totalGroupeQuestionsReponse(groupe, 4) + this.totalGroupeQuestionsReponse(groupe, 3)) / this.totalGroupeQuestions(groupe) * 100).toFixed(0)
    },

    pourcentageReponsesNegatives(groupe) {
      return ((this.totalGroupeQuestionsReponse(groupe, 2) + this.totalGroupeQuestionsReponse(groupe, 1)) / this.totalGroupeQuestions(groupe) * 100).toFixed(0)
    },

    pourcentageReponsesSO(groupe) {
      return ((this.totalGroupeQuestionsReponse(groupe, 'SO')) / this.totalGroupeQuestions(groupe) * 100).toFixed(0)
    },

    getFormation: function (formationId) {
      return this.formations.find(formation => formation.id === formationId);
    },

    getOrganisme: function (organismeId) {
      console.log(organismeId);
      return this.organismes.find(organisme => organisme.id === organismeId);
    },

    totalGroupeQuestions(questionGroup) {
      let total = 0;
      Object.keys(questionGroup).forEach(question => {
        Object.keys(this.compilation[question]).forEach(reponse => {
          total += this.compilation[question][reponse] || 0;
        });

      });

      return total;
    },

    totalGroupeQuestionsReponse(questionGroup, val) {
      let total = 0;
      Object.keys(questionGroup).forEach(question => {
        total += this.compilation[question][val] || 0;
      });

      return total;
    },

    compiler: async function () {
      this.isLoading = true;
      this.compilation = null; // Force les graph à appeler 'mounted'
      this.compilation = await api.obtenirCompilationEvaluations(
          this.selectedFormationsIds,
          this.formateur.length > 0 ? this.formateur : null,
          this.dateDebut ? moment.utc(this.dateDebut).format('YYYY-MM-DD') : null,
          this.dateFin ? moment.utc(this.dateFin).format('YYYY-MM-DD') : null
      );

      this.isLoading = false;
    },


  },

  computed: {

    formationsOrdreAlpha() {
      return this.formations.slice().sort((a, b) => a.nom.localeCompare(b.nom));
    },

    totalEvaluations() {
      let total = 0;
      Object.keys(this.compilation.formationIds).forEach(id => {
        total += this.compilation.formationIds[id];
      });
      return total;
    },

    selectedFormationsIds() {
      return this.selectedFormation.map(formation => {
        return formation.id
      });
    },


  },

  created: async function () {
    this.formations = await api.searchFormations(null, true);
    this.organismes = await api.rechercheOrganismes(null, false);


    this.questions = {
      formation: {
        objectifsClairs: {
          text: "Les contenus étaient clairs et précis"
        },
        objectifsAtteints: {
          text: "Les objectifs ont été atteints"
        },
        contenuAdapteBesoins: {
          text: "Le contenu était adapté à mes besoins "
        },
        appliquerContenuTravail: {
          text: "Je peux appliquer directement le contenu à mon travail "
        },
        motiveAppliquerApprentissages: {
          text: "À la suite de cette activité, je suis fortement motivé à appliquer mes apprentissages dans le cadre de mon travail"
        },
        enseignementFaciliteApprentissage: {
          text: "Les méthodes d’enseignement (exercices et activités) ont facilité mon apprentissage "
        },
        formateurEcouteParticipants: {
          text: "Le formateur était à l’écoute de tous les participants "
        }
      },

      methodologie: {
        equilibreApproprie: {
          text: "L’équilibre entre les exposés, les discussions et les exercices était approprié"
        },
        themesSuccedeFaconAppropriee: {
          text: "Les thèmes traités se succédaient d’une façon appropriée"
        },
        cahierContientInfosUtiles: {
          text: "Le cahier du participant, s'il y a lieu, contenait toutes les informations utiles "
        },
        materielAdequat: {
          text: "Le matériel didactique était adéquat (audiovisuel, affiches, etc.) "
        }
      },

      evaluationGlobale: {
        dureeSessionAppropriee: {
          text: "La durée était appropriée au contenu présenté "
        },
        horairePrevuRespecte: {
          text: " L’horaire prévu a été respecté"
        },
        satisfaitFormation: {
          text: "Je suis satisfait de cette activité"
        },
        satisfaitAccueil: {
          text: "Je suis satisfait de l’accueil au moment de mon arrivée"
        },
        nbParticipantsAdapte: {
          text: "Le nombre de participants est adapté à l'activité"
        },
        localAdequat: {
          text: "Les locaux étaient adéquats"
        }
      }
    };

    this.references = {
      "publicite_faite_par_organisation": {
        text: "Publicité faite par l'organisation",
      },
      "demande_de_employeur": {
        text: "Demande de l’employeur ",
      },
      "suggestion_collegue": {
        text: "Suggestion d’un collègue",
      },
      "autre": {
        text: "Autre",
      }
    };

    this.motifs = {
      "besoin_professionnel_immediat": {
        text: "Besoin professionnel immédiat ",
      },
      "interet_personnel_pour_sujet": {
        text: " Intérêt personnel pour le sujet",
      },
      "gout_apprendre": {
        text: "Goût d’apprendre",
      },
      "autre": {
        text: "Autre",
      }
    };


    if (this.$route.query.formation) {
      const formation = this.getFormation(this.$route.query.formation);
      this.selectedFormation.push(formation);
      await this.compiler();
    }

    this.isLoading = false;
  }

}


</script>

<style lang="scss">

.opinions {

  max-height: 300px;
  overflow-y: scroll;
  border: 1px solid #a0a0a0;
  padding: 0 1em;

  .opinion {
    padding: 1em;
    white-space: break-spaces;

    &:not(:last-child) {
      border-bottom: 1px solid #a0a0a0;
    }
  }

}

</style>
