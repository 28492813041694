<script>

    import { Pie } from 'vue-chartjs'

    export default {

        name : 'milieu-travail-pie-chart',

        extends: Pie,

        data: function () {
            return {

                milieuxTravail : [
                    {id: 'centre_jour', text: "Centre de jour"},
                    {id: 'centre_readaptation', text: "Centre de réadaptation"},
                    {id: 'centre_hospitalier', text: "Centre hospitalier"},
                    {id: 'ch_soins_psychiatriques', text: "CH de soins psychiatriques"},
                    {id: 'chsld', text: "CHSLD"},
                    {id: 'hopital_jour', text: "Hôpital de jour"},
                    {id: 'clsc', text: "CLSC"},
                    {id: 'residence_privee', text: "Résidence privée"},
                    {id: 'cisss_et_ou_agence', text: "CISSS et/ou Agence"},
                    {id: 'etablissement_enseignement', text: "Établissement d’enseignement"},
                    {id: 'autre', text: "Autre"},
                ]

            }
        },

        props: ['chartData'],


        mounted() {

            let default_colors = ['#3366CC','#DC3912','#FF9900','#109618','#990099','#3B3EAC','#0099C6','#DD4477','#66AA00','#B82E2E','#316395','#994499','#22AA99','#AAAA11','#6633CC','#E67300','#8B0707','#329262','#5574A6','#3B3EAC']


            let labels = [];
            let dataset = {
                backgroundColor : [],
                data : []
            };
            this.milieuxTravail.forEach((milieuTravail, idx) => {
                if(this.chartData[milieuTravail.id])
                {
                    labels.push(milieuTravail.text);
                    dataset.data.push(this.chartData[milieuTravail.id]);
                    dataset.backgroundColor.push(default_colors[idx]);
                }
            });

            let data = { datasets : [dataset], labels : labels };
            this.renderChart(data)
        }

    }


</script>