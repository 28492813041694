<template>
  <div style="position: relative">
    <nav class="menu">
      <ul class="menu" :class="{'mobile-open' : mobileOpen}" v-click-outside="menuClickOutside">
        <li :class="{active: participantActif}">
          <a href="#">Participation <span class="caret"></span></a>
          <ul>
            <li>
              <router-link @click.native="mobileOpen = false" :to="{name : 'demandes-attestations'}">
                Demande d’attestation
              </router-link>
            </li>
            <li>
              <router-link @click.native="mobileOpen = false" :to="{name : 'evaluation'}">
                Formulaire d’évaluation
              </router-link>
            </li>

          </ul>
        </li>
        <li :class="{active: organisateurActif}">
          <a href="#">Organisation <span class="caret"></span></a>
          <ul>
            <li>
              <router-link @click.native="mobileOpen = false" :to="{name : 'depot-presences'}">
                Liste des présences
              </router-link>
            </li>

          </ul>
        </li>
        <li :class="{active : gestionActif}" v-if="isLoggedIn">
          <a>Gestion <span class="caret"></span></a>

          <ul>
            <li>
              <router-link @click.native="mobileOpen = false" :to="{name : 'admin.demandes-attestations'}"
              >Gestion des demandes d'attestation
              </router-link>
            </li>
            <li>
              <router-link @click.native="mobileOpen = false" :to="{name : 'admin.presences'}">Gestion
                des présences
              </router-link>
            </li>
            <li>
              <router-link @click.native="mobileOpen = false" :to="{name : 'admin.evaluations'}">
                Gestion de la compilation des évaluations
              </router-link>
            </li>
            <li>
              <router-link @click.native="mobileOpen = false" :to="{name : 'admin.organismes'}">Gestion
                des organismes
              </router-link>
            </li>
            <li>
              <router-link :to="{name : 'admin.formations'}">Gestion
                des formations
              </router-link>
            </li>
            <li>
              <router-link @click.native="mobileOpen = false" :to="{name : 'admin.rapports'}">Rapports
              </router-link>
            </li>
            <li>
              <router-link @click.native="mobileOpen = false" :to="{name : 'admin.superviseurs'}">Superviseurs
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
      <ul class="mobile">
        <li @click="openMobileMenu">
          <i class="icon icon-menu-reduire"></i>
        </li>
      </ul>
    </nav>
  </div>

</template>

<script>

export default {
  data: () => {
    return {
      mobileOpen: false
    }
  },

  methods: {
    menuClickOutside() {
      console.log(this.mobileOpen);
      if (this.mobileOpen) {
        this.mobileOpen = false;
      }
    },

    openMobileMenu() {
      setTimeout(() => {
        this.mobileOpen = true;
      }, 50)


    },
  },

  watch: {
    mobileOpen: function () {
      document.body.style.overflow = this.mobileOpen ? 'hidden' : 'auto';
    }
  }
  ,


  computed: {
    isLoggedIn() {
      return this.$store.state.loggedIn
    }
    ,

    participantActif() {
      return this.$route.meta.groupe === 'participant'
    }
    ,
    organisateurActif() {
      return this.$route.meta.groupe === 'organisateur'
    }
    ,
    gestionActif() {
      return this.$route.meta.groupe === 'admin'
    }
    ,
  }
}

</script>

<style lang="scss" scoped>

nav.menu {


  font-size: 18px;

  li ul li a {
    font-size: 16px;
  }

  @media (max-width: 600px) {
    position: sticky;
    top: 0;
  }

  ul.menu {
    @media (max-width: 600px) {

      display: flex;
      flex-direction: column;
      height: 100%;
      width: calc(100% - 75px);
      background-color: #fff;
      position: fixed;
      top: 0;
      left: 100%;
      z-index: 10;

      transition: left 0.5s;




      &.mobile-open {
        left: 75px;
        transition: left 0.5s;
        box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.5);


        li {
          margin: 0;
          line-height: initial;
          height: auto;
          padding: 0;

          a {
            padding: 10px;
          }

          ul {
            width: 100%;
            top: 0;
            box-shadow: none;
            position: relative;
            border: 0;
            border-radius: 0;
            background-color: #fff;

          }
        }
      }
    }


  }

  ul.mobile {
    display: flex;
    justify-content: flex-end;

    li {
      &:hover {
        background: none;
      }
    }


    @media (min-width: 601px) {
      display: none;
    }
  }


  li.active {
    background-color: #eee;
  }


  .caret {
    border-width: 6px 5px 0 5px;
  }

}

</style>
