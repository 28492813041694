<template>

    <div>
        <FmedFenetreModale :afficher="demandeAttestationId !== null">
            <div v-if="demandeAttestation" class="modal">
                <div class="header">
                    <h1>Demande d'attestation {{ demandeAttestation.id }}</h1>
                    <DropdownButton style="margin-left: auto;align-self: flex-end" :items="dropdownItems">
                        <span class="caret"></span>
                    </DropdownButton>
                </div>

                <div class="definitions mt1">
                    <div class="definition definition-inline">
                        <span class="title">Date du paiement : </span>
                        <span class="description">{{ demandeAttestation.datePaiement | shortDate }}</span>
                    </div>
                    <div class="definition definition-inline">
                        <span class="title">Montant payé : </span>
                        <span class="description">{{ demandeAttestation.montantPaye.toFixed(2) }}$</span>
                    </div>
                </div>
                <div class="form">
                    <div class="box">

                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>Nom : </label>
                                    <FmedChampSaisie v-model="demandeAttestation.nom"/>
                                    <div v-if="errors.lastName" class="error"><span>{{ errors.lastName[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>Prénom : </label>
                                    <FmedChampSaisie v-model="demandeAttestation.prenom"/>
                                    <div v-if="errors.firstName" class="error"><span>{{ errors.firstName[0] }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt1">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label>Formation : </label>
                                    <FmedListeDeroulante v-model="demandeAttestation.formation" :options="formations"
                                                         :permettre-vide="false" cle-libelle="nom"
                                                         placeholder="Saisir ou sélectionner une formation"
                                                         @input="formationChanged">
                                        <span slot="no-options">Désolé, aucune formation n'a été trouvée</span>
                                    </FmedListeDeroulante>
                                    <div v-if="errors.formation" class="error"><span>{{ errors.formation[0] }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt1">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label>Module : </label>
                                    <template v-if="chargementModules">
                                        <img src="../../assets/images/loader-dark.png" alt="loading"/>
                                    </template>
                                    <template v-else>
                                        <FmedListeDeroulante v-model="demandeAttestation.module"
                                                             :options="modules ? modules : []" cle-libelle="nom"
                                                             :permettre-vide="true"
                                                             placeholder="Saisir ou sélectionner un module"
                                                             v-if="demandeAttestation.formation && modules.length > 0">
                                            <span slot="aucuneOption">Désolé, aucun module n'a été trouvée</span>
                                        </FmedListeDeroulante>
                                        <span v-else>Cette formation ne possède aucun module</span>
                                    </template>


                                </div>
                            </div>
                        </div>
                        <div class="row mt1">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label>Organisme : </label>
                                    <FmedListeDeroulante v-model="demandeAttestation.organisme"
                                                         :options="organismes ? organismes : []" cle-libelle="nom"
                                                         placeholder="Saisir ou sélectionner une formation"
                                                         v-if="demandeAttestation.formation">
                                        <span slot="aucuneOption">Désolé, aucun organisme n'a été trouvée</span>
                                    </FmedListeDeroulante>
                                    <div v-if="errors.organisme" class="error"><span>{{ errors.organisme[0] }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt1">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label>Date de la formation : </label>
                                    <FmedChampDate  v-model="demandeAttestation.dateFormation"/>
                                </div>
                            </div>
                        </div>
                        <div class="row mt1">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label>Unités de formation continue : </label>
                                    <span>{{nombreUfc}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row mt1" v-if="demandeAttestation.fichierPreuve">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label>Fichier preuve de suivi de formation :</label>
                                    <a @click="telechargerFichierPreuve()">{{ demandeAttestation.fichierPreuve.nom
                                        }}</a>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="row mt1">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label>Courriel :</label>
                                <FmedChampSaisie v-model="demandeAttestation.courriel"/>
                                <div v-if="errors.email" class="error"><span>{{ errors.email[0] }}</span></div>
                            </div>
                        </div>
                    </div>


                    <div class="row mt1">
                        <div class="col-sm-12">

                            <template v-if="loadingPresence">
                                <img src="../../assets/images/loader-dark.png" alt="loading"/>
                            </template>
                            <template v-else-if="demandeAttestation.presenceLiee">
                                <span class="text-muted">Présence liée à la demande</span>
                                <div class="box">
                                    <div>
                                        <div>
                                            <label>Nom et prénom : </label>
                                            <span>{{ demandeAttestation.presenceLiee.nom }}, {{ demandeAttestation.presenceLiee.prenom }}</span>
                                        </div>
                                        <div>
                                            <label>Date : </label>
                                            <span>{{ demandeAttestation.presenceLiee.evenement.date | shortDate }}</span>
                                        </div>
                                        <div>
                                            <label>Formation : </label>
                                            <span>{{ demandeAttestation.presenceLiee.evenement.formation.nom }}</span>
                                        </div>
                                        <div>
                                            <label>Organisateur : </label>
                                            <span>{{ demandeAttestation.presenceLiee.evenement.organisme.nom }}</span>
                                        </div>
                                    </div>
                                    <div class="text-right">
                                        <a @click="delierPresence()">Retirer la présence</a>
                                    </div>

                                </div>
                            </template>
                            <template v-else>
                                <div v-if="presencesSemblables.length > 0">
                                    <span class="text-muted">{{presencesSemblables.length}} correspondance(s) de présence a été trouvée(s) :</span>

                                    <div class="correspondances">
                                        <div class="box" v-for="(presenceSemblable, idx) in presencesSemblables"
                                             :key="idx">
                                            <div>
                                                <label>Nom et prénom : </label>
                                                <span>{{ presenceSemblable.presence.nom }}, {{ presenceSemblable.presence.prenom }}</span>
                                            </div>
                                            <div>
                                                <label>Date : </label>
                                                <span>{{ presenceSemblable.presence.evenement.date | shortDate }}</span>
                                            </div>
                                            <div>
                                                <label>Formation : </label>
                                                <span>{{ presenceSemblable.presence.evenement.formation.nom }}</span>
                                            </div>
                                            <div>
                                                <label>Organisme : </label>
                                                <span>{{ presenceSemblable.presence.evenement.organisme.nom }}</span>
                                            </div>
                                            <div class="text-right">
                                                <a @click="lierPresence(presenceSemblable.presence)">Lier la
                                                    présence</a>
                                            </div>

                                        </div>
                                    </div>


                                </div>
                                <div v-else class="text-muted" style="font-style: italic">
                                    Aucune correspondance de présence n'a été trouvée
                                </div>
                            </template>

                        </div>


                    </div>


                    <div class="row mt1">
                        <div class="col-sm-12">
                            <div v-if="!showNote"><a @click="showNote = true">Ajouter une note</a></div>
                            <div v-else>
                                <label>Notes :</label>
                                <VueEditor v-model="demandeAttestation.notes" id="rte"></VueEditor>
                            </div>

                        </div>
                    </div>

                    <div class="row mt1">
                        <div class="col-sm-12">
                            <label>
                                <input type="checkbox" v-model="demandeAttestation.enRevision">
                                En résivion
                            </label>
                            <FmedBouton couleur="encadre" :chargement="chargementPrevisualisation" @click="ouvrirPrevisualisationPdf"
                                        style="margin-left: 1em" :desactive="hasErrors">Enregistrer et voir un aperçu de
                                l'attestation
                            </FmedBouton>
                        </div>
                    </div>
                    <div class="row" v-if="demandeAttestation.dateEmission">
                        <div class="col-sm-12">
                            <div class="text-right">
                                <label>Date d'émission de l'attestation :</label> {{ demandeAttestation.dateEmission | shortDate }}
                            </div>
                        </div>
                    </div>


                    <div class="row mt1">
                        <div class="col-sm-12">
                            <div class="text-right btn-group">
                                <div class="text-right btn-group">
                                    <FmedBouton couleur="secondaire" @click="$emit('fermer')">Annuler</FmedBouton>
                                    <FmedBouton :chargement="chargementBoutonSauvegarder" @click="boutonSauvegarderClick"
                                                :desactive="this.hasErrors">Enregistrer
                                    </FmedBouton>
                                    <FmedBouton :chargement="issuing" @click="boutonEmettreClick"
                                                :desactive="this.hasErrors">Émettre l'attestation
                                    </FmedBouton>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <LoadingOverlay class="text-center" :active="demandeAttestation == null"></LoadingOverlay>


        </FmedFenetreModale>

        <FmedFenetreDialogue :afficher="afficherDialogueSupprimer" titre="Supprimer la demande d'attestation"
                             @annulation="afficherDialogueSupprimer = false"
                             @confirmation="supprimerDemandeAttestation">Êtes vous certain de vouloir supprimer cette
            demande d'attestation ?
        </FmedFenetreDialogue>
    </div>


</template>

<script>


    import validator from 'validator';
    import Api from '../../api';
    import {
        FmedFenetreModale,
        FmedBouton,
        FmedChampDate,
        FmedFenetreDialogue,
        FmedChampSaisie,
        FmedListeDeroulante
    } from "@fmedul/panier-vue";
    import LoadingOverlay from 'vue-loading-overlay';
    import {VueEditor} from "vue2-editor";
    import DropdownButton from "../DropdownButton";
    import moment from "moment";

    const api = Api.getInstance();

    export default {
        name: 'FenetreModaleDemandeAttestation',
        components: {
            FmedFenetreModale,
            FmedBouton,
            LoadingOverlay,
            VueEditor,
            FmedChampDate,
            DropdownButton,
            FmedFenetreDialogue,
            FmedChampSaisie,
            FmedListeDeroulante
        },
        data: function () {
            return {

                formations: [],
                organismes: [],
                modules: [],

                demandeAttestation: null,


                presenceSemblableALier: null,
                presencesSemblables: [],


                loadingPresence: false,
                presenceLiee: null,

                dropdownItems: [{
                    label: 'Supprimer la demande', action: () => {
                        this.afficherDialogueSupprimer = true
                    }
                }],

                showNote: false,

                chargementPrevisualisation: false,

                chargementBoutonSauvegarder: false,
                issuing: false,

                afficherDialogueSupprimer: false,

                chargementModules: false


            }
        },

        props: {
            demandeAttestationId: {
                required: true
            },
        },

        watch: {
            demandeAttestationId(nouvelleValeur) {
                if (nouvelleValeur !== null) {
                    this.chargerDemandeAttestation();
                } else {
                    this.reinitialiserFormulaire();
                }

            },
        },


        methods: {

            async telechargerFichierPreuve() {
                await api.telechargerFichier(this.demandeAttestation.fichierPreuve)
            },

            reinitialiserFormulaire() {
                this.demandeAttestation = null;
                this.presenceSemblableALier = null;
                this.presencesSemblables = [];
            },

            async formationChanged() {
                this.demandeAttestation.organisme = null;
                this.demandeAttestation.module = null;

                this.chargementModules = true;
                this.modules = await api.obtenirModulesDeFormation(this.demandeAttestation.formation.id);
                this.chargementModules = false;

                this.organismes = await api.getOrganismesFromFormation(this.demandeAttestation.formation.id);


            },

            async chargerDemandeAttestation() {

                this.formations = await api.getAllFormations();



                this.loadingPresence = true;
                this.presenceLiee = null;
                this.presencesSemblables = [];

                this.demandeAttestation = await api.getDemandeAttestation(this.demandeAttestationId);
                this.showNote = this.demandeAttestation.notes;

                this.chargementModules = true;
                this.modules = await api.obtenirModulesDeFormation(this.demandeAttestation.formation.id);
                this.chargementModules = false;

                this.organismes = await api.getOrganismesFromFormation(this.demandeAttestation.formation.id);

                if (this.presenceLiee == null) {
                    this.presencesSemblables = await api.demandeAttestationPresencesSemblables(this.demandeAttestation.id);
                }

                this.loadingPresence = false;
            },

            async delierPresence() {
                this.loadingPresence = true;
                await api.delierPresenceDemandeAttestation(this.demandeAttestation.presenceLiee.id);
                this.demandeAttestation.presenceLiee = null;

                this.presencesSemblables = await api.demandeAttestationPresencesSemblables(this.demandeAttestation.id);
                this.loadingPresence = false;
            },

            async supprimerDemandeAttestation() {

                await api.deleteDemandeAttestation(this.demandeAttestation.id);
                this.afficherDialogueSupprimer = false;
                this.$emit('delete');

            },

            async lierPresence(presenceSemblable) {
                this.loadingPresence = true;
                await api.lierPresenceDemandeAttestation(presenceSemblable.id, this.demandeAttestation.id);
                this.demandeAttestation.presenceLiee = presenceSemblable;
                this.loadingPresence = false;
            },


            addError(errors, key, value) {
                if (!errors[key]) {
                    errors[key] = [];
                }

                errors[key].push(value);
            },

            async sauvegarde() {
                if (this.hasErrors)
                    return;

                await api.updateDemandeAttestation(
                    this.demandeAttestation.id,
                    this.demandeAttestation.prenom,
                    this.demandeAttestation.nom,
                    this.demandeAttestation.courriel,
                    this.demandeAttestation.enRevision,
                    this.demandeAttestation.notes,
                    moment.utc(this.demandeAttestation.dateFormation).format('YYYY-MM-DD'),
                    this.demandeAttestation.formation.id,
                    this.demandeAttestation.module ? this.demandeAttestation.module.id : null,
                    this.demandeAttestation.organisme.id,
                );
            },


            async boutonSauvegarderClick() {

                this.chargementBoutonSauvegarder = true;

                await this.sauvegarde();

                this.$emit('sauvegarder', this.demandeAttestation);

                this.chargementBoutonSauvegarder = false;
            },

            async boutonEmettreClick() {
                this.issuing = true;
                await this.sauvegarde();
                await api.emettreAttestation(this.demandeAttestation.id);
                this.issuing = false;

                this.$emit('emettre', this.demandeAttestation);
                this.issuing = false;
            },

            async ouvrirPrevisualisationPdf() {
                this.chargementPrevisualisation = true;
                await this.sauvegarde();
                await api.downloadAttestationPdf(this.demandeAttestation.id);
                this.chargementPrevisualisation = false;
            }
        }
        ,


        computed: {

            nombreUfc() {
                if (this.demandeAttestation.module) {
                    return this.demandeAttestation.module.nombreUfc
                }
                if (this.demandeAttestation.formation) {
                    return this.demandeAttestation.formation.nombreUfc
                }

                return null;
            }
            ,

            errors() {

                let errors = {};

                if (!this.demandeAttestation.formation) {
                    this.addError(errors, 'formation', 'La formation est requise');
                }

                if (!this.demandeAttestation.organisme) {
                    this.addError(errors, 'organisme', "L'organisme est requise");
                }


                if (this.demandeAttestation.prenom.trim().length === 0) {
                    this.addError(errors, 'firstName', 'Le prénom ne doit pas être vide');
                }

                if (this.demandeAttestation.nom.trim().length === 0) {
                    this.addError(errors, 'lastName', 'Le nom ne doit pas être vide');
                }

                if (!validator.isEmail(this.demandeAttestation.courriel)) {
                    this.addError(errors, 'email', 'Le courriel n\'est pas valide');
                }

                return errors;
            }
            ,

            hasErrors() {
                return Object.keys(this.errors).length > 0 && this.errors.constructor === Object;
            }

        }
        ,

    }


</script>

<style lang="scss">

    #rte {
        height: 200px;
    }

</style>


