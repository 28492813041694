<template>

    <FmedFenetreModale :afficher="formationId !== null" :afficher-bouton-fermer="true" @fermer="$emit('fermer')">

        <div class="row">
            <div class="col-sm-12">
                <div class="modal">
                    <FmedTitre :niveau="1" class="mb-1">Modifier un fichier</FmedTitre>

                    <DragFileUpload @change="ajouterFichierAccreditation"
                                      v-if="!fichiersAccreditationsLoading"></DragFileUpload>

                    <LoadingOverlay class="text-center mt1" :active.sync="fichiersAccreditationsLoading" :width="25"
                                     :height="25"></LoadingOverlay>


                    <div class="mt1" v-if="!fichiersAccreditationsLoading">
                        <table style="width: 100%" class="liste-fichiers">
                            <tr v-for="fichier in fichiersAccreditations" :key="fichier.id">
                                <td width="50px"><i class="icon icon-paperclip"></i></td>
                                <td><a @click="telecharger(fichier)">{{ fichier.nom }}</a></td>
                                <td width="50px"><i class="icon icon-close"
                                                    @click="supprimerFichierAccreditation(fichier)"
                                                    style="cursor: pointer"></i></td>
                            </tr>
                        </table>
                    </div>


                </div>
            </div>
        </div>


    </FmedFenetreModale>

</template>

<script>


    import Api from "../../api";

    import {FmedFenetreModale, FmedTitre} from '@fmedul/panier-vue'
    import LoadingOverlay from 'vue-loading-overlay'
    import DragFileUpload from "../DragFileUpload";

    const api = Api.getInstance();

    export default {
        components : {FmedFenetreModale, LoadingOverlay, DragFileUpload, FmedTitre},

        data: function () {
            return {
                formation : null,
                fichiersAccreditations: [],
                fichiersAccreditationsLoading: false
            }
        },

        props: {
            formationId : {
                required : false
            }
        },

        watch : {
            async formationId(nouvelleValeur) {
                if(nouvelleValeur !== null) {
                    this.formation = null;
                    this.fichiersAccreditationsLoading = true;
                    this.formation = await api.getFormation(nouvelleValeur);

                    await this.chargerFichierAccreditations();
                }

            }
        },


        methods: {

            async telecharger(fichier){
                await api.telechargerFichier(fichier);
            },

            supprimerFichierAccreditation(fichier) {

                if (confirm("Êtes-vous certain de vouloir supprimer le fichier " + fichier.nom + " ?")) {
                    api.supprimerFichierAccreditationFormation(this.formation.id, fichier.id).then(() => {
                        this.chargerFichierAccreditations();
                    });
                }


            },

            async beforeOpen() {

            },

            async chargerFichierAccreditations() {
                this.fichiersAccreditationsLoading = true;
                this.fichiersAccreditations = await api.getFichiersAccreditationFormation(this.formation.id);
                this.fichiersAccreditationsLoading = false;
            },



            async ajouterFichierAccreditation(fichier) {
                this.fichiersAccreditationsLoading = true;
                await api.ajouterFichierAccreditationFormation(this.formation.id, fichier);
                await this.chargerFichierAccreditations();
            },

        },


    }


</script>

<style>

    .liste-fichiers tr:not(:last-child) td {
        padding: 10px 0;
        border-bottom: 1px solid #aaaaaa;
    }

    .liste-fichiers tr td {
        color: #aaa;
        padding: 10px 0;
    }


</style>


