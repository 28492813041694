<template>


    <fmed-application theme="fsi">
        <div class="app">

            <header class="entete-ul" style="border-bottom: solid 2px #dedede;">
                <div class="entete-contenu">
                    <a href="http://www.ulaval.ca" class="logo-ul" target="_blank">
                        <object type="image/svg+xml" data="https://www.fmed.ulaval.ca/panier_objet/img/logo-ul.svg"
                                width="125"
                                height="57" class="logo-complet trescomplet">
                            Logo Université Laval
                        </object>
                        <object type="image/svg+xml" data="https://www.fmed.ulaval.ca/panier_objet/img/logo-ul.svg"
                                width="125"
                                height="57" class="logo-tablet">
                            Logo Université Laval
                        </object>
                        <object type="image/svg+xml" data="https://www.fmed.ulaval.ca/panier_objet/img/logo-ul-mobile.svg"
                                width="35" height="44" class="logo-mobile">
                            Logo Université Laval
                        </object>
                    </a>

                    <div class="organisation" itemscope="" itemtype="http://schema.org/CollegeOrUniversity" role="banner">
                        <div class="titres">
                            <a href="http://www.fsi.ulaval.ca/" class="faculte" target="_blank">Faculté des sciences
                                infirmières</a>
                        </div>
                    </div>


                </div>

                <FmedBande>
                    <h1>Demandes d’attestation de formation continue pour l’obtention d’unités de formation continue (UFC)</h1>
                </FmedBande>

                <Navigation />


            </header>

            <div class="content">
                <notifications group="alerts" position="bottom right"></notifications>
                <div>
                    <router-view></router-view>
                </div>
            </div>


            <footer class="applicatif footerappnoir">
              <p class="ul-pied-page-liens signaler-probleme">
                <router-link :to="{name : 'signaler-probleme'}">Signaler un problème</router-link>
              </p>
                <p class="ul-footer-copyrights text-muted">
                  © 2021 Faculté des sciences infirmières, Université Laval | <FmedLien v-if="!$store.state.loggedIn" class="text-muted" :destination="{name : 'admin.connexion'}" icone="icon-lock-closed"></FmedLien>
                  <span v-if="$store.state.loggedIn"><i class="icon icon-lock-open" /> <span class="lien" @click="logout">Déconnexion</span></span>
                </p>

            </footer>

        </div>
    </fmed-application>


</template>

<script>

    import {FmedApplication, FmedBande, FmedLien} from '@fmedul/panier-vue'
    import Navigation  from './components/Navigation'
    import Api from './api'

    const api = Api.getInstance();

    export default {

        name: 'app',

        components : {FmedApplication, FmedBande, Navigation,FmedLien},

      methods : {
          logout() {
            api.logout();
            this.$router.push({name : 'admin.connexion'});
          }
      }

    }


</script>


<style lang="scss">

    body, html {
        height : 100%;
        font-family: Source Sans Pro,sans-serif;;
    }

    .app{
        height : 100%;
        display: flex;
        flex-direction: column;
    }

    .content {
        flex : 1 0 auto;
    }


    .fmed-application.fmed-application-fsi{
        footer {

          p {
            line-height: 1.5em;
            padding-top: 10px;
            padding-bottom: 10px;
          }

          .signaler-probleme {
            color : #6F8AB9;
            padding-bottom: 0;

          }

          .lien {
            color : #6F8AB9;
            &:hover {
              cursor: pointer;
              color: #6992CC;
            }
          }

            flex-shrink : 0;
            a {
                color: inherit;
            }
        }
    }

    .vue-switcher-theme--uec {
        &.vue-switcher-color--default {
            div {
                background-color: #77c88d;

                &:after {
                    // for the circle on the switch
                    background-color: #53b96e;
                }
            }

            &.vue-switcher--unchecked {
                div {
                    background-color: lighten(#888c8b, 30%);

                    &:after {
                        background-color: lighten(#888c8b, 10%);
                    }
                }
            }
        }
    }


</style>