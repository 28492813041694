<template>

  <FmedFenetreModale :afficher="afficher">
    <div v-if="organisme" class="modal">
      <div class="form">
        <FmedTitre :niveau="1" class="mb-1">Ajouter un organisme</FmedTitre>

        <FmedMessage type="erreur" :fermable="false" :afficher="erreurServeur !== null" class="mb-2">
          {{ erreurServeur }}
        </FmedMessage>

        <div class="form-group">
          <FmedChampSaisie v-model="organisme.nom" placeholder="Nom de l'organisme *"
                           :erreur="afficherErreurs && messageErreur('nom') !== null"
                           :message-erreur="messageErreur('nom')"/>
        </div>


        <div class="form-group">
          <FmedChampSaisie v-model="organisme.adresseCourriel" placeholder="Adresse courriel de l'organisme"
                           :erreur="afficherErreurs && messageErreur('adresseCourriel') !== null"
                           :message-erreur="messageErreur('adresseCourriel')"/>
        </div>


        <div class="row mt1">
          <div class="col-sm-12">
            <div class="text-right btn-group" @mouseover="showErrors = true">
              <FmedBouton couleur="encadre" @click="$emit('annuler')">Annuler</FmedBouton>
              <FmedBouton @click="save" :chargement="chargementBoutonSauvegarde"
                          :desactive="hasErrors && afficherErreurs">Enregistrer
              </FmedBouton>
            </div>
          </div>
        </div>
      </div>


    </div>


  </FmedFenetreModale>

</template>

<script>


import Api from '../../api';
import {FmedFenetreModale, FmedBouton, FmedChampSaisie, FmedTitre, FmedMessage} from '@fmedul/panier-vue'
import {courrielValide} from "../../../utilitaire";
import messages from "../../messages";

const api = Api.getInstance();

export default {
  components: {FmedFenetreModale, FmedBouton, FmedChampSaisie, FmedTitre, FmedMessage},
  data: function () {
    return {
      erreurServeur: null,
      organisme: null,

      afficherErreurs: false,

      chargementBoutonSauvegarde: false,
    }
  },

  props: {
    afficher: {
      required: true
    }
  },

  watch: {
    afficher(nouvelleValeur) {
      if (nouvelleValeur === true) {
        this.organisme = {}
      }
    }
  },


  methods: {


    messageErreur(prop) {
      if (this.errors[prop]) {
        return this.errors[prop][0];
      }

      return null;
    },


    addError(errors, key, value) {
      if (!errors[key]) {
        errors[key] = [];
      }

      errors[key].push(value);
    },


    async save() {
      if (this.hasErrors) {
        this.afficherErreurs = true;
        return;
      }

      this.erreurServeur = null;

      this.chargementBoutonSauvegarde = true;
      try {
        await api.creerOrganisme(this.organisme);
        this.chargementBoutonSauvegarde = false;

        this.$notify({
          group: 'alerts',
          title: 'Succès',
          text: 'Organisme créé avec succès',
          duration: 10000
        });

        this.$emit('sauvegarder', this.organisme);
      }
      catch (err){
        this.erreurServeur = messages.message(err.erreur);
      }
      finally {
        this.chargementBoutonSauvegarde = false;
      }
    }


  },


  computed: {

    errors() {

      var errors = {};

      if (!this.organisme.nom || this.organisme.nom.length === 0) {
        this.addError(errors, 'nom', 'Le nom de l\'organisme ne doit pas être vide');
      }
      if (this.organisme.adresseCourriel && !courrielValide(this.organisme.adresseCourriel)) {
        this.addError(errors, 'adresseCourriel', "L'adresse courriel n'est pas valide");
      }


      return errors;
    },

    hasErrors() {
      return Object.keys(this.errors).length > 0 && this.errors.constructor === Object;
    }

  },


}


</script>

<style>

#rte {
  height: 200px;
}

</style>


