import Vuex from 'vuex'
import Vue from 'vue'


Vue.use(Vuex);


export default new Vuex.Store({


    state : {
        loggedIn : false
    },

    mutations : {
        setLoggedIn(state, val){
            state.loggedIn = val;
        }
    }





});