<template>

  <div>
    <div class="container">
      <FmedTitre :niveau="1" class="mt-2">Gestion des demandes d'attestation</FmedTitre>
    </div>


    <div class="strip strip-secondary mt-1">
      <div class="container mt2">

        <div class="row">
          <div class="col-sm-4">
            <FmedChampSaisie v-model="keywords" placeholder="Par mots-clés"></FmedChampSaisie>
          </div>
          <div class="col-sm-8 col-md-4">
            <FmedListeDeroulante :options="[
                            {value : '', text : 'Toutes les demandes'},
                            {value : 0, text : 'Demandes non traitées'},
                            {value : 1, text : 'Attestations émises'},
                        ]"
                                 v-model="isIssued"
                                 cle-objet="value"
                                 cle-libelle="text"
                                 :permettre-vide="false">
            </FmedListeDeroulante>
          </div>
        </div>
        <div class="row mt2 align-center">
          <div class="col-sm-2"><span class="text-muted">Période des demandes :</span></div>
          <div class="col-sm-10">
            <div class="flex" style="align-items: flex-start">
              <div class="align-center">
                <span class="text-muted">de</span>
                <FmedChampDate v-model="startDate" class="ml1"></FmedChampDate>
              </div>
              <div class="align-center ">
                <span class="text-muted">à</span>
                <FmedChampDate v-model="endDate" class="ml1"></FmedChampDate>
              </div>
            </div>


          </div>
        </div>
        <div class="row mt2">
          <div class="col-sm-12">
            <label>
              <input type="checkbox" v-model="inReview">
              En résivion
            </label>
          </div>
        </div>

        <div class="pull-right">
          <FmedBouton @click="launchSearch">Chercher</FmedBouton>
        </div>


      </div>
    </div>

    <LoadingOverlay class="text-center mt1" :active.sync="isLoading"></LoadingOverlay>

    <div class="container mt-2" v-if="!isLoading">
      <Datatable :columns="columns" :data="donneesTableau" :per-page="15">

        <template slot-scope="{data}">
          <tr v-for="item in data" :key="item.id">
            <td>{{ item.id }}</td>
            <td>{{ item.datePaiement | shortDate }}</td>
            <td><a @click="openDemandeAttestationModal(item.id)">{{ item.nom }}, {{ item.prenom }}</a>
            </td>
            <td>{{ item.formation.nom }}</td>

            <td>{{ item.dateFormation | shortDate }}</td>
            <td style="text-align: center">
              <v-popover v-if="item.dateEmission !== null" trigger="hover">
                <i class="icon icon-check" style="color: #72994d"></i>


                <template slot="popover">
                  Attestation émise
                </template>
              </v-popover>

              <v-popover v-if="item.enRevision" trigger="hover">
                <i class="icon icon-flag" style="color: #f28d00"></i>

                <template slot="popover">Attestation en révision</template>
              </v-popover>

              <v-popover v-if="item.presenceLiee" trigger="hover">
                <i class="icon icon-info2" style="color:#1e94b8"></i>

                <template slot="popover">
                  La demande d'attestation est liée à une présence <span class="icon-link"
                                                                         @click="$router.push({ path: '/admin/evenements/' + item.presenceLiee.evenement.id, query : { presence_id : item.presenceLiee.id}})">Voir</span>
                </template>
              </v-popover>

              <v-popover v-if="item.presencesSemblables.length > 0" trigger="hover">
                <i class="icon icon-info" style="color:#1e94b8"></i>

                <template slot="popover">
                  Il existe des présences semblables à cet demande d'attestation
                </template>
              </v-popover>
            </td>
            <td style="text-align: center">
              <v-popover v-if="item.notes" trigger="hover">
                <i class="icon icon-file-text"></i>

                <template slot="popover" v-html="item.notes">
                  <div v-html="item.notes"></div>
                </template>
              </v-popover>
            </td>
            <td>
              <i class="icon icon-edit icon-link" style="cursor: pointer"
                 @click="openDemandeAttestationModal(item.id)"></i>
            </td>
          </tr>
        </template>


      </Datatable>
    </div>

    <FenetreModaleDemandeAttestation
        :demandeAttestationId="fenetreDialogueDemandeAttestationId"
        @sauvegarder="ondemandesAttestationsave"
        @delete="demandeAttestationDelete"
        @emettre="onDemandeAttestationIssued"
        @fermer="fermerFenetreDemandeAttestation"></FenetreModaleDemandeAttestation>

  </div>

</template>


<script>


import Api from '../../api';
import {FmedChampSaisie, FmedListeDeroulante, FmedChampDate, FmedTitre, FmedBouton} from '@fmedul/panier-vue'
import Datatable from '../../components/Datatable'
import LoadingOverlay from 'vue-loading-overlay'

import _ from 'lodash'

import moment from 'moment'

import FenetreModaleDemandeAttestation from '../../components/modals/FenetreModaleDemandeAttestation'

const api = Api.getInstance();

export default {

  components: {
    FmedChampSaisie,
    FmedListeDeroulante,
    FmedChampDate,
    FmedTitre,
    Datatable,
    LoadingOverlay,
    FenetreModaleDemandeAttestation,
    FmedBouton
  },


  data: function () {
    return {
      keywords: '',
      status: '',
      startDate: moment().subtract(1, 'years').toDate(),
      endDate: moment().toDate(),
      inReview: false,
      isIssued: {value: 0, text: 'Demandes non traitées'},

      itemsPerPage: 15,

      isLoading: true,

      columns: [
        {label: 'Réf.', field: 'id', sortable: true, width: '100px'},
        {
          label: 'Date paiement', field: 'datePaiement', sortFunction: function (a, b) {

            let first = moment.utc(a.dateFormation.date);
            let second = moment.utc(b.dateFormation.date);


            return (first.isBefore(second)) ? -1 : ((second.isBefore(first)) ? 1 : 0);

          }, sortable: true, width: '100px'
        },
        {
          label: 'Nom, Prénom', field: 'name', sortFunction: function (a, b) {
            let first = _.deburr(a.nom + ", " + a.prenom);
            let second = _.deburr(b.nom + ", " + b.prenom);

            return (first < second) ? -1 : (first > second) ? 1 : 0;

          }, sortable: true, 'width': '220px'
        },
        {label: 'Formation', field: 'nomFormation', sortable: true},

        {
          label: 'Date formation', field: 'dateFormation', sortFunction: function (a, b) {

            let first = moment.utc(a.dateFormation.date);
            let second = moment.utc(b.dateFormation.date);


            return (first.isBefore(second)) ? -1 : ((second.isBefore(first)) ? 1 : 0);

          }, sortable: true, width: '100px'
        },
        {
          label: 'Statut', field: 'status', width: '70px', sortFunction: function (a, b) {

            let first = 0;
            if (a.dateEmission !== null)
              first = 4;
            else if (a.presenceLiee !== null)
              first = 3;
            else if (a.presencesSemblables.length > 0)
              first = 2;
            else if (a.enRevision)
              first = 1;

            let second = 0;
            if (b.dateEmission !== null)
              second = 4;
            else if (b.presenceLiee !== null)
              second = 3;
            else if (b.presencesSemblables.length > 0)
              second = 2;
            else if (b.enRevision)
              second = 1;


            return (first < second) ? -1 : (first > second) ? 1 : 0;

          }, sortable: true
        },
        {
          label: 'Notes', sortable: true, width: '75px', sortFunction: function (a, b) {
            var first = a.notes.length;
            var second = b.notes.length;

            return (first < second) ? -1 : (first > second) ? 1 : 0;
          }
        },


        {label: '', field: 'issuedDate', sortable: true},
      ],

      demandesAttestations: [],
      presencesSemblables: [],

      donneesTableau: [],

      page: 1,
      per_page: 10,

      fenetreDialogueDemandeAttestationId: null,
    }

  },

  methods: {

    fermerFenetreDemandeAttestation() {
      this.fenetreDialogueDemandeAttestationId = null;
    },

    async launchSearch() {
      this.isLoading = true;
      this.demandesAttestations = await api.searchDemandesAttestations(this.keywords, this.formattedStartDate, this.formattedEndDate, this.isIssued.value, this.inReview);

      const demandesAttestationsIds = this.demandesAttestations.map(da => da.id);
      this.presencesSemblables = await api.obtenirPresencesSemblablesPlusieursDemandesAttestations(demandesAttestationsIds);

      this.donneesTableau = [];
      this.demandesAttestations.forEach(demande => {
        let entreeTableau = Object.assign({}, demande);
        entreeTableau.presencesSemblables = this.presencesSemblables.filter(presenceSemblable => presenceSemblable.demandeAttestationId === demande.id);
        this.donneesTableau.push(entreeTableau);

      })

      this.isLoading = false;
    },


    async openDemandeAttestationModal(demandeAttestationId) {
      this.fenetreDialogueDemandeAttestationId = demandeAttestationId;
    },

    truncateComments(text) {
      var clamp = '...';
      var node = document.createElement('div');
      node.innerHTML = text;
      var content = node.textContent;
      return content.length > 200 ? content.slice(0, 200) + clamp : content;
    },

    ondemandesAttestationsave() {
      this.$notify({
        group: 'alerts',
        title: 'Succès',
        text: 'La demande d\'attestation à été sauvegardé avec succès !',
        duration: 10000
      });
      this.fermerFenetreDemandeAttestation();
      this.launchSearch();
    },

    demandeAttestationDelete() {
      this.$notify({
        group: 'alerts',
        title: 'Succès',
        text: 'La demande d\'attestation à été supprimée avec succès !',
        duration: 10000
      });

      this.fermerFenetreDemandeAttestation();
      this.launchSearch();
    },

    onDemandeAttestationIssued() {
      this.$notify({
        group: 'alerts',
        title: 'Succès',
        text: 'L\'attestation a été envoyée avec succès !',
        duration: 10000
      });
      this.fermerFenetreDemandeAttestation();
      this.launchSearch();
    }
  },


  computed: {

    formattedStartDate() {
      return this.startDate ? moment.utc(this.startDate).format('YYYY-MM-DD') : '';
    },

    formattedEndDate() {
      return this.endDate ? moment.utc(this.endDate).format('YYYY-MM-DD') : '';
    }
  },

  created: function () {
    this.launchSearch();
    this.$forceUpdate();
  },


}


</script>

