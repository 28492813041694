<template>

    <FmedFenetreModale :afficher="(moduleId !== null || module !== null) && produitsDexero.length > 0">
        <div class="modal">
            <div class="form" v-if="moduleData">

                <FmedTitre :niveau="1" class="mb-1">Modifier un module</FmedTitre>
                <VueSwitch v-model="moduleData.actif" :type-bold="true" theme="uec" />
                <div class="form-group">
                    <FmedChampSaisie type="text" v-model="moduleData.nom" placeholder="Nom du module *"/>
                </div>

                <div class="form-group mt1">
                    <label>Nombre d'unités de formation continue *</label>
                    <FmedChampSaisie type="text" v-model="moduleData.nombreUfc" placeholder="Nom du module *"
                                     style="max-width: 100px"/>
                </div>


                <div class="form-group mt1">
                    <label>No. de produit Dex. *</label>
                    <FmedListeDeroulante v-model="moduleData.produitEcommerce" :options="produitsDexero" :fonction-libelle="p => p.id + ' (' + p.cout.toFixed(2) + ' $)'"
                                         placeholder="No. de produit Dex. *" style="max-width: 400px"/>
                </div>


                <div class="row mt1">
                    <div class="col-sm-12">
                        <div class="text-right btn-group btn-group" @mouseover="showErrors = true">
                            <div class="text-right btn-group btn-group" @mouseover="showErrors = true">
                                <FmedBouton couleur="encadre" @click="$emit('annuler');">Annuler</FmedBouton>
                                <FmedBouton @click="sauvegarder" :desactive="hasErrors">
                                    Enregistrer
                                </FmedBouton>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <LoadingOverlay v-else></LoadingOverlay>
        </div>


    </FmedFenetreModale>

</template>

<script>

    import Api from "../../api";
    import {FmedFenetreModale, FmedBouton, FmedChampSaisie, FmedTitre, FmedListeDeroulante} from '@fmedul/panier-vue'
    import LoadingOverlay from 'vue-loading-overlay'
    import VueSwitch from 'vue-switches';

    const api = Api.getInstance();


    export default {
        components: {FmedFenetreModale, LoadingOverlay, FmedBouton, FmedChampSaisie, FmedTitre, FmedListeDeroulante, VueSwitch},
        data: function () {
            return {
                moduleData: null,
                produitsDexero: [],

            }
        },

        props: {
            moduleId: {
                default: null,
            },
            module: {
                default: null,
            }
        },

        watch: {
            async moduleId(nouvelleValeur) {
                this.moduleData = await api.getModule(nouvelleValeur);
                await this.chargerProduitsDexero();

            },
            async module(nouvelleValeur) {
                this.moduleData = JSON.parse(JSON.stringify(nouvelleValeur));
                await this.chargerProduitsDexero();
            }
        },


        methods: {


            async chargerProduitsDexero() {
                this.produitsDexero = await api.obtenirTousProduitsDexero();
            },


            addError(errors, key, value) {
                if (!errors[key]) {
                    errors[key] = [];
                }

                errors[key].push(value);
            },


            sauvegarder() {
                if (this.hasErrors) {
                    return;
                }

                //ToDo : Si on a reçu le module par la prop 'moduleId', on va sauvegarder le module ici

                this.$emit('sauvegarder', this.moduleData);
            },


        },


        computed: {

            errors() {

                const errors = {};

                if (!this.moduleData.nom || this.module.nom.length === 0) {
                    this.addError(errors, 'nom', 'Le nom de l\'organisme ne doit pas être vide');
                }

                if (!this.moduleData.produitEcommerce) {
                    this.addError(errors, 'ecommerceId', 'Le no. de produit Dexero ne doit pas être vide');
                }

                if (!this.moduleData.nombreUfc || isNaN(this.moduleData.nombreUfc)) {
                    this.addError(errors, 'nombreUfc', 'Le nombre d\'UFC doit être un nombre');
                }


                return errors;
            },

            hasErrors() {
                return Object.keys(this.errors).length > 0 && this.errors.constructor === Object;
            }

        },


    }


</script>

<style>

    ul {
        list-style: none;
        padding: 0;
    }

    ul li {
        padding: 5px 0;
    }
</style>


