<template>

  <div>


    <div class="container">
      <FmedTitre :niveau="1" class="mt-2 mb-1">Remplir le formulaire d’évaluation de l'activité</FmedTitre>
      <p>
        Votre avis est essentiel à la qualité des formations et colloques accrédités! Veuillez prendre quelques minutes
        pour remplir le formulaire d’évaluation ci-dessous
        dans un objectif d’amélioration continue.
      </p>
    </div>


    <LoadingOverlay class="text-center mt1" :active.sync="isLoading"></LoadingOverlay>

    <div class="container form" v-if="!isLoading">

      <FmedMessage type="succes" class="mt-1" v-if="submitted && !showErrors">
        Merci! Vous avez soumis le formulaire d'évaluation avec succès.
      </FmedMessage>

      <FmedMessage type="erreur" class="mt-1" v-if="hasErrors && showErrors">
        Il y a des erreurs dans le formulaire. Veuillez et corriger avant de soumettre votre évaluation.
      </FmedMessage>

      <h3 class="mt-3 mb-1">Activité de formation</h3>

      <div class="form-group">
        <FmedListeDeroulante v-model="selectedFormation" :options="formationsOrdreAlpha" cle-libelle="nom"
                             placeholder="Saisir ou sélectionner une activité"
                             @input="formationChanged"
                             style="max-width: 500px">
          <span slot="aucuneOption">Désolé, aucune formation n'a été trouvée</span>
        </FmedListeDeroulante>
        <div class="error" v-if="errors.formation && showErrors"><span>{{ errors.formation[0] }}</span></div>
      </div>


      <div class="form-group">
        <FmedListeDeroulante :options="selectedFormation ? organismes : []"
                             v-model="selectedOrganisme" cle-libelle="nom"
                             placeholder="Sélectionner un organisme"
                             style="max-width: 500px">
          <span slot="aucuneOption">Sélectionner une formation préalablement</span>
        </FmedListeDeroulante>
        <div class="error" v-if="errors.organisme && showErrors"><span>{{ errors.organisme[0] }}</span></div>
      </div>


      <div class="form-group">
        <label>Date de l'activité</label>
        <FmedChampDate v-model="selectedDate"/>
        <div class="error" v-if="errors.date && showErrors"><span>{{ errors.date[0] }}</span></div>
      </div>

      <div class="form-group">
        <label>Nom de la formatrice ou du formateur</label>
        <FmedChampSaisie type="text" v-model="nomFormateur" style="max-width: 360px"/>
        <div class="error" v-if="errors.formateur && showErrors"><span>{{ errors.formateur[0] }}</span></div>
      </div>


      <h3 class="mt-3 mb-1">Formulaire d’évaluation</h3>

      <p class="info-formulaire-evaluation">
        Veuillez répondre sur une échelle allant de <strong>4 « complètement d’accord » à 1 « pas du tout d’accord
        ».</strong> Si
        l’énoncé ne s’applique pas à votre situation, veuillez cocher SO (sans objet).
      </p>

      <table class="table table-striped">
        <tr>
          <th>L'ACTIVITÉ</th>
          <th style="width: 20px;">4</th>
          <th style="width: 20px;">3</th>
          <th style="width: 20px;">2</th>
          <th style="width: 20px;">1</th>
          <th style="width: 20px;">SO</th>
        </tr>
        <tr v-for="(question, key) in questions.formation" :key="key">
          <td>
            {{ question.text }}
            <span v-if="errors['question_formation_' + key] && showErrors"
                  style="color:red">{{ errors['question_formation_' + key][0] }}</span>
          </td>
          <td>
            <FmedBoutonRadio :groupe="key" :valeur="4" v-model="question.reponse"/>
          </td>
          <td>
            <FmedBoutonRadio :groupe="key" :valeur="3" v-model="question.reponse"/>
          </td>
          <td>
            <FmedBoutonRadio :groupe="key" :valeur="2" v-model="question.reponse"/>
          </td>
          <td>
            <FmedBoutonRadio :groupe="key" :valeur="1" v-model="question.reponse"/>
          </td>
          <td>
            <FmedBoutonRadio :groupe="key" :valeur="null" v-model="question.reponse"/>
          </td>
        </tr>
      </table>


      <table class="table table-striped">
        <tr>
          <th>LA MÉTHODOLOGIE</th>
          <th style="width: 20px;">4</th>
          <th style="width: 20px;">3</th>
          <th style="width: 20px;">2</th>
          <th style="width: 20px;">1</th>
          <th style="width: 20px;">SO</th>
        </tr>
        <tr v-for="(question, key) in questions.methodologie" :key="key">
          <td>
            {{ question.text }}
            <span v-if="errors['question_methodologie_' + key] && showErrors"
                  style="color:red">{{ errors['question_methodologie_' + key][0] }}</span>
          </td>
          <td>
            <FmedBoutonRadio :groupe="key" :valeur="4" v-model="question.reponse"/>
          </td>
          <td>
            <FmedBoutonRadio :groupe="key" :valeur="3" v-model="question.reponse"/>
          </td>
          <td>
            <FmedBoutonRadio :groupe="key" :valeur="2" v-model="question.reponse"/>
          </td>
          <td>
            <FmedBoutonRadio :groupe="key" :valeur="1" v-model="question.reponse"/>
          </td>
          <td>
            <FmedBoutonRadio :groupe="key" :valeur="null" v-model="question.reponse"/>
          </td>
        </tr>
      </table>

      <table class="table table-striped">
        <tr>
          <th>ÉVALUATION GLOBALE</th>
          <th style="width: 20px;">4</th>
          <th style="width: 20px;">3</th>
          <th style="width: 20px;">2</th>
          <th style="width: 20px;">1</th>
          <th style="width: 20px;">SO</th>
        </tr>
        <tr v-for="(question, key) in questions.evaluationGlobale" :key="key">
          <td>
            {{ question.text }}
            <span v-if="errors['question_evaluationGlobale_' + key] && showErrors"
                  style="color:red">{{ errors['question_evaluationGlobale_' + key][0] }}</span>
          </td>
          <td>
            <FmedBoutonRadio :groupe="key" :valeur="4" v-model="question.reponse"/>
          </td>
          <td>
            <FmedBoutonRadio :groupe="key" :valeur="3" v-model="question.reponse"/>
          </td>
          <td>
            <FmedBoutonRadio :groupe="key" :valeur="2" v-model="question.reponse"/>
          </td>
          <td>
            <FmedBoutonRadio :groupe="key" :valeur="1" v-model="question.reponse"/>
          </td>
          <td>
            <FmedBoutonRadio :groupe="key" :valeur="null" v-model="question.reponse"/>
          </td>
        </tr>
      </table>


      <div class="mt2">
        <span>Comment avez-vous entendu parler de cette formation?</span>
        <span v-if="errors.reference && showErrors" style="color:red">{{ errors.reference[0] }}</span>

        <div v-for="(reference, key) in references" :key="key">
          <div>
            <FmedBoutonRadio groupe="reference" :valeur="key" v-model="selectedReference"
                             :libelle="reference.text"/>

            <template v-if="reference.requireDetails && key === selectedReference">
              <FmedChampSaisie v-model="referenceDetails" placeholder="Veuillez spécifier"/>
              <div class="error" v-if="errors.referenceDetails && showErrors"><span>{{
                  errors.referenceDetails[0]
                }}</span>
              </div>
            </template>

          </div>
        </div>
      </div>

      <div class="mt2">
        <span>Quels sont les motifs qui vous ont incité à vous inscrire à cette activité de formation continue ?</span>
        <span v-if="errors.motif && showErrors" style="color:red">{{ errors.motif[0] }}</span>

        <div v-for="(motif, key) in motifs" :key="key">
          <div>
            <FmedBoutonRadio groupe="motif" :valeur="key" v-model="selectedMotif" :libelle="motif.text"/>

            <template v-if="motif.requireDetails && key === selectedMotif">
              <FmedChampSaisie type="text" v-model="motifsDetails" placeholder="Veuillez spécifier"/>
              <div class="error" v-if="errors.motifDetails && showErrors">
                <span>{{ errors.motifDetails[0] }}</span></div>
            </template>

          </div>
        </div>
      </div>

      <h3 class="mt-3 mb-1">À propos de vous</h3>
      <div class="mt2">
        <FmedListeDeroulante v-model="selectedRegion" placeholder="Région géographique de travail"
                             :options="regions" cle-libelle="text"/>
        <div class="error" v-if="errors.region && showErrors"><span>{{ errors.region[0] }}</span></div>
      </div>

      <div class="mt2">
        <FmedListeDeroulante v-model="selectedDiplome" placeholder="Dernier diplôme obtenu" :options="diplomes"
                             cle-libelle="text"/>
        <div class="error" v-if="errors.diplome && showErrors"><span>{{ errors.diplome[0] }}</span></div>
      </div>

      <div class="mt2">
        <FmedListeDeroulante v-model="selectedMilieuTravail" placeholder="Milieu de travail"
                             :options="milieuxTravail" cle-libelle="text"/>
        <div class="error" v-if="errors.milieuTravail && showErrors"><span>{{ errors.milieuTravail[0] }}</span>
        </div>

        <div class="mt1" v-if="selectedMilieuTravail && selectedMilieuTravail.id === 'autre'">
          Précision du milieu de travail :
          <FmedChampSaisie v-model="milieuTravailDetails"/>
          <div class="error" v-if="errors.milieuTravailDetails && showErrors"><span>{{
              errors.milieuTravailDetails[0]
            }}</span>
          </div>
        </div>

      </div>

      <div class="mt2">
        <FmedListeDeroulante v-model="selectedProfession" placeholder="Profession ou titre d'emploi"
                             :options="professions" cle-libelle="text"/>
        <div class="error" v-if="errors.profession && showErrors"><span>{{ errors.profession[0] }}</span></div>
        <div class="mt1" v-if="selectedProfession && selectedProfession.id === 'autre'">
          Précision de la profession :
          <FmedChampSaisie type="text" v-model="professionDetails"/>
          <div class="error" v-if="errors.professionDetails && showErrors"><span>{{
              errors.professionDetails[0]
            }}</span>
          </div>
        </div>
      </div>


      <div class="mt2">
        <FmedListeDeroulante v-model="selectedAnneeExperience"
                             placeholder="Nombre d'année(s) d'expérience de travail" :options="anneesExperience"
                             cle-libelle="text"/>
        <div class="error" v-if="errors.anneeExperience && showErrors">
          <span>{{ errors.anneeExperience[0] }}</span></div>
      </div>


      <div class="mt2">
        <FmedListeDeroulante v-model="selectedGroupeAge" :options="groupesAges" placeholder="Groupe d'âge"
                             cle-libelle="text"/>
        <div class="error" v-if="errors.groupeAge && showErrors"><span>{{ errors.groupeAge[0] }}</span></div>
      </div>

      <FmedTitre :niveau="3" class="mt-5">Votre opinion est importante pour nous!</FmedTitre>
      <p>Les réponses à ces questions sont facultatives.</p>

      <div class="mt2">
        <span>Les points forts de l’activité</span>
        <div>
          <FmedZoneTexte v-model="opinionPointsForts" style="height : 135px;max-width:800px"></FmedZoneTexte>
        </div>
      </div>
      <div class="mt2">
        <span>Les points de l’activité à améliorer</span>
        <div>
          <FmedZoneTexte v-model="opinionAAmeliorer" style="height : 135px;max-width:800px"></FmedZoneTexte>
        </div>
      </div>
      <div class="mt2">
        <span>Les informations manquantes à inclure </span>
        <div>
          <FmedZoneTexte v-model="opinionInfosManquantes" style="height : 135px;max-width:800px"></FmedZoneTexte>
        </div>
      </div>
      <div class="mt2">
        <span>À qui recommanderiez-vous cette activité? </span>
        <div>
          <FmedZoneTexte v-model="opinionRecommandeActivite"
                         style="height : 135px;max-width:800px"></FmedZoneTexte>
        </div>
      </div>
      <div class="mt2">
        <span>Quelles sont les activités de formation que vous aimeriez voir offertes? </span>
        <div>
          <FmedZoneTexte v-model="opinionAutreFormation" style="height : 135px;max-width:800px"></FmedZoneTexte>
        </div>
      </div>
      <div class="mt2">
        <span>Quels sont les thèmes que vous aimeriez voir traités dans un colloque? </span>
        <div>
          <FmedZoneTexte v-model="opinionThemesColloque" style="height : 135px;max-width:800px"></FmedZoneTexte>
        </div>
      </div>
      <div class="mt2">
        <span>Commentaires</span>
        <div>
          <FmedZoneTexte v-model="opinionCommentaires" style="height : 135px;max-width:800px"></FmedZoneTexte>
        </div>
      </div>

      <div class="text-right mt-4 mb-1">
        <FmedBouton @click="soumettre" :chargement="sending">Soumettre</FmedBouton>
      </div>

    </div>


  </div>
</template>


<script>

import Api from "../api";
import moment from 'moment';
import LoadingOverlay from 'vue-loading-overlay';
import {
  FmedTitre,
  FmedChampDate,
  FmedListeDeroulante,
  FmedBouton,
  FmedMessage,
  FmedZoneTexte,
  FmedBoutonRadio,
  FmedChampSaisie
} from '@fmedul/panier-vue'

const api = Api.getInstance();

export default {

  name: 'evaluation',
  components: {
    FmedTitre,
    FmedChampDate,
    FmedListeDeroulante,
    FmedBouton,
    FmedMessage,
    LoadingOverlay,
    FmedZoneTexte,
    FmedBoutonRadio,
    FmedChampSaisie
  },
  data: function () {

    return {
      formations: [],
      organismes: [],
      questions: {},
      references: {},
      motifs: {},
      regions: [],
      diplomes: [],
      milieuxTravail: [],
      professions: [],
      anneesExperience: [],
      groupesAges: [],


      selectedFormation: null,
      selectedOrganisme: null,
      selectedDate: null,
      nomFormateur: '',
      selectedReference: null,
      referenceDetails: '',
      selectedMotif: null,
      motifsDetails: '',
      selectedRegion: null,
      selectedDiplome: null,
      selectedMilieuTravail: null,
      milieuTravailDetails: '',
      selectedProfession: null,
      professionDetails: '',
      selectedAnneeExperience: null,
      selectedGroupeAge: null,

      opinionPointsForts: '',
      opinionAAmeliorer: '',
      opinionInfosManquantes: '',
      opinionRecommandeActivite: '',
      opinionAutreFormation: '',
      opinionThemesColloque: '',
      opinionCommentaires: '',

      isLoading: true,
      sending: false,
      showErrors: false,
      submitted: false
    }
  },


  watch: {
    selectedFormation: async function () {
      this.updateUrl();
    },
    selectedOrganisme: function () {
      this.updateUrl();
    },

    selectedDate: function () {
      this.updateUrl();
    },

    nomFormateur: function () {
      this.updateUrl();
    },



  },

  methods: {


    soumettre: async function () {

      if (this.hasErrors) {
        this.showErrors = true;
        window.scrollTo(0, 0);
        return;
      }

      this.sending = true;
      await api.envoyerEvaluation(
          this.selectedFormation.id,
          this.selectedOrganisme.id,
          this.nomFormateur,
          moment.utc(this.selectedDate).format('YYYY-MM-DD'),
          this.questions.formation.objectifsClairs.reponse,
          this.questions.formation.objectifsAtteints.reponse,
          this.questions.formation.contenuAdapte.reponse,
          this.questions.formation.appliquerContenuTravail.reponse,
          this.questions.formation.motiverAppliquerApprentissages.reponse,
          this.questions.formation.enseignementFaciliteApprentissage.reponse,
          this.questions.formation.formateurEcouteParticipant.reponse,
          this.questions.methodologie.equilibreExercicesApproprie.reponse,
          this.questions.methodologie.themesSuccedeFaconAppropriee.reponse,
          this.questions.methodologie.cahierInfosUtiles.reponse,
          this.questions.methodologie.materielAdequat.reponse,
          this.questions.evaluationGlobale.dureeSessionAppropriee.reponse,
          this.questions.evaluationGlobale.horairePrevuRespecte.reponse,
          this.questions.evaluationGlobale.satisfaitFormation.reponse,
          this.questions.evaluationGlobale.satisfaitAccueil.reponse,
          this.questions.evaluationGlobale.nbParticipantsAdapte.reponse,
          this.questions.evaluationGlobale.localAdequat.reponse,
          this.selectedReference,
          this.referenceDetails,
          this.selectedMotif,
          this.motifsDetails,
          this.selectedRegion.code,
          this.selectedDiplome.id,
          this.selectedMilieuTravail.id,
          this.milieuTravailDetails,
          this.selectedProfession.id,
          this.professionDetails,
          this.selectedAnneeExperience.id,
          this.selectedGroupeAge.id,
          this.opinionPointsForts,
          this.opinionAAmeliorer,
          this.opinionInfosManquantes,
          this.opinionRecommandeActivite,
          this.opinionAutreFormation,
          this.opinionThemesColloque,
          this.opinionCommentaires
      );
      this.sending = false;


      this.submitted = true;


      this.resetForm();
      window.scrollTo(0, 0);
    },

    addError(errors, key, value) {
      if (!errors[key]) {
        errors[key] = [];
      }

      errors[key].push(value);
    },

    formationChanged: async function () {

      this.selectedOrganisme = null;
      this.organismes = await api.getOrganismesFromFormation(this.selectedFormation.id);

      this.updateUrl();


    },


    updateUrl(){
      let params = new URLSearchParams(window.location.search);

      if(this.selectedFormation){
        params.set('formationId', this.selectedFormation.id);
      }
      else {
        params.delete('formationId');
      }

      if(this.selectedOrganisme){
        params.set('organismeId', this.selectedOrganisme.id);
      }
      else {
        params.delete('organismeId');
      }

      if(this.selectedDate){
        params.set('date', moment(this.selectedDate).utc().format('YYYY-MM-DD'));
      }
      else {
        params.delete('date');
      }

      if(this.nomFormateur){
        params.set('nomFormateur', this.nomFormateur);
      }
      else {
        params.delete('nomFormateur');
      }

      window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
    },




    resetForm() {

      this.selectedFormation = null;
      this.selectedOrganisme = null;
      this.selectedDate = null;
      this.nomFormateur = '';
      this.selectedReference = null;
      this.referenceDetails = '';
      this.selectedMotif = null;
      this.motifsDetails = '';
      this.selectedRegion = null;
      this.selectedDiplome = null;
      this.selectedMilieuTravail = null;
      this.milieuTravailDetails = '';
      this.selectedProfession = null;
      this.professionDetails = '';
      this.selectedAnneeExperience = null;
      this.selectedGroupeAge = null;
      this.opinionPointsForts = '';
      this.opinionAAmeliorer = '';
      this.opinionInfosManquantes = '';
      this.opinionRecommandeActivite = '';
      this.opinionAutreFormation = '';
      this.opinionThemesColloque = '';
      this.opinionCommentaires = '';
      this.showErrors = false;

      Object.keys(this.questions).forEach(group => {
        Object.keys(this.questions[group]).forEach(question => {
          this.questions[group][question].reponse = undefined;
        })
      })
    }


  }
  ,

  computed: {

    formationsOrdreAlpha() {
      return this.formations.slice().sort((a, b) => a.nom.localeCompare(b.nom));
    },

    errors() {

      let errors = {};

      if (!this.selectedFormation) {
        this.addError(errors, 'formation', 'Veuillez choisir une formation');
      }
      if (!this.selectedOrganisme) {
        this.addError(errors, 'organisme', 'Veuillez choisir un organisme');
      }
      if (!this.selectedDate) {
        this.addError(errors, 'date', 'Veuillez choisir une date de formation');
      }
      if (this.nomFormateur.trim().length === 0) {
        this.addError(errors, 'formateur', 'Veuillez saisir le nom du formateur');
      }

      Object.keys(this.questions).forEach(group => {
        Object.keys(this.questions[group]).forEach(question => {
          if (this.questions[group][question].reponse === undefined) {
            this.addError(errors, 'question_' + group + '_' + question, 'Veuillez faire une selection');
          }
        });
      });

      if (!this.selectedReference) {
        this.addError(errors, 'reference', 'Veuillez choisir une référence');
      }
      if (this.selectedReference && this.references[this.selectedReference].requireDetails && this.referenceDetails.trim().length === 0) {
        this.addError(errors, 'referenceDetails', 'Veuillez préciser votre référence dans le champ texte');
      }

      if (!this.selectedMotif) {
        this.addError(errors, 'motif', 'Veuillez choisir un motif');
      }
      if (this.selectedMotif && this.motifs[this.selectedMotif].requireDetails && this.motifsDetails.trim().length === 0) {
        this.addError(errors, 'motifDetails', 'Veuillez préciser votre motif dans le champ texte');
      }

      if (!this.selectedRegion) {
        this.addError(errors, 'region', 'Veuillez choisir votre région');
      }

      if (!this.selectedDiplome) {
        this.addError(errors, 'diplome', 'Veuillez choisir votre dernier diplôme obtenu');
      }

      if (!this.selectedMilieuTravail) {
        this.addError(errors, 'milieuTravail', 'Veuillez choisir votre milieu de travail');
      }
      if (this.selectedMilieuTravail && this.selectedMilieuTravail.id === 'autre' && this.milieuTravailDetails.trim().length === 0) {
        this.addError(errors, 'milieuTravailDetails', 'Veuillez préciser votre milieu de travail');
      }

      if (!this.selectedProfession) {
        this.addError(errors, 'profession', 'Veuillez choisir votre profession');
      }
      if (this.selectedProfession && this.selectedProfession.id === 'autre' && this.professionDetails.trim().length === 0) {
        this.addError(errors, 'professionDetails', 'Veuillez préciser votre profession');
      }

      if (!this.selectedAnneeExperience) {
        this.addError(errors, 'anneeExperience', 'Veuillez choisir votre nombre d\'années d\'expérience');
      }

      if (!this.selectedGroupeAge) {
        this.addError(errors, 'groupeAge', 'Veuillez choisir votre groupe d\'âge');
      }


      return errors;
    },

    hasErrors() {
      return Object.keys(this.errors).length > 0 && this.errors.constructor === Object;
    },

    allFieldSelected: function () {
      return this.selectedOrganisme != null && this.selectedFormation != null && this.selectedDate != null && this.selectedModule;
    }
  },

  created: async function () {
    this.formations = await api.getAllFormations();


    this.questions = {
      formation: {
        objectifsClairs: {
          text: "Les contenus étaient clairs et précis",
          reponse: undefined
        },
        objectifsAtteints: {
          text: "Les objectifs ont été atteints",
          reponse: undefined
        },
        contenuAdapte: {
          text: "Le contenu était adapté à mes besoins ",
          reponse: undefined
        },
        appliquerContenuTravail: {
          text: "Je peux appliquer directement le contenu à mon travail ",
          reponse: undefined
        },
        motiverAppliquerApprentissages: {
          text: "À la suite de cette activité, j’ai une grande motivation à appliquer mes apprentissages dans le cadre de mon travail",
          reponse: undefined
        },
        enseignementFaciliteApprentissage: {
          text: "Les méthodes d’enseignement (exercices et activités) ont facilité mon apprentissage",
          reponse: undefined
        },
        formateurEcouteParticipant: {
          text: "La formatrice ou le formateur était à l’écoute de tous les participants ",
          reponse: undefined
        }
      },

      methodologie: {
        equilibreExercicesApproprie: {
          text: "L’équilibre entre les exposés, les discussions et les exercices était approprié",
          reponse: undefined
        },
        themesSuccedeFaconAppropriee: {
          text: "Les thèmes traités se succédaient d’une façon appropriée",
          reponse: undefined
        },
        cahierInfosUtiles: {
          text: "Le cahier du participant, s'il y a lieu, contenait toutes les informations utiles ",
          reponse: undefined
        },
        materielAdequat: {
          text: "Le matériel didactique était adéquat (audiovisuel, affiches, etc.) ",
          reponse: undefined
        }
      },

      evaluationGlobale: {
        dureeSessionAppropriee: {
          text: "La durée était appropriée au contenu présenté ",
          reponse: undefined
        },
        horairePrevuRespecte: {
          text: " L’horaire prévu a été respecté",
          reponse: undefined
        },
        satisfaitFormation: {
          text: "Je suis satisfaite ou satisfait de cette activité",
          reponse: undefined
        },
        satisfaitAccueil: {
          text: "Je suis satisfaite ou satisfait de l’accueil au moment de mon arrivée",
          reponse: undefined
        },
        nbParticipantsAdapte: {
          text: "Le nombre de personnes participantes est adapté à l'activité",
          reponse: undefined
        },
        localAdequat: {
          text: "Les locaux étaient adéquats",
          reponse: undefined
        }
      }
    };


    this.references = {
      "publicite_faite_par_organisation": {
        text: "Publicité faite par l'organisation",
        requireDetails: true,
      },
      "demande_de_employeur": {
        text: "Demande de l’employeur ",
      },
      "suggestion_collegue": {
        text: "Suggestion d'une ou d’un collègue",
      },
      "reseaux_sociaux": {
        text: "Réseaux sociaux",
      },
      "ordre_professionnel": {
        text: "Ordre professionnel",
      },
      "autre": {
        text: "Autre",
        requireDetails: true,
      }
    };

    this.motifs = {
      "besoin_professionnel_immediat": {
        text: "Besoin professionnel immédiat ",
        requireDetails: false,
      },
      "interet_personnel_pour_sujet": {
        text: " Intérêt personnel pour le sujet",
        requireDetails: false,
      },
      "gout_apprendre": {
        text: "Goût d’apprendre",
        requireDetails: false,
      },
      "autre": {
        text: "Autre",
        requireDetails: true,
      }
    },

        this.regions = [
          {text: "Bas-Saint-Laurent", code: "01"},
          {text: "Saguenay-Lac-Saint-Jean", code: "02"},
          {text: "Capitale nationale", code: "03"},
          {text: "Mauricie", code: "04"},
          {text: "Estrie", code: "05"},
          {text: "Montréal", code: "06"},
          {text: "Outaouais", code: "07"},
          {text: "Abitibi-Témiscamingue", code: "08"},
          {text: "Côte-Nord", code: "09"},
          {text: "Nord-du-Québec", code: "10"},
          {text: "Gaspésie-Îles-de-la-Madeleine", code: "11"},
          {text: "Chaudière-Appalaches", code: "12"},
          {text: "Laval", code: "13"},
          {text: "Lanaudière", code: "14"},
          {text: "Laurentides", code: "15"},
          {text: "Montérégie", code: "16"},
          {text: "Centre-du-Québec", code: "17"},
        ];

    this.diplomes = [
      {id: "secondaire", text: "Secondaire"},
      {id: "dec", text: "DEC"},
      {id: "certificat", text: "Certificat"},
      {id: "baccalaureat", text: "Baccalauréat"},
      {id: "maitrise", text: "Maîtrise"},
      {id: "doctorat", text: "Doctorat"},
    ];

    this.milieuxTravail = [
      {id: 'centre_jour', text: "Centre de jour"},
      {id: 'centre_readaptation', text: "Centre de réadaptation"},
      {id: 'centre_hospitalier', text: "Centre hospitalier"},
      {id: 'ch_soins_psychiatriques', text: "CH de soins psychiatriques"},
      {id: 'chsld', text: "CHSLD"},
      {id: 'hopital_jour', text: "Hôpital de jour"},
      {id: 'clsc', text: "CLSC"},
      {id: 'residence_privee', text: "Résidence privée"},
      {id: 'cisss_et_ou_agence', text: "CISSS et/ou Agence"},
      {id: 'etablissement_enseignement', text: "Établissement d’enseignement"},
      {id: 'autre', text: "Autre"},
    ];

    this.professions = [
      {id: 'educateur', text: "Éducateur"},
      {id: 'enseignant', text: "Enseignant"},
      {id: 'ergotherapeute', text: "Ergothérapeute"},
      {id: 'gestionnaire', text: "Gestionnaire"},
      {id: 'gestionnaire_de_cas', text: "Gestionnaire de cas"},
      {id: 'infirmiere', text: "Infirmière"},
      {id: 'infirmiere_auxiliere', text: "Infirmière auxiliaire"},
      {id: 'inhalotherapeute', text: "Inhalothérapeute"},
      {id: 'medecin', text: "Médecin"},
      {id: 'nutritionniste', text: "Nutritionniste"},
      {id: 'pharmacien', text: "Pharmacien"},
      {id: 'physiotherapeute', text: "Physiothérapeute"},
      {id: 'prepose_aux_beneficiaires', text: "Préposé aux bénéficiaires"},
      {id: 'psychologue', text: "Psychologue"},
      {id: 'travailleur_social', text: "Travailleur social"},
      {id: 'personne_ressource', text: "Personne-ressource/Conseillère"},
      {id: 'autre', text: "Autre"}
    ];

    this.anneesExperience = [
      {id: '0_5', text: "0 à 5 ans"},
      {id: '6_10', text: "6 à 10 ans"},
      {id: '11_15', text: "11 à 15 ans"},
      {id: '16_20', text: "16 à 20 ans"},
      {id: '21_plus', text: "21 ans et plus"}
    ];

    this.groupesAges = [
      {id: '25_moins', text: "25 ans et moins"},
      {id: '26_49', text: "26 à 49 ans"},
      {id: '50_plus', text: "50 ans et plus"}
    ];


    this.isLoading = false;


    this.selectedFormation = await this.formations.find(formation => formation.id === this.$route.query.formationId) || null;
    this.selectedDate = this.$route.query.date ? moment(this.$route.query.date, 'YYYY-MM-DD').toDate() : null;
    this.nomFormateur = this.$route.query.nomFormateur ? this.$route.query.nomFormateur : '';


    if(this.selectedFormation){
      this.organismes = await api.getOrganismesFromFormation(this.selectedFormation.id);

      if(this.$route.query.organismeId)
        this.selectedOrganisme = this.organismes.find(organisme => organisme.id === this.$route.query.organismeId) || null;
    }






  }


}
</script>

<style lang="scss">


.fmed-radio {
  label {
    color: #2f3333;
  }
}

.info-formulaire-evaluation {
  font-size: 1.1em;

  strong {
    font-weight: bold;
  }
}

</style>