<template>


  <div class="container" style="max-width: 525px">

    <div class="mt-1">
      <FmedLien icone="icon-arrow-back" destination="/">Revenir à la page précédente</FmedLien>
    </div>


    <FmedTitre :niveau="1" class="mt-2">Signaler un problème</FmedTitre>

    <FmedMessage class="mt-1" type="succes" titre="Succès" v-if="afficherSucces">Votre message a été soumis avec
      succès.
    </FmedMessage>

    <FmedChampSaisie v-model="formulaire.prenom" libelle="Prénom" placeholder="Votre prénom" class="mt-2"
                     :message-erreur="erreurs.prenom[0]" :erreur="afficherErreurs && erreurs.prenom.length > 0"/>
    <FmedChampSaisie v-model="formulaire.nom" libelle="Nom" placeholder="Votre nom" :message-erreur="erreurs.nom[0]"
                     :erreur="afficherErreurs && erreurs.nom.length > 0"/>
    <FmedChampSaisie v-model="formulaire.courriel" libelle="Courriel" placeholder="Votre courriel"
                     :message-erreur="erreurs.courriel[0]"
                     :erreur="afficherErreurs && erreurs.courriel.length > 0"/>
    <FmedZoneTexte v-model="formulaire.message" libelle="Message" placeholder="Votre message"
                   :message-erreur="erreurs.message[0]" :erreur="afficherErreurs && erreurs.message.length > 0"/>

    <div class="mt-1 text-right">
      <FmedBouton couleur="encadre" @click="$router.go(-1)">Annuler
      </FmedBouton>
      <FmedBouton :chargement="enregistrement" @click="soumettre"
                  :desactive="nombreErreurs > 0 && afficherErreurs">Soumettre
      </FmedBouton>
    </div>


  </div>


</template>


<script>
import Api from "../api";
import {FmedTitre, FmedChampSaisie, FmedZoneTexte, FmedBouton, FmedMessage, FmedLien} from '@fmedul/panier-vue'
import {courrielValide} from "../../utilitaire";


const api = Api.getInstance();


export default {

  name: 'formulaire-erreur-stage',
  components: {
    FmedTitre,
    FmedChampSaisie,
    FmedZoneTexte,
    FmedBouton,
    FmedMessage,
    FmedLien
  },
  data: function () {

    return {


      enregistrement: false,
      afficherErreurs: false,
      afficherSucces: false,

      formulaire: {
        prenom: '',
        nom: '',
        courriel: '',
        message: ''
      },


    }
  },

  methods: {

    reinitialiser() {
      this.afficherErreurs = false;
      this.afficherSucces = false;

      this.formulaire = {
        prenom: '',
        nom: '',
        courriel: '',
        message: ''
      }
    },

    async soumettre() {
      if (this.nombreErreurs > 0) {
        this.afficherErreurs = true;
        return;
      }

      this.enregistrement = true
      await api.postSignalerProbleme(this.formulaire);
      this.enregistrement = false;

      this.reinitialiser();
      this.afficherSucces = true;
      window.scrollTo(0, 0);
    }

  },

  computed: {

    erreurs() {

      let erreurs = {
        'prenom': [],
        'nom': [],
        'courriel': [],
        'message': [],
      };

      if (this.formulaire.prenom.trim().length === 0) {
        erreurs['prenom'].push('Le prénom ne doit pas être vide');
      }
      if (this.formulaire.nom.trim().length === 0) {
        erreurs['nom'].push('Le nom ne doit pas être vide');
      }
      if (this.formulaire.courriel.trim().length === 0) {
        erreurs['courriel'].push('Le courriel ne doit pas être vide');
      }
      if (this.formulaire.message.trim().length === 0) {
        erreurs['message'].push('Le message ne doit pas être vide');
      }

      if (!courrielValide(this.formulaire.courriel)) {
        erreurs['courriel'].push('Le format de l\'adresse courriel est invalide');
      }

      return erreurs;

    },

    nombreErreurs() {

      let nombre = 0;
      for (const champ of Object.keys(this.erreurs)) {
        nombre += this.erreurs[champ].length;
      }

      return nombre;

    },


  },

  filters: {},

  created: async function () {


  }


}
</script>

<style>


</style>