import Vue from 'vue'
import router from './router'
import axios from 'axios'
import moment from "moment"
import config from "./config";
import store from "@/store";


import Cookies from 'js-cookie'

export default class {

    static #instance = null;

    constructor() {
        this.http = axios.create({
            baseURL: config.API_BASE_URL
        });

        this.loadAuthorizationToken();


        this.http.interceptors.response.use(reponse => {
            return reponse;
        }, async erreur => {
            if (erreur.response) {
                if (401 === erreur.response.status && router.currentRoute.name !== 'admin.connexion') {

                    Vue.notify({
                        type: 'error',
                        group: 'alerts',
                        title: 'Accès refusé',
                        text: 'Vous n\'avez pas accès à cette ressource ou votre session a expirée. Veuillez vous connecter.',
                        duration: 10000
                    });
                    Cookies.remove('token');
                    store.commit('setLoggedIn', false);
                    delete this.http.defaults.headers.common['Authorization'];
                    await router.push({name: 'admin.connexion'})


                } else if (erreur.response.data.erreur === "ERREUR_INCONNUE") {
                    this.erreurGenerique();
                }

                throw erreur.response.data

            } else { //On a pas de réponse du serveur
                this.erreurGenerique();
            }

        });
    }


    erreurGenerique() {
        Vue.notify({
            type: 'error',
            group: 'alerts',
            title: 'Erreur',
            text: 'Une erreur s\'est produite. Veuillez réessayer plus tard',
            duration: 10000
        });
    }

    loadAuthorizationToken() {
        if (Cookies.get('token') !== undefined) {
            this.http.defaults.headers.common['Authorization'] = Cookies.get('token');
            store.commit('setLoggedIn', true);
        }

    }

    static getInstance() {
        if (this.#instance == null) {
            this.#instance = new this();
        }

        return this.#instance;
    }


    async getOrganisme(id) {
        const reponse = await this.http.get('/organismes/' + id);
        return reponse.data;
    }

    async getOrganismes() {

        const reponse = await this.http.get('/organismes');
        return reponse.data;

    }

    async rechercheOrganismes(motsCles, actifSeulement) {

        let params = {}
        if (motsCles) {
            params.mots_cles = motsCles
        }
        params.actif_seulement = actifSeulement

        const reponse = await this.http.get('/organismes/recherche', {params: params});

        return reponse.data;
    }


    getFormationsFromOrganisme(organisme) {

        return new Promise(resolve => {
            this.http.get("/organismes/" + organisme.id + "/formations")
                .then(function (response) {

                    let formations = response.data;
                    resolve(formations);
                });

        });

    }

    async getOrganismesFromFormation(formationId, inclureInactif) {

        let params = {};

        if (inclureInactif) {
            params.inclure_inactif = true
        }

        const reponse = await this.http.get("/formations/" + formationId + "/organismes", {
            params: params
        });
        return reponse.data;
    }


    async obtenirModulesDeFormation(formationId, inclureInactif) {

        let params = {}
        if (inclureInactif) {
            params.inclure_inactif = true;
        }

        const reponse = await this.http.get("/formations/" + formationId + "/modules", {
            params: params
        });
        return reponse.data;


    }

    getModule(moduleId) {
        const reponse = this.http.get("/modules/" + moduleId);
        return reponse.data;
    }


    async searchDemandesAttestations(keywords, startDate, endDate, isIssued, inReview) {

        const response = await this.http.get('/demandes-attestations/search' +
            '?keywords=' + keywords +
            '&startDate=' + startDate +
            '&endDate=' + endDate +
            '&isIssued=' + isIssued +
            '&inReview=' + inReview);

        return response.data;
    }

    getDemandeAttestation(demandeAttestationId) {
        return new Promise(resolve => {
            this.http.get('/demandes-attestations/' + demandeAttestationId)
                .then(function (response) {
                    let demandeAttestation = response.data;
                    resolve(demandeAttestation);
                });
        });

    }

    deleteDemandeAttestation(demandeAttestationId) {
        return new Promise(resolve => {
            this.http.delete('/demandes-attestations/' + demandeAttestationId)
                .then(function () {
                    resolve();
                });
        });

    }

    updateDemandeAttestation(demandeAttestationId, prenom, nom, courriel, enRevision, commentaires, dateFormation, formationId, moduleId, organismeId) {


        const reponse = this.http.put('/demandes-attestations/' + demandeAttestationId, {
            prenom: prenom,
            nom: nom,
            courriel: courriel,
            en_revision: enRevision,
            commentaires: commentaires,
            date_formation: dateFormation,
            formation_id: formationId,
            module_id: moduleId,
            organisme_id: organismeId,
        });

        return reponse.data;
    }


    async emettreAttestation(demandeAttestationId) {

        await this.http.post('/demandes-attestations/' + demandeAttestationId + '/emettre');
    }


    searchorganismes(keywords, activeOnly) {

        return new Promise(resolve => {

            let organismes = [];
            this.http.get('/organismes/search' +
                '?keywords=' + keywords +
                '&activeOnly=' + activeOnly)
                .then(function (response) {
                    response.data.forEach(function (organisme) {
                        organismes.push(organisme);
                    });

                    resolve(organismes);
                });
        });


    }

    async creerOrganisme(organisme) {

        const reponse = await this.http.post('/organismes', {
            nom: organisme.nom,
            adresse_courriel: organisme.adresseCourriel
        });

        return reponse.data
    }


    async updateOrganisme(organisme) {

        const reponse = await this.http.put('/organismes/' + organisme.id, {
            nom: organisme.nom,
            adresse_courriel: organisme.adresseCourriel || null,
            actif: organisme.actif
        });

        return reponse.data
    }


    async getAllFormations() {
        let response = await this.http.get('/formations');

        return response.data;
    }


    async creerRequeteDemandeAttestation(formationId, moduleId, organismeId, formationDate, fichier, demandeRabais, nomDemandeur, prenomDemandeur) {

        let data = new FormData();
        data.append('formation_id', formationId);
        data.append('organisme_id', organismeId);
        data.append('formation_date', formationDate);


        if (moduleId) {
            data.append('module_id', moduleId);
        }
        if (fichier) {
            data.append('fichier', fichier)
        }

        data.append('demande_rabais', demandeRabais || false);
        if (demandeRabais) {
            data.append('nom_demandeur_pour_rabais', nomDemandeur);
            data.append('prenom_demandeur_pour_rabais', prenomDemandeur);
        }

        const reponse = await this.http.post('/requetes-demandes-attestations/', data);

        return reponse.data.url;
    }


    async searchFormations(keywords, active) {

        const params = {};
        if (active)
            params.active = active;

        if (active)
            params.keywords = keywords;

        const reponse = await this.http.get('/formations/recherche', {
            params: params
        });

        return reponse.data;

    }


    async creerFormation(nom, organismes, accreditation_debut, accreditation_fin, nombreUfc, produitDexeroId) {

        var organismes_ids = [];
        organismes.forEach(function (organisme) {
            organismes_ids.push(organisme.id)
        });

        var data = new FormData();
        data.append('nom', nom);
        data.append('organismes_ids', organismes_ids);
        data.append('date_accreditation_debut', accreditation_debut);
        data.append('date_accreditation_fin', accreditation_fin);


        const reponse = await this.http.post('/formations', {
            nom: nom,
            organismes_ids: organismes_ids,
            date_accreditation_debut: accreditation_debut,
            date_accreditation_fin: accreditation_fin,
            nombre_ufc: nombreUfc,
            produit_ecommerce_id: produitDexeroId
        });

        return reponse.data;

    }

    async modifierFormation(formation) {


        const reponse = await this.http.put('/formations/' + formation.id, {
            formation_id: formation.id,
            nom: formation.nom,
            actif: formation.active,
            date_accreditation_debut: moment.utc(formation.dateDebutAccreditation).format('YYYY-MM-DD'),
            date_accreditation_fin: moment.utc(formation.dateFinAccreditation).format('YYYY-MM-DD'),
            fichier_requis_demande_attestation: formation.fichierRequisDemandeAttestation,
            nombre_ufc: formation.nombreUfc,
            produit_ecommerce_id: formation.produitEcommerce.id,
            type_activite: formation.typeActivite,
        });

        return reponse.data;

    }

    async modifierOrganismesDeFormation(formationId, organismesIds) {

        const reponse = await this.http.patch('/formations/' + formationId + "/organismes", {
            ids: organismesIds
        });
        return reponse.data;

    }


    async getFormation(formationId) {

        const reponse = await this.http.get('/formations/' + formationId);
        return reponse.data;

    }


    ajouterFichierAccreditationFormation(formationId, fichier) {
        return new Promise(resolve => {

            var data = new FormData();
            data.append('formation_id', formationId);
            data.append('fichier', fichier);

            this.http.post('/formations/' + formationId + '/ajouter-fichier-accreditation', data).then(function () {
                resolve();
            })
        });
    }

    getFichiersAccreditationFormation(formation_id) {

        return new Promise(resolve => {


            this.http.get('/formations/' + formation_id + '/fichiers-accreditation').then(function (response) {
                resolve(response.data);
            })
        });

    }


    supprimerFichierAccreditationFormation(formation_id, fichier_id) {
        return new Promise(resolve => {

            var data = new FormData();
            data.append('formation_id', formation_id);
            data.append('fichier_id', fichier_id);

            this.http.post('/formations/' + formation_id + '/fichiers-accreditation/supprimer', data).then(function (response) {
                resolve(response.data);
            })
        });
    }


    obtenirFormationsPourOrganisme(organisme_id) {

        return new Promise(resolve => {

            var data = new FormData();

            this.http.get('/organismes/' + organisme_id + '/formations', data).then(function (response) {
                resolve(response.data);
            })
        });
    }

    async creerModule(nom, nombreUfc, produitEcommerceId, actif, formationId) {

        const response = await this.http.post('/modules', {
            nom: nom,
            nombre_ufc: nombreUfc,
            produit_ecommerce_id: produitEcommerceId,
            actif: actif,
            formation_id: formationId

        });

        return response.data
    }

    async modifierModule(id, nom, nombreUfc, produitEcommerceId, actif) {

        const response = await this.http.put('/modules/' + id, {
            nom: nom,
            nombre_ufc: nombreUfc,
            produit_ecommerce_id: produitEcommerceId,
            actif: actif,
        });

        return response.data
    }


    creerEvenement(evenement) {

        return new Promise(resolve => {

            var data = new FormData();
            data.append('prenomCreateur', evenement.prenomCreateur);
            data.append('nomCreateur', evenement.nomCreateur);
            data.append('courrielCreateur', evenement.courrielCreateur);
            data.append('formation_id', evenement.formation.id);
            data.append('organisme_id', evenement.organisme.id);
            data.append('date', moment.utc(evenement.date).format('YYYY-MM-DD'));

            let evenement_id = null;

            let _this = this;

            this.http.post('/evenements/creer-evenement', data).then(function (response) {
                evenement_id = response.data.id;
            }).then(function () {
                _this.ajouterPresencesEvenement(evenement_id, evenement.presences).then(function () {
                    resolve();
                });


            });


        });

    }

    async obtenirTousProduitsDexero() {
        const reponse = await this.http.get('/produits-ecommerce');
        return reponse.data;
    }


    ajouterPresencesEvenement(evenement_id, presences) {
        return new Promise(resolve => {

            let data = [];
            presences.forEach(function (presence) {
                data.push({
                    nom: presence.nom,
                    prenom: presence.prenom
                });
            });

            this.http.post('/evenements/ajouter-presence', {
                evenement_id: evenement_id,
                presences: data
            }).then(function () {
                resolve();
            })
        })
    }


    rechercheEvenements(mots_cles, organisme_id, date_debut, date_fin) {

        return new Promise(resolve => {

            let data = {};
            data.mots_cles = mots_cles;
            data.organisme_id = organisme_id;

            if (date_debut != null)
                data.date_debut = moment.utc(date_debut).format('YYYY-MM-DD');

            if (date_fin != null)
                data.date_fin = moment.utc(date_fin).format('YYYY-MM-DD')

            this.http.get('/evenements/recherche', {
                params: data
            }).then(function (response) {
                resolve(response.data);
            })

        })

    }


    trouverEvenement(evenement_id) {

        return new Promise(resolve => {
            this.http.get('/evenements/' + evenement_id).then(function (response) {
                resolve(response.data);
            });
        })
    }


    demandeAttestationPresencesSemblables(demandeAttestationId) {

        return new Promise(resolve => {
            this.http.get('/demandes-attestations/' + demandeAttestationId + '/presences-semblables').then(function (response) {
                resolve(response.data);
            });
        })
    }

    lierPresenceDemandeAttestation(presence_id, demande_attestation_id) {

        return new Promise(resolve => {
            this.http.post('/presences/' + presence_id + '/lier-demande-attestation', {
                presence_id: presence_id,
                demande_attestation_id: demande_attestation_id
            }).then(function (response) {
                resolve(response.data);
            });
        })
    }

    delierPresenceDemandeAttestation(presence_id) {

        return new Promise(resolve => {
            this.http.post('/presences/' + presence_id + '/delier-demande-attestation').then(function (response) {
                resolve(response.data);
            });
        })
    }

    async obtenirPresenceDemandeAttestationLiee(demandeAttestationId) {

        const response = await this.http.get('/presences/demande-attestation-liee/' + demandeAttestationId);
        return response.data;
    }

    async obtenirPresencesSemblablesPlusieursDemandesAttestations(demandesAttestationsIds) {

        const ids = demandesAttestationsIds.join(',');

        const reponse = await this.http.get('/demandes-attestations/recherche-presence-semblable?id=' + ids);
        return reponse.data;
    }

    async obtenirPresencesEvenement(evenementId) {

        const reponse = await this.http.get('/evenements/' + evenementId + '/presences');
        return reponse.data;
    }

    logout() {
        Cookies.set('token', undefined);
        delete this.http.defaults.headers.common['Authorization'];
        store.commit('setLoggedIn', false);
    }

    async login(idul, nip) {

        let success = true;
        let response = null;

        try {
            response = await this.http.post('/login', {
                idul: idul,
                nip: nip
            });

            let data = response.data;

            if (data.token) {
                const token = data.token;
                Cookies.set('token', token);
                this.loadAuthorizationToken();
            }
        } catch (e) {
            success = false;
        }


        return success;
    }

    async obtenirCompilationEvaluations(formation_ids, formateur, date_debut, date_fin) {

        const response = await this.http.get('/evaluations/compilation', {
            params: {
                formation_ids: formation_ids,
                formateur: formateur,
                date_debut_formation: date_debut,
                date_fin_formation: date_fin,
            }
        });

        return response.data;

    }


    async downloadAttestationPdf(demandeAttestationId) {
        const response = await this.http.get('/attestation/' + demandeAttestationId + "/pdf", {
            responseType: 'blob',
        });

        this.lancerTelechargement(response.data, 'attestation.pdf');

    }


    async envoyerEvaluation(formation_id, organisme_id, nom_formateur, date_formation, formation_objectifs_clairs_note, formation_objectifs_atteints_note, formation_contenu_adapte_besoins_note, formation_appliquer_contenu_travail_note, formation_motive_appliquer_apprentissages_note, formation_enseignement_facilite_apprentissage_note, formation_formateur_ecoute_participants_note, methodologie_equilibre_approprie_note, methodologie_themes_succede_facon_appropriee_note, methodologie_cahier_contient_infos_utiles_note, methodologie_materiel_adequat_note, eval_globale_duree_session_appropriee_note, eval_globale_horaire_prevu_respecte_note, eval_globale_satisfait_formation_note, eval_globale_satisfait_accueil_note, eval_globale_nb_participants_adapte_note, eval_globale_local_adequat_note, reference, reference_details, motif, motif_details, region, dernier_diplome, milieu_travail, milieu_travail_details, profession, profession_details, annee_experience, group_age, opinion_points_forts, opinion_points_a_ameliorer, opinion_infos_manquantes_a_inclure, opinion_a_qui_recommanderiez_vous_formation, opinion_formation_vous_aimeriez_voir_offertes, opinion_themes_colloque, opinion_commentaires) {

        await this.http.post('/evaluations', {
            formation_id: formation_id,
            organisme_id: organisme_id,
            nom_formateur: nom_formateur,
            date_formation: date_formation,
            formation_objectifs_clairs_note: formation_objectifs_clairs_note,
            formation_objectifs_atteints_note: formation_objectifs_atteints_note,
            formation_contenu_adapte_besoins_note: formation_contenu_adapte_besoins_note,
            formation_appliquer_contenu_travail_note: formation_appliquer_contenu_travail_note,
            formation_motive_appliquer_apprentissages_note: formation_motive_appliquer_apprentissages_note,
            formation_enseignement_facilite_apprentissage_note: formation_enseignement_facilite_apprentissage_note,
            formation_formateur_ecoute_participants_note: formation_formateur_ecoute_participants_note,
            methodologie_equilibre_approprie_note: methodologie_equilibre_approprie_note,
            methodologie_themes_succede_facon_appropriee_note: methodologie_themes_succede_facon_appropriee_note,
            methodologie_cahier_contient_infos_utiles_note: methodologie_cahier_contient_infos_utiles_note,
            methodologie_materiel_adequat_note: methodologie_materiel_adequat_note,
            eval_globale_duree_session_appropriee_note: eval_globale_duree_session_appropriee_note,
            eval_globale_horaire_prevu_respecte_note: eval_globale_horaire_prevu_respecte_note,
            eval_globale_satisfait_formation_note: eval_globale_satisfait_formation_note,
            eval_globale_satisfait_accueil_note: eval_globale_satisfait_accueil_note,
            eval_globale_nb_participants_adapte_note: eval_globale_nb_participants_adapte_note,
            eval_globale_local_adequat_note: eval_globale_local_adequat_note,
            reference: reference,
            reference_details: reference_details,
            motif: motif,
            motif_details: motif_details,
            region: region,
            dernier_diplome: dernier_diplome,
            milieu_travail: milieu_travail,
            milieu_travail_details: milieu_travail_details,
            profession: profession,
            profession_details: profession_details,
            annee_experience: annee_experience,
            group_age: group_age,
            opinion_points_forts: opinion_points_forts,
            opinion_points_a_ameliorer: opinion_points_a_ameliorer,
            opinion_infos_manquantes_a_inclure: opinion_infos_manquantes_a_inclure,
            opinion_a_qui_recommanderiez_vous_formation: opinion_a_qui_recommanderiez_vous_formation,
            opinion_formation_vous_aimeriez_voir_offertes: opinion_formation_vous_aimeriez_voir_offertes,
            opinion_themes_colloque: opinion_themes_colloque,
            opinion_commentaires: opinion_commentaires,
        });

    }


    async telechargerRapportNombreDemandesAttestationParFormation(dateDebut, dateFin) {
        const response = await this.http.get('/rapports/nombre-demandes-attestation-par-formation/excel', {
            responseType: 'blob',
            params: {
                date_debut: dateDebut,
                date_fin: dateFin
            }
        });

        const nomFichier = response.headers['x-suggested-filename'];
        this.lancerTelechargement(response.data, nomFichier);
    }

    async telechargerRapportBilanEvaluations(formation_ids, formateur, date_debut, date_fin) {

        const response = await this.http.get('/rapports/bilan-evaluations/excel', {
            responseType: 'blob',
            params: {
                formation_ids: formation_ids,
                formateur: formateur,
                date_debut_formation: date_debut,
                date_fin_formation: date_fin,
            }
        });

        const nomFichier = response.headers['x-suggested-filename'];
        this.lancerTelechargement(response.data, nomFichier);
    }

    async approuverEvenement(evenementId) {
        await this.http.post('/evenements/' + evenementId + '/approuver');
    }

    async telechargerFichierPreuveAttestation(demandeAttestationId, nomFichier) {

        const response = await this.http.get('/demandes-attestations/' + demandeAttestationId + "/telecharger-fichier-preuve", {
            responseType: 'blob',
        });

        this.telechargerFichier(new Blob([response.data]), nomFichier);

    }

    async telechargerAttestationPdf(demandeAttestationId) {

        const response = await this.http.get('/attestation/' + demandeAttestationId + "/pdf", {
            responseType: 'blob',
        });

        this.lancerTelechargement([response.data], 'attestation.pdf');
    }


    async telechargerFichier(fichier) {

        const reponse = await this.http.get('/fichiers/' + fichier.id + '/telecharger', {
            responseType: 'blob',
        });

        this.lancerTelechargement(reponse.data, fichier.nom);
    }

    lancerTelechargement(blob, nomFichier) {

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', nomFichier);
        document.body.appendChild(link);
        link.click();
    }


    async obtenirSuperviseurs() {
        const reponse = await this.http.get('/superviseurs');
        return reponse.data;
    }

    async postSignalerProbleme(formulaire) {
        await this.http.post('/signaler-probleme', {
            nom: formulaire.nom,
            prenom: formulaire.prenom,
            courriel: formulaire.courriel,
            message: formulaire.message,
        });
    }


    async chargerFichierSuperviseurs(fichier) {

        let data = new FormData();
        data.append('fichier', fichier);

        const reponse = await this.http.post('/superviseurs', data);
        return reponse.data;
    }


}








