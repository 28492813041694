<template>

    <FmedFenetreModale :afficher="afficher">

        <div class="row">
            <div class="col-sm-12">
                <div class="modal">

                    <div class="form" v-if="!chargement">
                        <FmedTitre :niveau="1" class="mb-1">Ajouter une formation</FmedTitre>


                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <FmedChampSaisie v-model="nom" placeholder="Nom de la formation"/>
                                    <div v-if="errors.nom" v-show="showErrors" class="error">
                                        <span>{{ errors.nom[0] }}</span></div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt1">
                            <div class="col-sm-12">
                                <FmedListeDeroulante v-model="selectedOrganismes" :options="organismes"
                                                     cle-libelle="nom"
                                                     placeholder="Organisme" :multiple="true">
                                    <span slot="aucuneOption">Désolé, aucune formation n'a été trouvée</span>
                                </FmedListeDeroulante>
                            </div>
                        </div>


                        <div class="strip strip-secondary mt1">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label>Unités de formation continue :</label>
                                        <FmedChampSaisie v-model="nombreUfc" style="max-width: 100px"/>
                                        <div v-if="errors.nombreUfc" v-show="showErrors" class="error"><span>{{
                                            errors.nombreUfc[0]
                                          }}</span>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label>Produit Dexero :</label>
                                        <FmedListeDeroulante v-model="produitDexeroSelectionne" style="max-width: 350px"
                                                             :cherchable="true" :options="produitsDexero"
                                                             :fonction-libelle="p => p.id + ' (' + p.cout.toFixed(2) + ' $)'"/>
                                        <div v-if="errors.cout" v-show="showErrors" class="error"><span>{{ errors.cout[0] }}</span>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>


                        <div class="row mt1">
                            <div class="col-sm-12">
                                <div class="strip strip-secondary">
                                    <div><label>Accréditation</label></div>
                                    <div class="mt1">
                                        <div class="date-accreditation flex">
                                            <div>
                                                Début&nbsp;:
                                                <FmedChampDate v-model="accreditationDateDebut"/>
                                                <div v-if="errors.accreditationDateDebut" v-show="showErrors"
                                                     class="error"><span>{{ errors.accreditationDateDebut[0] }}</span>
                                                </div>
                                            </div>

                                            <div>
                                                Fin&nbsp;:
                                                <FmedChampDate
                                                        v-model="accreditationDateFin"/>
                                                <div v-if="errors.accreditationDateFin" v-show="showErrors"
                                                     class="error"><span>{{ errors.accreditationDateFin[0] }}</span>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt1">
                            <div class="col-sm-12">
                                <div class="text-right btn-group">
                                    <FmedBouton couleur="encadre" @click="$emit('annuler')">Annuler</FmedBouton>
                                    <FmedBouton @click="save" :desactive="this.hasErrors">Enregistrer
                                    </FmedBouton>
                                </div>
                            </div>
                        </div>
                    </div>
                    <LoadingOverlay class="text-center mt1" :active.sync="chargement"></LoadingOverlay>


                </div>
            </div>
        </div>


    </FmedFenetreModale>

</template>

<script>


    import Api from "../../api";
    import moment from 'moment';
    import {
        FmedFenetreModale,
        FmedTitre,
        FmedListeDeroulante,
        FmedChampDate,
        FmedChampSaisie,
        FmedBouton
    } from '@fmedul/panier-vue'
    import LoadingOverlay from 'vue-loading-overlay'

    const api = Api.getInstance();

    export default {
        components: {
            FmedFenetreModale,
            FmedTitre,
            FmedListeDeroulante,
            FmedChampDate,
            FmedChampSaisie,
            LoadingOverlay,
            FmedBouton
        },
        data: function () {
            return {
                nom: '',
                organismes: [],
                produitsDexero: [],
                selectedOrganismes: [],

                nombreUfc: '',
                produitDexeroSelectionne: null,

                accreditationDateDebut: null,
                accreditationDateFin: null,

                showErrors: false,

                chargement: false,
            }
        },

        props: {
            afficher: {
                required: true
            }
        },

        watch: {
            async afficher(nouvelleValeur) {
                if (nouvelleValeur === true) {
                    this.chargement = true;
                    this.organismes = await api.getOrganismes();
                    this.produitsDexero = await api.obtenirTousProduitsDexero();
                    console.log(this.produitsDexero);
                    this.chargement = false;
                }
            }
        },


        methods: {


            async save() {
                if (this.errors.length > 0)
                    return;

                const formation = await api.creerFormation(
                    this.nom,
                    this.selectedOrganismes,
                    moment.utc(this.accreditationDateDebut).format('YYYY-MM-DD'),
                    moment.utc(this.accreditationDateFin).format('YYYY-MM-DD'),
                    this.nombreUfc,
                    this.produitDexeroSelectionne.id
                );
                this.$emit('sauvegarder', this.nom);
                this.nom = '';
                await this.$router.push({path: '/admin/formations/' + formation.id});
            },


        },


        computed: {

            errors() {

                var errors = {
                    nom: [],
                    accreditationDateDebut: [],
                    accreditationDateFin: [],
                    nombreUfc: [],
                    produitDexero: [],
                };

                if (this.nom.length === 0) {
                    errors['nom'].push('Le nom de la formation ne peut être vide');
                }


                if (this.accreditationDateDebut == null) {
                    errors['accreditationDateDebut'].push('La date ne peut être vide');
                }
                if (this.accreditationDateFin == null) {
                    errors['accreditationDateFin'].push('La date ne peut être vide');
                }
                if (!this.nombreUfc || this.nombreUfc.trim().length === 0) {
                    errors['nombreUfc'].push('Ne peut être vide');
                }
                if (isNaN(this.nombreUfc)) {
                    errors['nombreUfc'].push('Doit être un nombre');
                }
                if (this.produitDexeroSelectionne === null) {
                    errors['produitDexero'].push('Ne doit pas être vide');
                }


                return errors;
            },

            hasErrors() {
                return Object.keys(this.errors).some(erreurItem => {
                    return this.errors[erreurItem].length > 0
                });
            },


        },

    }


</script>

<style lang="scss">

    #rte {
        height: 200px;
    }



</style>


