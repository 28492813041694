<template>

    <ul class="dropdown-button" >
        <li @click="showItems = !showItems">
            <slot></slot>
            <ul class="items" :class="{'show-items' : showItems}">
                <li v-for="(item, idx) in items" :key="idx" @click="item.action">{{item.label}}</li>
            </ul>
        </li>

    </ul>


</template>

<script>

    export default {

        props: ['items'],

        data: function () {
            return {
                showItems: false
            }
        },

        methods: {}


    }


</script>


<style lang="scss">

    ul.dropdown-button {
        margin: 0;

        &:hover {
            cursor: pointer;
        }

        > li {
            position: relative;

            > ul.items {
                display: none;
                position: absolute;
                right: 0;
                top: 30px;
                background-color: #fff;
                padding: 5px;
                border: 1px solid #ADAEAE;
                z-index: 100;

                &.show-items {
                    display: block;
                }

                > li {
                    font-size: 0.9rem;
                    padding: 5px 10px;
                    white-space: nowrap;

                    user-select: none;

                    &:hover {
                        background: #eee;
                    }
                }
            }

        }

    }


</style>


