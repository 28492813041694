<template>

  <div>


    <div class="container">
      <FmedTitre :niveau="1" class="mt-2 mb-1">Déposer la liste des présences d’une formation</FmedTitre>
    </div>


    <LoadingOverlay class="text-center mt1" :active.sync="isLoading"></LoadingOverlay>

    <div class="container form mt5" v-if="!isLoading">


      <FmedMessage type="succes" class="mt-1" v-if="soumis" @fermer="soumis = false">
        Merci! Vous avez soumis la liste de présences avec succès.
      </FmedMessage>

      <FmedTitre :niveau="3">Coordonnées de la personne-ressource</FmedTitre>

      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="col-sm-3" style="max-width: 150px">Prénom</label>
            <FmedChampSaisie v-model="evenement.prenomCreateur"
                             style="max-width: 500px"/>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="col-sm-3" style="max-width: 150px">Nom</label>
            <FmedChampSaisie v-model="evenement.nomCreateur"
                             style="max-width: 500px"/>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="col-sm-3" style="max-width: 150px">Courriel</label>
            <FmedChampSaisie type="text" v-model="evenement.courrielCreateur"
                             style="max-width: 500px"/>
          </div>
        </div>
      </div>


      <FmedTitre :niveau="3" class="mt-2">Formation</FmedTitre>


      <div class="form-group">
        <FmedListeDeroulante v-model="evenement.formation" :options="formationsOrdreAlpha" cle-libelle="nom"
                             placeholder="Saisir ou sélectionner une formation"
                             @input="formationChanged" style="max-width: 600px">
          <span slot="aucuneOption">Désolé, aucune formation n'a été trouvée</span>
        </FmedListeDeroulante>
      </div>


      <div class="form-group">

        <FmedListeDeroulante :options="organismes ? organismes : []"
                             v-model="evenement.organisme"
                             cle-libelle="nom" placeholder="Sélectionner un organisme"
                             style="max-width: 600px">
          <span slot="aucuneOption">Sélectionner une formation préalablement</span>
        </FmedListeDeroulante>
      </div>


      <div class="form-group">
        <label>Date de début de la formation</label>
        <FmedChampDate v-model="evenement.date" :dates-desactivees="disabledDates"></FmedChampDate>
      </div>


      <FmedTitre :niveau="3" class="mt-2">Liste des présences</FmedTitre>
      <FmedMessage type="info">
        Utiliser obligatoirement le gabarit <a href="/fichiers/presences.xlsx">Liste des
        présences</a> (Fichier Excel au format .xlsx)
      </FmedMessage>
      <DragFileUpload class="mt1" @change="changeFichierPresence" ref="filePresences"></DragFileUpload>

      <table class="table mt1" v-if="evenement.presences && evenement.presences.length > 0">
        <tr>
          <th>Nom</th>
          <th>Prénom</th>
        </tr>
        <tr v-for="(presence, idx) in evenement.presences" :key="idx">
          <td>{{ presence.nom }}</td>
          <td>{{ presence.prenom }}</td>
        </tr>
      </table>


      <div class="row mt-1 mb-1">
        <div class="col-sm-12">
          <div class="text-right">
            <FmedBouton @click="sauvegarder" :disabled="!allFieldSelected" :chargement="sauvegarde">Soumettre
            </FmedBouton>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>


<script>

import Api from "../api";
import {
  FmedTitre,
  FmedListeDeroulante,
  FmedChampDate,
  FmedMessage,
  FmedBouton,
  FmedChampSaisie
} from '@fmedul/panier-vue'
import LoadingOverlay from 'vue-loading-overlay'
import DragFileUpload from '../components/DragFileUpload'

const api = Api.getInstance();

import XLSX from 'xlsx'

export default {

  components: {
    FmedTitre,
    LoadingOverlay,
    FmedListeDeroulante,
    FmedChampDate,
    FmedMessage,
    FmedBouton,
    DragFileUpload,
    FmedChampSaisie
  },

  data: () => {
    return {


      evenement: {
        prenomCreateur: '',
        nomCreateur: '',
        courrielCreateur: '',
        organisme: null,
        formation: null,
        date: null,
        presences: []
      },

      formations: [],
      organismes: [],

      isLoading: true,

      sauvegarde: false,
      soumis: false,


      disabledDates: {
        from: (new Date())
      },
    }
  },


  methods:
      {


        changeFichierPresence: function (fichier) {
          console.log("fichier modifié");
          this.evenement.presences = [];
          let reader = new FileReader();

          reader.onload = (e) => {
            let data = new Uint8Array(e.target.result);

            let workbook = null;
            try {
              workbook = XLSX.read(data, {type: 'array'});
            } catch (e) {

              this.errorFichier();
              return;
            }

            console.log(workbook);
            let lignes = XLSX.utils.sheet_to_json(workbook.Sheets.presences);
            console.log(lignes);

            if (lignes.length === 0 || (!lignes[0]['Nom'] || !lignes[0]['Prenom'])) {
              this.errorFichier();
              return;
            }

            this.evenement.presences = lignes.map(function (ligne) {
              return {
                nom: ligne.Nom,
                prenom: ligne.Prenom
              }
            });

            this.$forceUpdate();
          };
          reader.readAsArrayBuffer(fichier);
        },

        errorFichier: function () {
          alert('Le fichier est vide ou invalide');
          this.$refs.filePresences.clear();
        },

        formationChanged: async function () {
          this.evenement.organisme = null;
          this.organismes = await api.getOrganismesFromFormation(this.evenement.formation.id)
        },


        async sauvegarder() {

          this.sauvegarde = true;
          await api.creerEvenement(this.evenement);
          this.sauvegarde = false;
          this.soumis = true;
          this.resetFormulaire();
          window.scrollTo(0, 0);
        },


        resetFormulaire() {
          this.evenement.prenomCreateur = '';
          this.evenement.nomCreateur = '';
          this.evenement.courrielCreateur = '';
          this.evenement.organisme = null;
          this.evenement.formation = null;
          this.evenement.date = null;
          this.evenement.presences = [];
        },


      }
  ,

  computed: {
    allFieldSelected: function () {
      return this.evenement.prenomCreateur
          && this.evenement.nomCreateur
          && this.evenement.courrielCreateur
          && this.evenement.organisme
          && this.evenement.formation
          && this.evenement.date
          && this.evenement.presences
          && this.evenement.presences.length > 0;
    },

    formationsOrdreAlpha() {
      return this.formations.slice().sort((a, b) => a.nom.localeCompare(b.nom));
    }
  },




  mounted: async function () {
    this.formations = await api.getAllFormations();

    this.isLoading = false;
  }


};
</script>

