<template>

    <FmedBoiteConnexion @connexion="connect" :chargement="connecting"
                        message-erreur="Erreur lors de la tentative de connexion"></FmedBoiteConnexion>

</template>


<script>


    import Api from '../../api';
    import {FmedBoiteConnexion} from "@fmedul/panier-vue";

    const api = Api.getInstance();

    export default {
        components: {FmedBoiteConnexion},
        data: function () {
            return {
                connecting: false,
                messageErreur: ''
            }

        },

        methods: {
            connect: async function (e) {
                this.messageErreur = '';
                this.connecting = true;
                const success = await api.login(e.idul, e.nip);
                this.connecting = false;

                if (success === false) {
                    this.messageErreur = 'Erreur lors de la tentative de connexion';
                } else {
                    await this.$router.push({name: 'admin.demandes-attestations'});
                }

            }
        },


        computed: {},

        created: function () {
        },


    }


</script>

<style lang="scss" scoped>

    h1 {
        font-weight: 300;
    }

    .login-container {

        max-width: 500px;
        margin: 100px auto 0;

        .login-box {
            border: 1px solid #000;
            padding: 20px;

            .input {
                label {
                    display: block;
                    font-weight: bold;
                    color: #000;
                }

                input {
                    width: 100%
                }

                &:not(last-child) {
                    margin-bottom: 20px;
                }

            }
        }

    }


</style>

