<template>

    <div class="light" :class="{on : value, off : !value}" :style="{ width : size, height : size, borderRadius : size}"></div>

</template>

<script>

    export default {

        props: {

            value: {
                type: Boolean
            },
            size: {
                default: '50px'
            }

        }


    }


</script>


<style lang="scss">

    .light {

        display: inline-block;

        &.on {
            background-color: #53b96e;
        }

        &.off {
            background-color: #C0BEBE;
        }

    }


</style>


