<template>

    <div>


        <div class="container">
            <FmedTitre :niveau="1" class="mt-2 mb-1">Gestion des organismes</FmedTitre>
        </div>


        <div class="strip strip-secondary">
            <div class="container mt2">

                <div class="form">

                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <FmedChampSaisie  v-model="keywords" placeholder="Par mots-clés"/>
                            </div>

                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="checkbox">
                                    <input type="checkbox" v-model="active">
                                    Statut actif
                                </label>
                            </div>

                        </div>
                    </div>

                </div>


                <div class="pull-right">
                    <FmedBouton @click="recherche">Chercher</FmedBouton>
                </div>


            </div>
        </div>

        <LoadingOverlay class="text-center mt1" :active.sync="isLoading"></LoadingOverlay>

        <div v-if="!isLoading">

            <div class="container mt-1">

                <div class="pull-right">
                    <FmedBouton couleur="encadre" @click="ouvrirFenetreDialogueCreerOrganisme">Ajouter un organisme</FmedBouton>
                </div>


                <Datatable :columns="columns" :data="organismes" :per-page="15" class="mt1">

                    <template slot-scope="{data}">
                        <tr v-for="(organisme, idx) in data" :key="organisme.id">
                            <td>{{ organisme.nom }}</td>
                            <td>{{ organisme.adresseCourriel }}</td>
                            <td class="text-center">
                                <Light v-model="organisme.actif" size="15px"></Light>
                            </td>
                            <td align="center"><i class="icon icon-edit icon-link" style="cursor: pointer"
                                                  @click="ouvrirModalModifierOrganisme(idx)"></i></td>
                        </tr>
                    </template>


                </Datatable>
            </div>
        </div>

        <FenetreModaleCreerOrganisme :afficher="afficherFenetreModaleCreerOrganisme" @annuler="afficherFenetreModaleCreerOrganisme = false" @sauvegarder="onSauvegardeModificationCreation"></FenetreModaleCreerOrganisme>
        <FenetreModaleModifierOrganisme :organisme-id="organismeModifierId" @annuler="organismeModifierId = null" @sauvegarder="onSauvegardeModification"></FenetreModaleModifierOrganisme>
    </div>


</template>


<script>

    import Api from '../../api';

    import LoadingOverlay from 'vue-loading-overlay'
    import {FmedTitre, FmedChampSaisie, FmedBouton} from '@fmedul/panier-vue'
    import FenetreModaleCreerOrganisme from "../../components/modals/FenetreModaleCreerOrganisme";
    import FenetreModaleModifierOrganisme from "../../components/modals/FenetreModaleModifierOrganisme";
    import Datatable from "../../components/Datatable";
    import Light from "../../components/Light";

    const api = Api.getInstance();

    export default {
        components: {
            FenetreModaleModifierOrganisme,
            FenetreModaleCreerOrganisme, LoadingOverlay, FmedTitre, FmedChampSaisie, FmedBouton, Datatable, Light},
        data: function () {

            return {

                keywords: '',
                active: true,

                itemsPerPage: 15,

                columns: [
                    {label: 'Organisme', field: 'nom', sortable: true},
                  {
                    label: 'Adresse courriel', field : 'adresseCourriel', sortable: true
                  },
                    {
                        label: 'Statut', field: 'status', sortFunction: function (a, b) {
                            var first = a.active ? 1 : 0;
                            var second = b.active ? 1 : 0;

                            return (first > second) ? 1 : ((first < second) ? -1 : 0)
                        }, sortable: true, width: '50px'
                    },

                    {label: '', width: '50px'},
                ],

                organismes: [],

                page: 1,
                per_page: 10,


                isLoading: true,

                afficherFenetreModaleCreerOrganisme : false,
                organismeModifierId : null,
                organismeModifierIdx : null,
            }

        },

        methods: {

            async onSauvegardeModificationCreation() {
                this.afficherFenetreModaleCreerOrganisme = false;
                await this.recherche();
            },

            onSauvegardeModification(organisme){

                this.$set(this.organismes, this.organismeModifierIdx,organisme);

                this.organismeModifierId = null;
                this.organismeModifierIdx = null;
            },

            async recherche() {
                this.isLoading = true;
                this.organismes = await this.effectuerRecherche();
                this.isLoading = false;
            },

            effectuerRecherche() {
                return api.rechercheOrganismes(this.keywords, this.active);
            },

            ouvrirModalModifierOrganisme(organismeIdx) {

                this.organismeModifierIdx = organismeIdx;
                this.organismeModifierId = this.organismes[this.organismeModifierIdx].id;

            },


            ouvrirFenetreDialogueCreerOrganisme() {
                this.afficherFenetreModaleCreerOrganisme = true;
            },





        },

        created: async function () {

            this.organismes = await this.effectuerRecherche();
            this.isLoading = false;

        }

    }


</script>
