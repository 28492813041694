<template>

    <div>
        <div class="container">
            <FmedTitre :niveau="1" class="mt-1 mb-1">Extraction des demandes d’attestation par formation</FmedTitre>
        </div>


        <div class="container box mt1">
            <div class="row mt2 align-center">
                <div class="col-sm-2"><span class="text-muted">Période :</span></div>
                <div class="col-sm-10 flex align-center">
                    <span class="text-muted">de</span>
                    <FmedChampDate v-model="dateDebut"></FmedChampDate>
                    <span class="text-muted" style="margin-left: 1em">à</span>
                    <FmedChampDate v-model="dateFin"></FmedChampDate>
                </div>
            </div>

            <div class="text-right">
                <FmedBouton :chargement="loading" @click="telechargerRapport">Télécharger le rapport</FmedBouton>
            </div>
        </div>

    </div>


</template>


<script>

    import Api from '../../api';
    import moment from 'moment';

    import {FmedBouton, FmedTitre, FmedChampDate} from '@fmedul/panier-vue'


    const api = Api.getInstance();

    export default {
        components: {FmedBouton, FmedTitre, FmedChampDate},
        data: function () {

            return {
                dateDebut: moment().subtract('1', 'year').format('YYYY-MM-DD'),
                dateFin: moment().format('YYYY-MM-DD'),

                loading: false,
            }

        },


        methods: {
            async telechargerRapport() {
                this.loading = true;
                await api.telechargerRapportNombreDemandesAttestationParFormation(
                    moment.utc(this.dateDebut).format('YYYY-MM-DD'),
                    moment.utc(this.dateFin).format('YYYY-MM-DD')
                );
                this.loading = false;
            }
        },


        computed: {},

        created: async function () {

        },

    }


</script>

<style lang="scss">

    .surligner {
        background-color: yellow;
    }

</style>
