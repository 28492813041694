<template>
    <div class="table-responsive">
        <div class="table">
            <table width="100%">
                <tr>
                    <th v-for="(column, idx) in columns"
                        :style="{ 'width' : column.width }"
                        :key="idx"
                        @click="sort(column)"
                        :class="{ 'sortable' : column.sortable, 'sorted' : column.field === sortField, 'sort-asc' : (column.field === sortField && sortOrder === 'asc'), 'sort-desc' : (column.field === sortField && sortOrder === 'desc')}">
                        {{ column.label }}
                    </th>
                </tr>
                <slot :data="dataInPage"></slot>
            </table>
            <div class="table-navigation">
                <div class="current-records">{{ currentRecordsString }}</div>
                <div class="pagination">
                    <a @click="previousPage">Précédent</a>
                    <span v-for="page in pagesInPager" :key="page"
                          :class="{'current' : currentPage===page, 'link' : page !== 'skip' && currentPage!==page}"
                          @click="changePage(page)">{{ page !== 'skip' ? page : '...' }}</span>
                    <a @click="nextPage">Suivant</a>
                </div>
                <div class="per-page">
                    Nombre de résultats par page
                    <select v-model="itemsPerPage">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>


                </div>

            </div>
        </div>
    </div>


</template>

<script>

    import _ from 'lodash'

    export default {

        data: function () {
            return {
                sortField: null,
                sortFunction: null,
                sortOrder: null,
                currentPage: 1,
                itemsPerPage : JSON.parse(this.perPage)
            }
        },

        props: ['columns', 'data', 'per-page'],

        watch: {
            data: function(){
                this.changePage(1);
            }
        },

        methods: {
            sort: function (column) {

                if (!column.sortable)
                    return;

                if (this.sortField !== column.field) {
                    this.sortField = column.field;
                    this.sortFunction = column.sortFunction;
                    this.sortOrder = null;
                }


                if (this.sortOrder == null)
                    this.sortOrder = 'asc';
                else if (this.sortOrder === 'asc')
                    this.sortOrder = 'desc';
                else {
                    this.sortOrder = null;
                    this.sortField = null;
                    this.sortFunction = null;
                }
            },

            previousPage: function () {
                if (this.currentPage > 1)
                    this.currentPage--;
            },
            nextPage: function () {
                if (this.currentPage < this.pageCount)
                    this.currentPage++;
            },
            changePage: function (page) {
                if (page !== 'skip')
                    this.currentPage = page;
            }

        },


        computed: {
            sortedData: function () {

                if (this.sortField == null && this.sortFunction === null)
                    return this.data;


                var sortedData = this.data.slice(0);
                var _this = this;
                return sortedData.sort(function (a, b) {

                    var sortOrder = 1;
                    if (_this.sortOrder === 'desc')
                        sortOrder = -1;

                    var result = 0;
                    if(typeof(_this.sortFunction) == 'function'){
                        result = _this.sortFunction(a,b);
                    }else{
                        var first = _.deburr(a[_this.sortField]);
                        var second = _.deburr(b[_this.sortField]);

                        result = (first < second) ? -1 : (first > second) ? 1 : 0;
                    }


                    return result * sortOrder;
                });

            },

            dataInPage: function () {
                return _.slice(this.sortedData, (this.currentPage - 1) * this.itemsPerPage, this.currentPage * this.itemsPerPage);
            },

            pageCount: function () {
                return Math.ceil(this.data.length / this.itemsPerPage);
            },

            pagesInPager: function () {

                var pages = [];
                pages.push(this.currentPage);

                if (this.currentPage > 1)
                    pages.unshift(this.currentPage - 1);

                if (this.currentPage > 2)
                    pages.unshift(this.currentPage - 2);

                if (this.currentPage + 1 <= this.pageCount) {
                    pages.push(this.currentPage + 1);
                }
                if (this.currentPage + 2 <= this.pageCount) {
                    pages.push(this.currentPage + 2);
                }


                if (pages[pages.length -1] !== this.pageCount) {
                    if (pages[pages.length - 2] !== this.pageCount - 2)
                        pages.push('skip');

                    pages.push(this.pageCount);
                }

                if (pages[0] !== 1) {
                    if (pages[1] !== 3)
                        pages.unshift('skip');

                    pages.unshift(1);

                }


                return pages;

            },

            currentRecordsString : function() {
                return (this.currentPage * this.itemsPerPage - this.itemsPerPage + 1) + "-"  + Math.min(this.currentPage * this.itemsPerPage, this.data.length) + " sur " + this.data.length;
            }
        },

    }


</script>

<style>
    .pagination .link, .pagination .current {
        cursor: pointer
    }
    .table .table-navigation .pagination .current {
        background-color: #dfdfdc;
        color: #bcbcbc;
    }

</style>
